import {
  VIJEST_LIST_REQUEST,
  VIJEST_LIST_SUCESS,
  VIJEST_LIST_FAIL,
  VIJEST_DETAILS_REQUEST,
  VIJEST_DETAILS_SUCESS,
  VIJEST_DETAILS_FAIL,
  VIJEST_DELETE_REQUEST,
  VIJEST_DELETE_SUCCESS,
  VIJEST_DELETE_FAIL,
  VIJEST_CREATE_REQUEST,
  VIJEST_CREATE_SUCCESS,
  VIJEST_CREATE_FAIL,
  VIJEST_CREATE_RESET,
  VIJEST_UPDATE_REQUEST,
  VIJEST_UPDATE_SUCCESS,
  VIJEST_UPDATE_FAIL,
  VIJEST_UPDATE_RESET,
  VIJEST_ONE_REQUEST,
  VIJEST_ONE_FAIL,
  VIJEST_ONE_SUCESS,
} from '../constants/vijestConstants'

export const vijestListReducer = (state = { vijesti: [] }, action) => {
  switch (action.type) {
    case VIJEST_LIST_REQUEST:
      return { loading: true, vijesti: [] }
    case VIJEST_LIST_SUCESS:
      return { loading: false, vijesti: action.payload }
    case VIJEST_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const vijestDetailsReducer = (state = { vijest: {} }, action) => {
  switch (action.type) {
    case VIJEST_DETAILS_REQUEST:
      return { loading: true, ...state }
    case VIJEST_DETAILS_SUCESS:
      return { loading: false, vijest: action.payload }
    case VIJEST_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const vijestDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case VIJEST_DELETE_REQUEST:
      return { loading: true }
    case VIJEST_DELETE_SUCCESS:
      return { loading: false, success: true }
    case VIJEST_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const vijestCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case VIJEST_CREATE_REQUEST:
      return { loading: true }
    case VIJEST_CREATE_SUCCESS:
      return { loading: false, success: true, vijest: action.payload }
    case VIJEST_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case VIJEST_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const vijestUpdateReducer = (state = { vijest: {} }, action) => {
  switch (action.type) {
    case VIJEST_UPDATE_REQUEST:
      return { loading: true }
    case VIJEST_UPDATE_SUCCESS:
      return { loading: false, success: true, vijest: action.payload }
    case VIJEST_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case VIJEST_UPDATE_RESET:
      return { vijest: {} }
    default:
      return state
  }
}

export const vijestOneReducer = (state = { vijesti: [] }, action) => {
  switch (action.type) {
    case VIJEST_ONE_REQUEST:
      return { loading: true, vijesti: [] }
    case VIJEST_ONE_SUCESS:
      return { loading: false, vijesti: action.payload }
    case VIJEST_ONE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
