import { createMuiTheme } from '@material-ui/core/styles'

const aitiBlue = '#0B72B9'
const aitiBlack = '#000010'
const aitiWhite = '#ffffff'
const aitiGrey = '#ededed'
const aitiPurple = '#7c18f5'

const outerTheme = createMuiTheme({
  h4: {
    color: `${aitiWhite}`,
    textTransform: 'none',
    backround: 'none',
  },
  h2: {
    color: `${aitiBlue}`,
    textTransform: 'none',
    backround: 'none',
  },

  mainTitle: {
    marginBottom: '20px',
    textAlign: 'left',
    fontFamily: 'Playfair Display',
    color: '#000000',
    fontSize: '4.4rem',
    fontWeight: '700',
    textTransform: 'none',
  },
  titleSansSerif: {
    textAlign: 'left',
    fontFamily: 'PT Sans',
    color: '#000000',
    fontSize: '4rem',
    fontWeight: '600',
    textTransform: 'none',
  },
  subtitleSansSerif: {
    textAlign: 'left',
    fontFamily: 'PT Sans',
    color: '#000000',
    fontSize: '2rem',
    fontWeight: '500',
    textTransform: 'none',
  },
  registerButton: {
    height: 50,
    width: 180,
    color: '#ffffff',
    fontFamily: 'PT Sans',
    fontSize: '1.2rem',

    backgroundColor: `${aitiBlue}`,
    textTransform: 'none',
    textAlign: 'left',
    itemAlign: 'center',
    '&:hover': {
      backgroundColor: '#43a5f0',
      color: '#ffffff',
    },
  },

  palette: {
    common: {
      aitiBlue: aitiBlue,
      aitiWhite: aitiWhite,
      aitiBlack: aitiBlack,
      aitiGrey: aitiGrey,
    },
    primary: {
      main: `${aitiPurple}`,
    },
    secondary: {
      main: `${aitiPurple}`,
    },
  },
  typography: {
    tab: {
      textTransform: 'none',
    },
  },

  muiLink: {
    '&:before': {
      borderBottom: `2px solid ${aitiBlue}`,
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `2px solid ${aitiBlue}`,
    },
  },
  Button: {
    textTransform: 'none',
  },
  button: {
    backgroundColor: '#7c18f5',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#000000',
      color: '#ffffff',
    },
  },
  prijavaButton: {
    width: 300,

    backgroundColor: '#7c18f5',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#000000',
      color: '#ffffff',
    },
  },
  appBarHeight: {
    height: 60,
  },
})

export default outerTheme
