import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../actions/userActions'
//COMPONENTS
import UserImage from '../../images/images-1.jpg'
import NewsImage from '../../images/news.jpg'
import TreningImage from '../../images/trening.png'
import AplikacijaImage from '../../images/aplikacija.jpg'
import Careers from '../../images/admin-careers.jpg'
//STYLES
import theme from '../../components/UI/Theme'
import {
  makeStyles,
  Paper,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Box,
  Grid,
  Button,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    padding: theme.spacing(1.4),
    margin: 'auto',
    width: 1350,
    backgroundColor: theme.palette.common.aitiGrey,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paperBottom: {
    padding: theme.spacing(1.4),
    margin: 'auto',
    width: 1350,
    backgroundColor: theme.palette.common.aitiGrey,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: 400,
      padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  coverBox: {
    height: 'auto',
    width: 'auto',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      height: 320,
      width: 370,
    },
  },
  titleBox: {
    width: 900,
    height: 'auto',
    paddingTop: 80,
    marginBottom: 80,
    [theme.breakpoints.down('md')]: {
      height: 400,
      width: '80%',
      paddingTop: 80,
    },
    [theme.breakpoints.down('sm')]: {
      height: 500,
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      height: 320,
      width: '100%',
      padding: 20,
      paddingTop: 80,
      marginBottom: 0,
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    marginTop: 20,
    fontSize: '2.5rem',
    letterSpacing: '0.1rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '1.8rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 0,
      fontSize: '1.8rem',
    },
  },
  mainSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 40,
    fontFamily: 'PT Sans',
    fontSize: '1.8rem',
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
      height: '2rem',
      paddingBottom: 50,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: 350,
      height: '10rem',
      margin: 0,
      paddingRight: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
  root: {
    width: 350,
    marginBottom: 40,
    backgroundColor: theme.palette.common.aitiGrey,
    transition: 'transform 0.25s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: '5%',
      backgroundColor: theme.palette.common.aitiGrey,
    },
  },
  media: {
    height: 240,
    backgroundColor: theme.palette.common.aitiGrey,
  },
  cardHovered: {
    transform: 'scale3d(1.01, 1.01, 1)',
    backgroundColor: theme.palette.common.aitiWhite,
  },
  actions: {
    paddingLeft: 16,
    paddingBottom: 16,
  },
  button: {
    ...theme.button,
  },
})

const Admin = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const logoutHandler = () => {
    dispatch(logout())
  }
  const [state, setState] = useState({
    raised: false,
    shadow: 1,
  })
  const [state1, setState1] = useState({
    raised: false,
    shadow: 1,
  })
  const [state2, setState2] = useState({
    raised: false,
    shadow: 1,
  })
  const [state3, setState3] = useState({
    raised: false,
    shadow: 1,
  })
  const [state4, setState4] = useState({
    raised: false,
    shadow: 1,
  })
  const [state5, setState5] = useState({
    raised: false,
    shadow: 1,
  })

  return (
    <div className={classes.main}>
      <Paper className={classes.paper_top}>
        <Grid container justify='space-between' className={classes.coverBox}>
          <Box className={classes.titleBox}>
            <p>AITI DIGITAL</p>
            <h3 className={classes.mainTitle}>AITI ADMINISTRACIJA</h3>
            <p className={classes.mainSubTitle}>
              {userInfo.name}, dobrodosli u administrativnu sekciju. Zelimo ti
              ugodan rad.
            </p>
          </Box>
        </Grid>
      </Paper>
      <Paper className={classes.paperBottom}>
        <Grid container justify='space-between'>
          <Card
            className={classes.root}
            classes={{ root: state1.raised ? classes.cardHovered : '' }}
            onMouseOver={() => setState1({ raised: true, shadow: 3 })}
            onMouseOut={() => setState1({ raised: false, shadow: 1 })}
            raised={state1.raised}
            zdepth={state1.shadow}
          >
            <CardActionArea>
              <CardMedia
                component='img'
                className={classes.media}
                image={NewsImage}
              />
            </CardActionArea>
            <CardContent>
              <Link to={`/admin/vijestlist`}>
                <Typography gutterBottom variant='h5' component='h2'>
                  <strong>Vijesti</strong>
                </Typography>
              </Link>
              <Typography variant='body2' color='textSecondary' component='p'>
                Na ovom mjestu mozete uredjivati AITI vijesti
              </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
              <Link to={`/admin/vijestlist`}>
                <Button
                  variant='outlined'
                  size='medium'
                  color='secondary'
                  className={classes.button}
                >
                  Pokreni
                </Button>
              </Link>
            </CardActions>
          </Card>
          <Card
            className={classes.root}
            classes={{ root: state2.raised ? classes.cardHovered : '' }}
            onMouseOver={() => setState2({ raised: true, shadow: 3 })}
            onMouseOut={() => setState2({ raised: false, shadow: 1 })}
            raised={state2.raised}
            zdepth={state2.shadow}
          >
            <CardActionArea>
              <CardMedia
                component='img'
                className={classes.media}
                image={TreningImage}
              />
            </CardActionArea>
            <CardContent>
              <Link to={`/admin/kurslist`}>
                <Typography gutterBottom variant='h5' component='h2'>
                  <strong>Obrazovni programi</strong>
                </Typography>
              </Link>

              <Typography variant='body2' color='textSecondary' component='p'>
                Na ovom mjestu mozete uredjivati podatke o obrazovnim programima
              </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
              <Link to={`/admin/kurslist`}>
                <Button
                  variant='outlined'
                  size='medium'
                  color='secondary'
                  className={classes.button}
                >
                  Pokreni
                </Button>
              </Link>
            </CardActions>
          </Card>
          <Card
            className={classes.root}
            classes={{ root3: state3.raised ? classes.cardHovered : '' }}
            onMouseOver={() => setState3({ raised: true, shadow: 3 })}
            onMouseOut={() => setState3({ raised: false, shadow: 1 })}
            raised={state3.raised}
            zdepth={state3.shadow}
          >
            <CardActionArea>
              <CardMedia
                component='img'
                className={classes.media}
                image={AplikacijaImage}
              />
            </CardActionArea>
            <CardContent>
              <Link to={`/admin/orderlist`}>
                <Typography gutterBottom variant='h5' component='h2'>
                  <strong>Prijave na obrazovne programe</strong>
                </Typography>
              </Link>

              <Typography variant='body2' color='textSecondary' component='p'>
                Na ovom mjestu mozete uredjivati podatke povezane sa prijavom
                korisnika na obrazovne programe.
              </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
              <Link to={`/admin/orderlist`}>
                <Button
                  variant='outlined'
                  size='medium'
                  color='secondary'
                  className={classes.button}
                >
                  Pokreni
                </Button>
              </Link>
            </CardActions>
          </Card>

          <Card
            className={classes.root}
            classes={{ root: state4.raised ? classes.cardHovered : '' }}
            onMouseOver={() => setState4({ raised: true, shadow: 3 })}
            onMouseOut={() => setState4({ raised: false, shadow: 1 })}
            raised={state4.raised}
            zdepth={state4.shadow}
          >
            <CardActionArea>
              <CardMedia
                component='img'
                className={classes.media}
                image={UserImage}
              />
            </CardActionArea>
            <CardContent>
              <Link to={`/admin/userlist`}>
                <Typography gutterBottom variant='h5' component='h2'>
                  <strong>Korisnici</strong>
                </Typography>
              </Link>

              <Typography variant='body2' color='textSecondary' component='p'>
                Na ovom mjestu mozete uredjivati podatke registrovanih korisnika
              </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
              <Link to={`/admin/userlist`}>
                <Button
                  variant='outlined'
                  size='medium'
                  color='secondary'
                  className={classes.button}
                >
                  Pokreni
                </Button>
              </Link>
            </CardActions>
          </Card>
          <Card
            className={classes.root}
            classes={{ root: state5.raised ? classes.cardHovered : '' }}
            onMouseOver={() => setState5({ raised: true, shadow: 3 })}
            onMouseOut={() => setState5({ raised: false, shadow: 1 })}
            raised={state4.raised}
            zdepth={state4.shadow}
          >
            <CardActionArea>
              <CardMedia
                component='img'
                className={classes.media}
                image={Careers}
              />
            </CardActionArea>
            <CardContent>
              <Link to={`/admin/careerlist`}>
                <Typography gutterBottom variant='h5' component='h2'>
                  <strong>AITI Careers</strong>
                </Typography>
              </Link>

              <Typography variant='body2' color='textSecondary' component='p'>
                Na ovom mjestu mozete uredjivati podatke o AITI otvorenim
                pozicijama
              </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
              <Link to={`/admin/careerlist`}>
                <Button
                  variant='outlined'
                  size='medium'
                  color='secondary'
                  className={classes.button}
                >
                  Pokreni
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      </Paper>
    </div>
  )
}

export default Admin
