import { createMuiTheme } from '@material-ui/core/styles'

const aitiBlue = '#0b72b9'
const aitiLightBlue = '#2a86f7'
const aitiTirkiz = '#209fbb'
const aitiBlack = '#000010'
const aitiDark = '#2c3d46'
const aitiWhite = '#ffffff'
const aitiGrey = '#ededed'
const aitiGreyII = '#ECECEC'
const aitiGreyIII = '#e9e9e9'
const aitiGreyIV = '#E0E1E1'
const aitiOrange = '#fdba45'
const aitiPurple = '#4C1450'

const theme = createMuiTheme({
  //HEADER & FOOTER
  navHeight: {
    height: 50,
  },
  //PALLETE
  palette: {
    common: {
      aitiBlue: aitiBlue,
      aitiLightBlue: aitiLightBlue,
      aitiTirkiz: aitiTirkiz,
      aitiWhite: aitiWhite,
      aitiBlack: aitiBlack,
      aitiDark: aitiDark,
      aitiGrey: aitiGrey,
      aitiGreyII: aitiGreyII,
      aitiGreyIII: aitiGreyIII,
      aitiGreyIV: aitiGreyIV,
      aitiOrange: aitiOrange,
      aitiPurple: aitiPurple,
    },
    primary: {
      main: `${aitiWhite}`,
      contrastText: `${aitiBlack}`,
    },
    secondary: {
      main: `${aitiBlue}`,
    },
  },
  paperMain: {
    margin: 'auto',
    width: 1350,
    backgroundColor: `${aitiGrey}`,
    boxShadow: 'none',
  },
  h4: {
    color: `${aitiWhite}`,
    textTransform: 'none',
    backround: 'none',
  },
  h2: {
    color: `${aitiBlue}`,
    textTransform: 'none',
    backround: 'none',
  },
  h6: {
    color: `${aitiBlue}`,
    textTransform: 'none',
    backround: 'none',
  },
  mainTitle: {
    marginBottom: 20,
    textAlign: 'left',
    fontFamily: 'Playfair Display',
    color: '#000000',
    fontSize: '4.4rem',
    fontWeight: '700',
    textTransform: 'none',
  },
  mainSubTitle: {
    marginBottom: 20,
    textAlign: 'left',
    fontFamily: 'PT Sans',
    color: '#000000',
    fontSize: '1.5rem',
    fontWeight: '600',
    textTransform: 'none',
  },
  mainParagraph: {
    marginBottom: 20,
    textAlign: 'left',
    fontFamily: 'PT Sans',
    color: '#000000',
    fontSize: '1.3rem',
    fontWeight: '500',
    lineHeight: 1.7,
    textTransform: 'none',
  },
  titleSansSerif: {
    textAlign: 'left',
    fontFamily: 'PT Sans',
    color: '#000000',
    fontSize: '4rem',
    fontWeight: '600',
    textTransform: 'none',
  },

  subtitleSansSerif: {
    textAlign: 'left',
    fontFamily: 'PT Sans',
    color: '#000000',
    fontSize: '2rem',
    fontWeight: '500',
    textTransform: 'none',
  },
  paragrafSansSerif: {
    textAlign: 'left',
    fontFamily: 'PT Sans',
    color: '#000000',
    fontSize: '1.2rem',
    fontWeight: '300',
    textTransform: 'none',
  },
  registerButton: {
    height: 50,
    width: 180,
    color: '#ffffff',
    fontFamily: 'PT Sans',
    fontSize: '1.2rem',
    backgroundColor: `${aitiBlue}`,
    textTransform: 'none',
    textAlign: 'left',
    itemAlign: 'center',
    '&:hover': {
      backgroundColor: '#43a5f0',
      color: '#ffffff',
    },
  },

  typography: {
    tab: {
      textTransform: 'none',
      color: 'fff',
    },
    color: 'fff',
  },

  muiLink: {
    '&:before': {
      borderBottom: `2px solid ${aitiWhite}`,
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: `2px solid ${aitiBlue}`,
    },
  },
  Button: {
    textTransform: 'none',
  },
  button: {
    backgroundColor: '#7c18f5',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#000',
      color: '#fff',
    },
  },
  prijavaButton: {
    width: 300,
    backgroundColor: '#0B72B9',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#238497',
      color: '#fff',
    },
  },
  loginButton: {
    backgroundColor: '#0B72B9',
    color: '#fff',
    '&:hover': {
      backgroundColor: `${aitiLightBlue}`,
      color: '#fff',
    },
  },
})

export default theme
