import React from 'react'
import CookieConsent from 'react-cookie-consent'
//CSS
import { useTheme, makeStyles } from '@material-ui/core/styles'
//import theme from '../UI/Theme'
import { Card, CardMedia, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({}))

const CookiesConsent = () => {
  const classes = useStyles()

  return (
    <Card className={classes.cookieConsentMain}>
      <CookieConsent
        className={classes.CookieConsentBody}
        location='bottom'
        buttonText='Prihvati i zatvori'
        cookieName='myAwesomeCookieName2'
        classes={{ CookieConsent: classes.CookieConsentBody }}
        style={{
          background: '#4C1450',
          padding: '30px',
        }}
        buttonStyle={{
          background: '#ffffff',
          color: '#4C1450',
          width: '100%',
          fontSize: '15px',
        }}
        expires={150}
      >
        <p
          style={{
            fontSize: '20px',
          }}
        >
          Mi koristimo kolacice kako bi unaprijedili vase iskustvo.
        </p>
        <p>
          Mi koristimo kolacice iskljucivo kako bi ova stranica funkcionisala
          dobro. Molimo kliknite "Prihvatam" kako bi nam pomogli da unaprijedimo
          njenu efikasnost. Vise o nasem koristenju kolacica mozete procitati u
          nasoj Cookies politici
        </p>
      </CookieConsent>
    </Card>
  )
}

export default CookiesConsent
