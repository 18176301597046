import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//Components
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { Helmet } from "react-helmet";
//import Meta from '../components/Meta'
import { listVijestDetails } from "../../actions/vijestActions";
//Styles
import { Grid, Card, CardMedia, makeStyles } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import theme from "../../components/UI/Theme";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "60%",
    margin: "auto",
    marginTop: 80,
    padding: 20,
    justify: "center",
    alignItems: "flex-start",
    [theme.breakpoints.down("lg")]: { width: "80%" },
    [theme.breakpoints.down("sm")]: { width: "100%", padding: 0 },
  },
  mainContainer: { justify: "center", alignItems: "flex-start" },
  title: {
    marginBottom: 10,
    textAlign: "left",
    fontFamily: "Playfair Display",
    color: "#444444",
    fontSize: "4rem",
    fontWeight: "500",
    textTransform: "none",
    [theme.breakpoints.down("lg")]: { fontSize: "3rem" },
    [theme.breakpoints.down("sm")]: { padding: 20, fontSize: "2.5rem" },
  },
  author: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      fontSize: "1.1rem",
    },
  },
  datum: {
    marginTop: 10,
    marginBottom: 10,
    color: "#7B7C7D",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      marginTop: 2,
    },
  },
  mainContainerImage: {
    width: "100%",
    height: 400,
    [theme.breakpoints.down("sm")]: { height: "auto" },
  },
  media: {
    width: "100%",
    height: 400,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 300,
    },
  },
  socialMedia: { marginTop: 20, height: 40, margin: "auto" },
  socialFacebook: {
    marginRight: 10,
    paddingTop: 0,
    "& circle": {
      fill: "#4a4a4a",
      marginTop: 20,
      "&:hover": {
        fill: "#2b04d6",
        marginBottom: 20,
      },
    },
    "&:hover": { paddingTop: 40 },
  },
  socialTwitter: {
    marginRight: 10,
    paddingTop: 0,
    "& circle": {
      fill: "#4a4a4a",
      marginTop: 20,
      "&:hover": {
        fill: "#2b04d6",
        marginTop: 20,
      },
    },
    "&:hover": { paddingTop: 40 },
  },
  socialLinkedin: {
    marginRight: 10,
    paddingTop: 0,
    "& circle": {
      fill: "#4a4a4a",
      marginTop: 20,
      "&:hover": {
        fill: "#2b04d6",
        marginTop: 20,
      },
    },
    "&:hover": { paddingTop: 40 },
  },
  mainContainerBox: {
    marginTop: 0,
    justify: "center",
    alignItems: "flex-start",
  },

  newsType: {
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      fontSize: "1rem",
    },
  },
  sub_title: {
    marginBottom: 10,
    paddingLeft: 0,
    paddingTop: 10,
    height: "3rem",
    textAlign: "left",
    color: "#000",
    fontSize: "1.5rem",
    fontWeight: "500",
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
      height: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: 20,
      fontSize: "1.2rem",
    },
  },
  paragraf: {
    paddingTop: 20,
    fontSize: "1.1rem",
    lineHeight: "1.6",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
      fontSize: "1rem",
    },
  },
}));

const VijestScreen = ({ match }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const vijestDetails = useSelector((state) => state.vijestDetails);
  const { loading, error, vijest } = vijestDetails;

  useEffect(() => {
    dispatch(listVijestDetails(match.params.id));
  }, [dispatch, match]);

  return (
    <Grid container direction="column" className={classes.main}>
      <Link to="/vijesti">
        <KeyboardArrowLeftIcon
          style={{ marginTop: 0 }}
          width={35}
          height={20}
          fill={theme.palette.common.aitiBlue}
        />
        <span style={{ marginRight: 10, paddingTop: 20 }}>SVE VIJESTI</span>
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <Grid container direction="column" className={classes.mainContainer}>
          <Helmet>
            <meta charset="utf-8" />
            <title>{vijest.title}</title>
            <meta name="description" content={vijest.subTitle} />
            <meta name="csrf_token" content="" />
            <meta property="type" content="website" />
            <meta property="url" content={`/vijest/${vijest._id}`} />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-TileImage"
              content="/ms-icon-144x144.png"
            />
            <meta name="theme-color" content="#ffffff" />
            <meta name="_token" content="" />
            <meta name="robots" content="noodp" />
            <meta property="title" content={vijest.title} />
            <meta property="quote" content={vijest.subTitle} />
            <meta name="description" content={vijest.subTitle} />
            <meta property="image" content={vijest.image} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={vijest.title} />
            <meta property="og:quote" content={vijest.subTitle} />
            <meta property="og:image" content={vijest.image} />
            <meta content="image/*" property="og:image:type" />
            <meta
              property="og:url"
              content={`https://www.aiti.ba/vijest/${vijest._id}`}
            />
            <meta property="og:site_name" content="aiti.ba" />
            <meta property="og:description" content={vijest.subTitle} />
          </Helmet>
          <Grid item>
            <h3 className={classes.title}>{vijest.title}</h3>
          </Grid>
          <Grid item className={classes.author}>
            Pise {vijest.author}
          </Grid>
          <Grid item className={classes.datum}>
            {vijest.date}
          </Grid>
          <Card className={classes.mainContainerImage}>
            <CardMedia
              component="img"
              className={classes.media}
              image={vijest.image}
            />
          </Card>
          <Grid className={classes.socialMedia}>
            <FacebookShareButton
              url={`https://www.aiti.ba/vijest/${vijest._id}`}
              className={classes.socialFacebook}
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton
              url={`https://www.aiti.ba/vijest/${vijest._id}`}
              quote={"Napredni institut za tehnologije i inovacije"}
              hashtag="#AITI_Digital"
              className={classes.socialTwitter}
            >
              <TwitterIcon size={32} round={true} fill={"#000"} />
            </TwitterShareButton>
            <LinkedinShareButton
              url={`https://www.aiti.ba/vijest/${vijest._id}`}
              quote={"Napredni institut za tehnologije i inovacije"}
              hashtag="#AITI_Digital"
              className={classes.socialLinkedin}
              t
            >
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
          </Grid>
          <Grid container direction="column" alignItems="center">
            <Grid
              item
              xs
              direction="column"
              spacing={2}
              className={classes.mainContainerBox}
            >
              <Grid item className={classes.newsType}>
                {vijest.newsType}
              </Grid>

              <Grid item className={classes.sub_title}>
                {vijest.subTitle}
              </Grid>
              <Grid item className={classes.paragraf}>
                <div dangerouslySetInnerHTML={{ __html: vijest.description }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default VijestScreen;
