import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share";
//COMPONENTS
import Message from "../components/Message";
import Loader from "../components/Loader";
import Meta from "../components/Meta";
import VijestOne from "../components/Vijesti/VijestOne";
import KursOne from "../components/OBUKE/KursOne";
import { listKursevi } from "../actions/kursActions";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
//STYLES
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { CssBaseline, Box } from "@material-ui/core";
//IMAGES
import coverImage from "../images/aiticover1.jpg";
import firstImage from "../images/AITI-IMAGE.jpg";
import penjanje from "../images/penjanje_aiti.jpg";
import CookiesConsent from "../components/CookiesConsent";

const linkBox = {
  bgcolor: "transparent",
  m: 1,
  margin: "auto",
  style: { width: "300px", height: "60px" },
  borderColor: "#ffffff",
  display: "flex",
  justifyContent: "center",
};

const lineBox = {
  bgcolor: "transparent",
  m: 1,
  margin: "auto",
  style: { width: 1, height: "100%" },
  borderColor: "#fff",
  display: "flex",
  justifyContent: "center",
};
const lineBottom = {
  bgcolor: "transparent",
  m: 1,
  margin: "auto",
  style: { width: 340, height: 1 },
  borderColor: "#fff",
};
const linkBoxI = {
  bgcolor: "transparent",
  m: 1,
  margin: "auto",
  style: { width: "300px", height: "60px" },
  borderColor: "#fff",
  display: "flex",
  justifyContent: "center",
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    height: "80vh",
    [theme.breakpoints.down("xs")]: { height: "100vh" },
  },
  //COVER
  coverMedia: {
    height: "80vh",
    alignItem: "top",
    [theme.breakpoints.down("xs")]: { height: "100vh" },
  },
  coverBox: {
    width: 700,
    marginTop: "-50vh",
    [theme.breakpoints.down("md")]: { width: "90%", marginTop: "-50vh" },
    [theme.breakpoints.down("sm")]: { width: "100%", marginTop: "-55vh" },
  },
  coverBoxTop: {
    [theme.breakpoints.down("xs")]: {
      height: "45vh",
      width: "100%",
      backgroundColor: "#000",
      opacity: 0.8,
    },
  },
  coverTitle: {
    fontFamily: "Playfair Display",
    color: "#fff",
    fontSize: "2.8rem",
    fontWeight: 600,
    marginBottom: 20,
    paddingLeft: 140,
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
      fontWeight: 500,
      paddingLeft: 50,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      fontSize: "1.8rem",
      fontWeight: 600,
    },
  },
  coverSubTitle: {
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 140,
    color: "#fff",
    fontWeight: 300,
    fontSize: "1.1rem",
    lineHeight: 1.5,
    alignItem: "center",
    [theme.breakpoints.down("md")]: { paddingLeft: 50 },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: 0,
      padding: 20,
      backgroundColor: "#000",
      opacity: 0.8,
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
  coverBoxBottom: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "10vh",
      backgroundColor: "#000",
      opacity: 0.8,
    },
  },
  coverButton: {
    color: "#fff",
    textTransform: "none",
    fontSize: "1.1rem",
    paddingLeft: 140,
    "&:hover": {
      color: "#d5cfd5",
    },
    [theme.breakpoints.down("md")]: { paddingLeft: 50 },
    [theme.breakpoints.down("xs")]: {
      //width: '100%',
      marginRight: "auto",
      paddingLeft: 20,

      fontSize: "1rem",
    },
  },
  coverArrow: {
    marginRight: "1rem",
    color: theme.palette.common.aitiBlue,
  },
  //CARD - EXPERTIZE
  cardCover: {
    height: 600,
    marginTop: 40,
    backgroundImage: `url(${firstImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "left",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
  },
  cardBox: {
    width: "50%",
    marginTop: 150,
    paddingLeft: 100,
    alignItem: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "auto",
      marginTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  cardBoxDiv: {
    display: "flex",
    verticalAlign: "center",
    height: 50,
    marginTop: 40,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      marginBottom: 60,
    },
  },
  cardIcon: { height: 50, verticalAlign: "center" },
  cardIconText: { height: 50, paddingTop: 15, marginBottom: 40 },
  cardTitle: {
    ...theme.mainTitle,
    fontSize: "2.5rem",
    color: "#000",
    "& span": {
      color: theme.palette.common.aitiOrange,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  boxText: {
    height: 20,
    margin: 0,
    padding: 0,
    paddingRight: 10,
    paddingTop: 1,
    fontSize: "1rem",
    fontWeight: "1.2rem",
    color: "#000",
  },
  //II SEKCIJA
  drugiGrid: {
    height: "auto",
    width: "100%",
    padding: 60,
    backgroundColor: theme.palette.common.aitiBlue,
    [theme.breakpoints.down("xs")]: {
      padding: 20,
    },
  },
  drugiGridItem: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  drugiBox: {
    height: 400,
    width: "100%",
    padding: 60,
    verticalAlign: "center",
    alignItem: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: 20,
      padding: 20,
    },
  },
  lineLeft: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  lineBottom: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },

  drugiTitle: {
    ...theme.mainTitle,
    color: "#fdba45",
    textAlign: "center",
    [theme.breakpoints.down("xl")]: { fontSize: "3.5rem" },
    [theme.breakpoints.down("lg")]: { fontSize: "2.8rem" },
    [theme.breakpoints.down("md")]: { fontSize: "2.4rem" },
    [theme.breakpoints.down("sm")]: { fontSize: "2rem" },
  },
  drugiSubTitle: {
    height: 60,
    ...theme.mainSubTitle,
    letterSpacing: 5,
    textAlign: "center",
    color: "#ffffff",
  },
  drugiParagraf: {
    height: 80,
    marginBottom: 40,
    ...theme.mainSubTitle,
    lineHeight: "1.5rem",
    fontSize: "1rem",
    textAlign: "center",
    color: "#ffffff",
  },
  linkBoxLine: {
    paddingTop: 15,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.common.aitiPurple,
    },
  },
  linkBoxText: {
    height: 20,
    margin: 0,
    padding: 0,
    paddingRight: 10,
    paddingTop: 1,
    fontSize: "1rem",
    fontWeight: "1.2rem",
    color: "#ffffff",
  },
  // III SEKCIJA
  treciGrid: {
    height: 600,
    alignItems: "top",
    backgroundColor: "#5a5c5b",
  },
  mediaIII: {
    height: 600,
    width: "100%",
    alignItem: "right",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  treciGridBox: {
    padding: 100,
    paddingBottom: 40,
    [theme.breakpoints.down("xs")]: {
      padding: 20,
      paddingTop: 0,
    },
  },
  treciGridTitle: {
    ...theme.mainTitle,
    fontSize: "2.5rem",
    color: "#fdba45",
    "& span": {
      color: theme.palette.common.aitiOrange,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  treciGridSubTitle: {
    ...theme.mainSubTitle,
    color: theme.palette.common.aitiGrey,
    [theme.breakpoints.down("xl")]: { fontSize: "1,8rem" },
    [theme.breakpoints.down("lg")]: { fontSize: "1.6rem" },
    [theme.breakpoints.down("md")]: { fontSize: "1.3rem" },
    [theme.breakpoints.down("sm")]: { fontSize: "1.1rem" },
  },
  buttonBox: {
    paddingTop: 15,
    cursor: "pointer",
    marginLeft: 100,
    "&:hover": {
      backgroundColor: theme.palette.common.aitiGrey,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 20,
    },
  },
  buttonBoxII: {
    paddingTop: 15,
    cursor: "pointer",
    marginLeft: 100,
    "&:hover": {
      backgroundColor: theme.palette.common.aitiPurple,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 20,
    },
  },
  boxTextI: {
    height: 20,
    margin: 0,
    padding: 0,
    paddingRight: 10,
    paddingTop: 1,
    fontSize: "1rem",
    fontWeight: "1.2rem",
    color: "#fff",
  },
}));

const HomeScreen = ({ match }) => {
  const classes = useStyles();
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;
  const dispatch = useDispatch();
  const kursList = useSelector((state) => state.kursList);
  const { loading, error } = kursList;

  useEffect(() => {
    dispatch(listKursevi(keyword, pageNumber));
  }, [dispatch, keyword, pageNumber]);

  return (
    <>
      <CssBaseline />
      <Meta />
      <Card className={classes.root}>
        <CardMedia
          className={classes.coverMedia}
          image={coverImage}
          title="AITI"
        />
        <Grid container direction="column" className={classes.coverBox}>
          <Grid sm item className={classes.coverBoxTop}>
            <Typography className={classes.coverTitle}>
              AITI otvara vrata za sve: Digitalne vjestine su buducnost
            </Typography>
            <Typography className={classes.coverSubTitle}>
              U novom normalnom, drustvo, biznisi i licni zivot ovisi o
              digitalnim tehnologijama kao nikad do sad. Kao nikad do sad,
              postoji potreba za kadrovima koji posjeduju digitalne vjestine
              koje ce pomoci biznisima i zajednici da uspjesno zapocnu
              digigitalnu transformaciju.
            </Typography>
          </Grid>
          <Grid sm item className={classes.coverBoxBottom}>
            <Button
              className={classes.coverButton}
              component={Link}
              to="/obuke"
            >
              <span style={{ marginRight: 10 }}>Saznaj vise</span>
              <ArrowForwardIcon className={classes.coverArrow} />
            </Button>
          </Grid>
        </Grid>
      </Card>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <>
            <VijestOne />
          </>
          <>
            <KursOne />
          </>
        </>
      )}{" "}
      <motion.div
        initial={false}
        animate={{ opacity: 0.7 }}
        whileHover={{ opacity: 1 }}
        whileTap={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Card className={classes.cardCover}>
          <Box className={classes.cardBox}>
            <div className={classes.cardBoxDiv}>
              <Box component="div" display="inline">
                <ViewColumnIcon className={classes.cardIcon} />
              </Box>

              <Box component="div" display="inline">
                <p className={classes.cardIconText}>
                  Tehnologije i digitalna transformacija
                </p>
              </Box>
            </div>

            <h3 className={classes.cardTitle}>
              Zelite staviti <span>tehnologije i inovacije </span>u vrh
              prioriteta
            </h3>
          </Box>
          <Link to="/oblasti">
            <Box
              justifyContent="center"
              border={1}
              cursor="pointer"
              className={classes.buttonBox}
              {...linkBox}
              alignItem="center"
            >
              <p className={classes.boxText}>Oblasti expertize</p>
              <span>
                <ArrowForwardIosIcon style={{ fill: "#000" }} />
              </span>
            </Box>
          </Link>
        </Card>
      </motion.div>
      <Grid container direction="row" className={classes.drugiGrid}>
        <Grid item className={classes.drugiGridItem}>
          <Box className={classes.drugiBox}>
            <h3 className={classes.drugiTitle}>ZA POJEDINCE</h3>
            <p className={classes.drugiSubTitle}>NOVI POSLOVI, NOVE VJESTINE</p>
            <p className={classes.drugiParagraf}>
              Posveceni razvoju vitalnih vjestina za buduce trziste rada i
              smanjivanje jaza u vjestinama
            </p>
            <Link to="/vjestine">
              <Box
                display="flex"
                justifyContent="center"
                border={1}
                cursor="pointer"
                className={classes.linkBoxLine}
                {...linkBox}
                alignItem="center"
              >
                <p className={classes.linkBoxText}>
                  Kako mozes razviti vjestine
                </p>
                <span>
                  <ArrowForwardIosIcon style={{ fill: "#ffffff" }} />
                </span>
              </Box>
            </Link>
          </Box>
        </Grid>
        <Box className={classes.lineLeft}>
          <Box
            display="flex"
            justifyContent="center"
            borderLeft={1}
            {...lineBox}
            alignItem="center"
          ></Box>
        </Box>
        <Box className={classes.lineBottom}>
          <Box
            justifyContent="center"
            border={1}
            {...lineBottom}
            verticalAlign="center"
          ></Box>
        </Box>
        <Grid sm item className={classes.drugiGridItem}>
          <Box className={classes.drugiBox}>
            <h3 className={classes.drugiTitle}>ZA ORGANIZACIJE</h3>
            <p className={classes.drugiSubTitle}>
              PREKVALIFIKACIJA I DIGITALNI TRENING
            </p>
            <p className={classes.drugiParagraf}>
              Predani podrsci transformaciji nacina obavljanja posla koristenjem
              naprednih digitalnih tehnologija.
            </p>
            <Link to="/usluge">
              <Box
                display="flex"
                justifyContent="center"
                border={1}
                cursor="pointer"
                className={classes.linkBoxLine}
                {...linkBox}
                alignItem="center"
              >
                <p className={classes.linkBoxText}>Vise o nasim uslugama</p>
                <span>
                  <ArrowForwardIosIcon style={{ fill: "#fff" }} />
                </span>
              </Box>
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row-reverse" className={classes.treciGrid}>
        <Grid item md={6} xs={12}>
          <CardMedia
            component="img"
            className={classes.mediaIII}
            image={penjanje}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Box className={classes.treciGridBox}>
            <h3 className={classes.treciGridTitle}>Pridruzi se AITI</h3>
            <p className={classes.treciGridSubTitle}>
              Saznaj vise o mogucnostima za rad, ucenje uz rad i prakticnu obuku
              koje trenutno nudimo
            </p>
          </Box>
          <Link to="/careers">
            <Box
              display="flex"
              justifyContent="center"
              border={1}
              cursor="pointer"
              className={classes.buttonBoxII}
              {...linkBoxI}
              alignItem="center"
            >
              <p className={classes.boxTextI}>Saznaj vise</p>
              <span>
                <ArrowForwardIosIcon style={{ fill: "#ffffff" }} />
              </span>
            </Box>
          </Link>
        </Grid>
      </Grid>
      <CookiesConsent />
    </>
  );
};

export default HomeScreen;
