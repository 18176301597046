import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listOneVijest } from '../../actions/vijestActions'
//Components
import Loader from '../Loader'
import Message from '../Message'
import ButtonArrow from '../UI/ButtonArrow'
//CSS
import { makeStyles } from '@material-ui/core/styles'
import theme from '../UI/Theme'
import { Card, CardMedia, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  main: { width: '100%' },
  card: {
    margin: 0,
    padding: 0,
    minWidth: '100%',
    height: 600,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 600,
    },
  },
  main_grid: {
    width: '100%',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  main_grid_item: {
    minWidth: '50%',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  media: {
    width: '100%',
    height: 600,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 300,
    },
  },
  main_grid_itemBox: {
    minWidth: '50%',
    margin: 0,
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {},
    [theme.breakpoints.down('md')]: {
      //height: 140,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  titleBox: {
    width: '100%',
    height: '100%',
    padding: 80,
    paddingBottom: 20,
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: 40,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
  },
  title: {
    paddingTop: 80,
    textAlign: 'left',
    fontFamily: 'Playfair Display',
    color: '#000000',
    fontSize: '3.5rem',
    fontWeight: '600',
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2.5rem',
      paddingLeft: 0,
      fontSize: '2rem',
    },
  },
  sub_title: {
    height: '3rem',
    paddingTop: 10,
    marginBottom: 20,
    fontFamily: 'PT Sans',
    textAlign: 'left',
    color: '#444444',
    fontSize: '1.5rem',
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
      height: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      paddingLeft: 0,
      fontSize: '1.2rem',
    },
  },
  grid_link: {
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  main_grid_link: {
    //marginLeft: 90,
    textAlign: 'left',
    color: '#000',
    fontSize: '1.2rem',
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      fontSize: '1rem',
    },
  },
}))

const VijestOne = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const vijestOne = useSelector((state) => state.vijestOne)
  const { loading, error, vijesti } = vijestOne

  useEffect(() => {
    dispatch(listOneVijest())
  }, [dispatch])

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <div className={classes.main}>
      {vijesti.map((vijest) => (
        <Card className={classes.card} key={vijest._id}>
          <Grid container direction='row' className={classes.main_grid}>
            <Grid sm item className={classes.main_grid_item}>
              <CardMedia
                component='img'
                className={classes.media}
                image={vijest.image}
              />
            </Grid>
            <Grid sm item className={classes.main_grid_itemBox}>
              <div className={classes.titleBox}>
                <h3 className={classes.title}>{vijest.title}</h3>
                <p className={classes.sub_title}>{vijest.subTitle}</p>
                <Link
                  className={classes.grid_link}
                  to={`/vijest/${vijest._id}`}
                >
                  <p className={classes.main_grid_link}>
                    <span style={{ marginRight: 10 }}>
                      Istrazi vise o ovoj temi
                    </span>
                    <ButtonArrow
                      className={classes.coverArrow}
                      width={35}
                      height={25}
                      fill={theme.palette.common.aitiBlue}
                    />
                  </p>
                </Link>
              </div>
            </Grid>
          </Grid>
        </Card>
      ))}
    </div>
  )
}

export default VijestOne
