import React from 'react'
import { Link } from 'react-router-dom'
//Components
import TehnologijePregledComponent from '../../components/Pages/TehnologijePregled'
import Meta from '../../components/Meta'

//Styles
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    height: 'auto',
    backgroundColor: '#2c3d46',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  prviGrid: { height: '100vh' },
}))

const OblastiTehnologijeScreen = ({ match }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Meta />
      <Grid className={classes.prviGrid}>
        <TehnologijePregledComponent />
      </Grid>
    </Paper>
  )
}

export default OblastiTehnologijeScreen
