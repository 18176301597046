import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { saveShippingAddress } from '../../actions/cartActions'
//Components
import FormContainer from '../../components/UI/FormContainer'
import CheckoutSteps from '../../components/OBUKE/CheckoutSteps'
//Styles
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import theme from '../../components/UI/Theme'
import formTheme from '../../components/UI/FormTheme'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  TextField,
} from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    ...theme.paperMain,
    height: 'auto',
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 400,
      padding: 0,
    },
  },
  box: {
    display: 'flex',
    verticalAlign: 'center',
    height: 50,
    marginTop: 80,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginBottom: 60,
    },
  },
  coverArrow: { height: 50, verticalAlign: 'center' },
  coverArrow1: { height: 50, paddingTop: 15 },

  coverBox: {
    height: 200,
    [theme.breakpoints.down('xs')]: {
      height: 250,
    },
  },

  headerSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 0,
    marginBottom: 0,
    fontFamily: 'PT Sans',
    fontSize: '1.6rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      height: '2rem',
      paddingBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      height: 50,
      margin: 0,
      paddingLeft: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    marginTop: 20,
    letterSpacing: '2px',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 20,
      fontSize: '2.5rem',
    },
  },
  prijavaButton: {
    ...theme.prijavaButton,
    marginTop: 50,
    marginBottom: 50,
  },
  boxForm: {
    marginBottom: 20,
  },
})

const ShippingScreen = ({ history }) => {
  const classes = useStyles()
  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  const [address, setAddress] = useState(shippingAddress.address)
  const [city, setCity] = useState(shippingAddress.city)
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
  const [country, setCountry] = useState(shippingAddress.country)

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(saveShippingAddress({ address, city, postalCode, country }))
    history.push('/payment')
  }

  return (
    <div className={classes.main}>
      <Paper className={classes.paper_top}>
        <Box>
          <Link to='/kursevi'>
            <div className={classes.box}>
              <Box component='div' display='inline'>
                <KeyboardArrowLeftIcon className={classes.coverArrow} />
              </Box>
              <Box
                component='div'
                display='inline'
                className={classes.coverArrow1}
              >
                SVI KURSEVI
              </Box>
            </div>
          </Link>
        </Box>
        <Grid
          container
          direction='column'
          justify='space-between'
          className={classes.coverBox}
        >
          <Box>
            <h3 className={classes.mainTitle}>Hvala na prijavi</h3>
            <p className={classes.headerSubTitle}>
              Molimo popunite podatke ispod koji su neophodni za administraciju
              vase prijave
            </p>
          </Box>
          <Divider />
        </Grid>
      </Paper>
      <FormContainer>
        <CheckoutSteps step1 step2 />
        <ThemeProvider theme={formTheme}>
          <form onSubmit={submitHandler}>
            <FormGroup controlId='address' className={classes.boxForm}>
              <FormLabel>Adresa</FormLabel>
              <FormControl
                type='text'
                placeholder='Unesite vasu adresu'
                value={address}
                required
                onChange={(e) => setAddress(e.target.value)}
              >
                <TextField
                  id='outlined-basic'
                  label='Unesite vasu adresu'
                  variant='filled'
                />
              </FormControl>
            </FormGroup>

            <FormGroup controlId='city' className={classes.boxForm}>
              <FormLabel>Grad</FormLabel>
              <FormControl
                type='text'
                placeholder='Grad'
                value={city}
                required
                onChange={(e) => setCity(e.target.value)}
              >
                <TextField id='outlined-basic' label='Grad' variant='filled' />
              </FormControl>
            </FormGroup>

            <FormGroup controlId='postalCode' className={classes.boxForm}>
              <FormLabel>Postanski broj</FormLabel>
              <FormControl
                type='text'
                placeholder='Postanski broj'
                value={postalCode}
                required
                onChange={(e) => setPostalCode(e.target.value)}
              >
                <TextField
                  id='outlined-basic'
                  label='Postanski broj'
                  variant='filled'
                />
              </FormControl>
            </FormGroup>

            <FormGroup controlId='country' className={classes.boxForm}>
              <FormLabel>Drzava</FormLabel>
              <FormControl
                type='text'
                placeholder='Bosna i Hercegovina'
                value={country}
                required
                onChange={(e) => setCountry(e.target.value)}
              >
                <TextField
                  id='outlined-basic'
                  label='Bosna i Hercegovina'
                  variant='filled'
                />
              </FormControl>
            </FormGroup>

            <Button type='submit' className={classes.prijavaButton}>
              Nastavi
            </Button>
          </form>
        </ThemeProvider>
      </FormContainer>
    </div>
  )
}

export default ShippingScreen
