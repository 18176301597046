import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  listVijesti,
  deleteVijest,
  createVijest,
} from '../../actions/vijestActions'
import { VIJEST_CREATE_RESET } from '../../constants/vijestConstants'
//Komponente
import Message from '../../components/UI/Message'
import Loader from '../../components/UI/Loader'
import Paginate from '../../components/Paginate'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import theme from '../../components/UI/Theme'
import {
  makeStyles,
  Paper,
  Box,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    padding: theme.spacing(1.4),
    margin: 'auto',
    width: 1350,
    backgroundColor: theme.palette.common.aitiGrey,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paperBottom: {
    padding: theme.spacing(1.4),
    margin: 'auto',
    width: 1350,
    backgroundColor: theme.palette.common.aitiGrey,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '400px',
      padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  box: {
    display: 'flex',
    verticalAlign: 'center',
    height: 50,
    marginTop: 80,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginBottom: 60,
    },
  },
  coverArrow: { height: 50, verticalAlign: 'center' },
  coverArrow1: { height: 50, paddingTop: 15 },

  coverBox: {
    height: 'auto',
    width: 'auto',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      height: 420,
      width: '370px',
    },
  },
  titleBox: {
    width: '900px',
    height: 'auto',

    paddingTop: 80,
    marginBottom: 80,
    [theme.breakpoints.down('md')]: {
      height: '400px',
      width: '80%',
      paddingTop: 80,
    },
    [theme.breakpoints.down('sm')]: {
      height: '500px',
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      height: 320,
      width: '100%',
      padding: 20,
      paddingTop: 80,
      marginBottom: 0,
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    height: 'auto',
    marginTop: 20,
    fontSize: '2.5rem',
    letterSpacing: '0.1rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
      height: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '1.8rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 0,
      fontSize: '1.8rem',
    },
  },
  mainSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 40,
    fontFamily: 'PT Sans',
    fontSize: '1.8rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
      height: '2rem',
      paddingBottom: 50,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      height: '10rem',
      margin: 0,
      paddingRight: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
  root: {
    width: 350,
    marginBottom: 40,
    backgroundColor: theme.palette.common.aitiGrey,
    transition: 'transform 0.25s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: '5%',
      backgroundColor: theme.palette.common.aitiGrey,
    },
  },
  media: {
    height: 240,
    backgroundColor: theme.palette.common.aitiGrey,
  },
  cardHovered: {
    transform: 'scale3d(1.01, 1.01, 1)',
    backgroundColor: theme.palette.common.aitiWhite,
  },
  actions: {
    paddingLeft: 16,
    paddingBottom: 16,
  },
  button: {
    ...theme.button,
  },
  table: {
    minWidth: 650,
  },
})

function VijestListScreen({ history, match }) {
  const classes = useStyles()
  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  const vijestList = useSelector((state) => state.vijestList)
  const { loading, error, vijesti, pages, page } = vijestList

  const vijestDelete = useSelector((state) => state.vijestDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = vijestDelete

  const vijestCreate = useSelector((state) => state.vijestCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    vijest: createdVijest,
  } = vijestCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: VIJEST_CREATE_RESET })
    if (!userInfo.isAdmin) {
      history.push('/login')
    }
    if (successCreate) {
      history.push(`/admin/vijest/${createdVijest._id}/edit`)
    } else {
      dispatch(listVijesti('', pageNumber))
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdVijest,
    pageNumber,
  ])

  const deleteHandler = (id) => {
    if (window.confirm('Da li ste sigurni da zelite da obrisete vijest?')) {
      dispatch(deleteVijest(id))
    }
  }
  const createVijestHandler = () => {
    dispatch(createVijest())
  }

  return (
    <div className={classes.main}>
      <Paper className={classes.paper_top}>
        <Box>
          <Link to='/administracija'>
            <div className={classes.box}>
              <Box component='div' display='inline'>
                <KeyboardArrowLeftIcon className={classes.coverArrow} />
              </Box>
              <Box
                component='div'
                display='inline'
                className={classes.coverArrow1}
              >
                ADMINISTRACIJA
              </Box>
            </div>
          </Link>
        </Box>
        <Grid container justify='space-between' className={classes.coverBox}>
          <Box className={classes.titleBox}>
            <h3 className={classes.mainTitle}>ADMINISTRACIJA AITI VIJESTI</h3>
            <p className={classes.mainSubTitle}>
              {userInfo.name}, vi ste ovlasteni da administrirate sekciju AITI
              vijesti.
            </p>
            <Button className={classes.button} onClick={createVijestHandler}>
              <i className='fas fa-plus'></i>
              &ensp; Kreiraj vijest
            </Button>
          </Box>
        </Grid>
      </Paper>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Paper className={classes.paperBottom}>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              stickyHeader
              aria-label='sticky table'
            >
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Naslov</TableCell>
                  <TableCell align='left'>Autor</TableCell>
                  <TableCell align='left'>Tip vijesti</TableCell>
                  <TableCell align='left'>Kategorija</TableCell>
                  <TableCell align='left'>Datum</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vijesti.map((vijest) => (
                  <TableRow key={vijest._id}>
                    <TableCell align='left'>{vijest.title}</TableCell>
                    <TableCell align='left'>{vijest.author}</TableCell>
                    <TableCell align='left'>{vijest.newsType}</TableCell>
                    <TableCell align='left'>{vijest.category}</TableCell>
                    <TableCell align='left'>{vijest.date}</TableCell>

                    <TableCell align='left'>
                      <Button
                        component={Link}
                        to={`/admin/vijest/${vijest._id}/edit`}
                      >
                        <i className='fas fa-edit'></i>
                      </Button>
                    </TableCell>
                    <TableCell align='left'>
                      <Button
                        variant='danger'
                        className='btn-sm'
                        onClick={() => deleteHandler(vijest._id)}
                      >
                        <i className='fas fa-trash'></i>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Paginate pages={pages} page={page} isAdmin={true} />
        </Paper>
      )}
    </div>
  )
}

export default VijestListScreen
