import {
  KURS_LIST_REQUEST,
  KURS_LIST_SUCCESS,
  KURS_LIST_FAIL,
  KURS_DETAILS_REQUEST,
  KURS_DETAILS_SUCCESS,
  KURS_DETAILS_FAIL,
  KURS_DELETE_REQUEST,
  KURS_DELETE_SUCCESS,
  KURS_DELETE_FAIL,
  KURS_CREATE_REQUEST,
  KURS_CREATE_SUCCESS,
  KURS_CREATE_FAIL,
  KURS_CREATE_RESET,
  KURS_UPDATE_REQUEST,
  KURS_UPDATE_SUCCESS,
  KURS_UPDATE_FAIL,
  KURS_UPDATE_RESET,
  KURS_CREATE_REVIEW_REQUEST,
  KURS_CREATE_REVIEW_SUCCESS,
  KURS_CREATE_REVIEW_FAIL,
  KURS_CREATE_REVIEW_RESET,
  KURS_TOP_REQUEST,
  KURS_TOP_SUCCESS,
  KURS_TOP_FAIL,
  KURS_ONE_REQUEST,
  KURS_ONE_SUCCESS,
  KURS_ONE_FAIL,
} from '../constants/kursConstants'

export const kursListReducer = (state = { kursevi: [] }, action) => {
  switch (action.type) {
    case KURS_LIST_REQUEST:
      return { loading: true, kursevi: [] }
    case KURS_LIST_SUCCESS:
      return {
        loading: false,
        kursevi: action.payload.kursevi,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case KURS_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const kursDetailsReducer = (
  state = { kurs: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case KURS_DETAILS_REQUEST:
      return { loading: true, ...state }
    case KURS_DETAILS_SUCCESS:
      return { loading: false, kurs: action.payload }
    case KURS_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const kursDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case KURS_DELETE_REQUEST:
      return { loading: true }
    case KURS_DELETE_SUCCESS:
      return { loading: false, success: true }
    case KURS_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const kursCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case KURS_CREATE_REQUEST:
      return { loading: true }
    case KURS_CREATE_SUCCESS:
      return { loading: false, success: true, kurs: action.payload }
    case KURS_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case KURS_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const kursUpdateReducer = (state = { kurs: {} }, action) => {
  switch (action.type) {
    case KURS_UPDATE_REQUEST:
      return { loading: true }
    case KURS_UPDATE_SUCCESS:
      return { loading: false, success: true, kurs: action.payload }
    case KURS_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case KURS_UPDATE_RESET:
      return { kurs: {} }
    default:
      return state
  }
}

export const kursCreateReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case KURS_CREATE_REVIEW_REQUEST:
      return { loading: true }
    case KURS_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true }
    case KURS_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload }
    case KURS_CREATE_REVIEW_RESET:
      return {}
    default:
      return state
  }
}

export const kursTopRatedReducer = (state = { kursevi: [] }, action) => {
  switch (action.type) {
    case KURS_TOP_REQUEST:
      return { loading: true, kursevi: [] }
    case KURS_TOP_SUCCESS:
      return { loading: false, kursevi: action.payload }
    case KURS_TOP_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const kursOneReducer = (state = { kursevi: [] }, action) => {
  switch (action.type) {
    case KURS_ONE_REQUEST:
      return { loading: true, kursevi: [] }
    case KURS_ONE_SUCCESS:
      return { loading: false, kursevi: action.payload }
    case KURS_ONE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
