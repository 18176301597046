import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listCareers } from '../../actions/careerActions'
//Components
import Meta from '../../components/Meta'
import CareerList from '../../components/CAREERS/CareersList'
import CareerOne from '../../components/CAREERS/CareersSelected'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
//import CareerOne from '../../components/CAREERS/CareerOne'
//Styles
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../components/UI/Theme'
import { Box, Card, Grid, CardMedia, Paper } from '@material-ui/core'
// Images
import coverImage from '../../images/careers-cover.jpg'
import secondImage from '../../images/careers-second.jpg'
const useStyles = makeStyles((theme) => ({
  //SHEET
  root: {
    maxWidth: '100%',
    height: 'auto',
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  //COVER
  cover: {
    maxWidth: '100%',
    height: 600,
    marginTop: 80,
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
      marginTop: 60,
    },
  },
  media: {
    height: '100vh',
    alignItem: 'bottom',
    [theme.breakpoints.down('sm')]: { height: '55vh' },
  },
  coverBox: {
    height: '100vh',
    width: '41.5vw',
    marginTop: '-100vh',
    paddingLeft: 140,
    paddingRight: 20,
    backgroundColor: '#fff',
    opacity: 0.7,
    color: '#000',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 60,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 0,
      paddingLeft: 20,
    },
  },
  coverTitle: {
    ...theme.mainTitle,
    marginBottom: 20,
    paddingTop: 140,
    color: '#000',
    fontSize: '4rem',
    fontWeight: '500',
    [theme.breakpoints.down('lg')]: {
      fontSize: '3.2rem',
      marginBottom: 10,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.8rem',
      marginBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.2rem',
      fontWeight: '600',
      marginBottom: 10,
      paddingTop: 20,
    },
  },
  coverSubTitle: {
    marginBottom: 10,
    color: '#000',
    fontWeight: '300',
    fontSize: '1.1rem',
    lineHeight: '1.6',
    alignItem: 'center',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 0,
      paddingRight: 20,
    },
  },
  // PRVI DIO
  prviDio: {
    maxWidth: '100%',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      //height: '100vh',
      //marginTop: 60,
    },
  },
  prvi_dio_box: {
    //height: 'auto',
    width: '50%',
    margin: 'auto',
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
      //paddingLeft: 60,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 20,
      paddingTop: 0,
    },
  },
  prvi_dioTitle: {
    ...theme.mainTitle,
    paddingTop: 80,
    fontSize: '1rem',
    letterSpacing: 8,
    textAlign: 'center',
    color: '#000',
    fontSize: '1rem',
    fontWeight: '500',
    lineHeight: 1.8,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      //marginBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: '500',
      marginBottom: 10,
      paddingTop: 20,
    },
  },
  //DRUGI DIO
  drugiDio: {
    maxWidth: '100%',
    height: 600,
    [theme.breakpoints.down('sm')]: {
      height: 450,
      //marginTop: 60,
    },
  },
  image_II: {
    height: 600,
    alignItem: 'bottom',
    [theme.breakpoints.down('sm')]: { height: '55vh' },
  },
  drugiBox: {
    height: '100vh',
    width: '41.5vw',
    marginTop: -600,
    paddingLeft: 140,
    backgroundColor: '#fff',
    opacity: 0.7,
    color: '#000',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 80,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 140,
      marginTop: -140,
      paddingLeft: 0,
    },
  },
  drugi_dioTitle: {
    ...theme.titleSansSerif,
    width: '30vw',
    paddingTop: 180,
    fontSize: '3rem',
    letterSpacing: 8,
    color: '#000',
    fontWeight: 500,
    zIndex: '1250 !important',
    '& span': {
      color: theme.palette.common.aitiTirkiz,
      fontWeight: 600,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
      paddingTop: 220,
    },
    [theme.breakpoints.down('sm')]: {
      width: '75%',
      fontSize: '1.2rem',
      fontWeight: 500,
      textAlign: 'center',
      margin: 'auto',
      paddingTop: 0,
    },
  },
  //TRECI DIO
  mainGrid_vs: {
    padding: theme.spacing(2),
    justify: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 0,
    },
  },
  mainGridItem_left: {
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '50%',
      margin: 0,
      padding: 20,
    },
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: 0,
    },
  },
  //CETVRTI DIO
  cetvrti_dioTitle: {
    ...theme.titleSansSerif,
    width: '30vw',
    paddingLeft: 40,
    paddingTop: 60,
    fontSize: '3rem',
    letterSpacing: 8,
    color: '#000',
    fontWeight: 500,
    zIndex: '1250 !important',
    '& span': {
      color: theme.palette.common.aitiTirkiz,
      fontWeight: 600,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
      paddingTop: 60,
    },
    [theme.breakpoints.down('sm')]: {
      width: '75%',
      fontSize: '1.2rem',
      fontWeight: 500,
      textAlign: 'center',
      margin: 'auto',
      paddingTop: 20,
      paddingLeft: 0,
    },
  },
  cetvrtiDioSubTitle: {
    width: '50%',
    padding: 40,
    paddingTop: 0,
    marginBottom: 10,
    color: '#000',
    fontWeight: '300',
    fontSize: '1.1rem',
    lineHeight: '1.6',
    alignItem: 'center',
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 0,
      textAlign: 'center',
    },
  },
}))

const CareersScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const careerList = useSelector((state) => state.careerList)
  const { loading, error, careers } = careerList

  useEffect(() => {
    dispatch(listCareers())
  }, [dispatch])

  return (
    <Paper className={classes.root}>
      <Meta />
      <Card className={classes.cover}>
        <CardMedia className={classes.media} image={coverImage} title='AITI' />

        <Grid container direction='column' className={classes.coverBox}>
          <h3 className={classes.coverTitle}>
            Radujemo se razvoju vase karijere u AITI
          </h3>
          <p className={classes.coverSubTitle}>
            Upotrijebi svoje talente i svaki dan napravi nesto novo.
          </p>
          <p className={classes.coverSubTitle}>
            Bilo da imas iskustvo ili si tek nedavno zavrsio obrazovanje, raditi
            sa AITI moze biti izazovan i korisatan korak u vasoj karijeri.
          </p>
        </Grid>
      </Card>
      <Card className={classes.prviDio}>
        <Grid container direction='column' className={classes.prvi_dio_box}>
          <h3 className={classes.prvi_dioTitle}>
            MI TI MOZEMO POMOCI DA RAZVIJES SVOJU KARIJERU
          </h3>
        </Grid>
      </Card>
      <Card className={classes.drugiDio}>
        <CardMedia
          className={classes.image_II}
          image={secondImage}
          title='AITI'
        />
        <Grid container direction='column' className={classes.drugiBox}>
          <h3 className={classes.drugi_dioTitle}>Iskoristi svaku priliku</h3>
        </Grid>
      </Card>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <>
            <CareerOne />
          </>
        </>
      )}

      <h1 className={classes.cetvrti_dioTitle}>AITI KARIJERA</h1>
      <p className={classes.cetvrtiDioSubTitle}>
        Bilo da imas iskustvo ili si tek nedavno zavrsio obrazovanje, raditi sa
        AITI moze biti izazovan i korisatan korak u vasoj karijeri.
      </p>
      {loading ? (
        <h2>Loading.....</h2>
      ) : error ? (
        <h3>{error}</h3>
      ) : (
        <Grid container className={classes.mainGrid_vs}>
          {careers.map((career) => (
            <Grid
              item
              className={classes.mainGridItem_left}
              key={career._id}
              xs={12}
              sm={12}
              md={6}
              lg={4}
              //xl={3}
            >
              <CareerList career={career} />
            </Grid>
          ))}
        </Grid>
      )}
    </Paper>
  )
}

export default CareersScreen
