import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listTopKursevi } from '../../actions/kursActions'
//Komponente
import Loader from '../UI/Loader'
import Message from '../UI/Message'
//CSS
import { makeStyles } from '@material-ui/core/styles'
import theme from '../UI/Theme'
import { Card, CardMedia, Grid } from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    ...theme.paperMain,
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },

  gridCardRight: {
    width: '40%',
    padding: 20,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 10,
    },
  },

  gridCardLeftContainer: {
    display: 'inline',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  mainGrid: {
    padding: 10,

    '&:hover': {
      transform: 'scale3d(1.01, 1.01, 1)',
      backgroundColor: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {},
  },

  mediaCard: {
    height: 200,
  },
  media: {
    width: '100%',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 200,
    },
  },
  subtitle: {
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
})

const KursTopTri = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const kursTopRated = useSelector((state) => state.kursTopRated)
  const { loading, error, kursevi } = kursTopRated

  useEffect(() => {
    dispatch(listTopKursevi())
  }, [dispatch])

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <Grid container direction='column'>
      {kursevi.map((kurs) => (
        <Grid item key={kurs._id} className={classes.mainGrid}>
          <Grid item>
            <Card className={classes.mediaCard}>
              <CardMedia
                component='img'
                image={kurs.image}
                className={classes.media}
              />
            </Card>
          </Grid>
          <Grid item className={classes.subtitle}>
            <Link to={`/kurs/${kurs._id}`}>
              <h5>
                {kurs.name} (KM {kurs.price})
              </h5>
            </Link>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default KursTopTri
