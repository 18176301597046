import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Divider } from '@material-ui/core'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('xs')]: {
      height: 700,
    },
  },
  mainContainter: {
    width: '50%',
    margin: 0,
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  gridItem: {
    width: '50%',
    padding: '3em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '2em',
      paddingBottom: 0,
    },
  },
  link: {
    paddingRight: 40,
    color: theme.palette.common.aitiBlack,
    fontFamily: 'Roboto',
    fontSize: '0.9rem',
    fontWeight: '400',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.common.aitiOrange,
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
    },
  },
  title: {
    paddingTop: 0,
    marginLeft: 0,
    fontSize: 38,
    color: theme.palette.common.aitiBlack,
  },
  subtitle: {
    marginLeft: 0,
    marginTop: -20,
    fontSize: 18,
    color: theme.palette.common.aitiBlack,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  contactTitle: {
    marginBottom: 20,
    paddingTop: 0,
    color: theme.palette.common.aitiBlack,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  titleAddress: {
    paddingTop: 20,
    marginBottom: 20,
    color: theme.palette.common.aitiBlack,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  paragraf: {
    marginLeft: 0,
    marginTop: 0,
    marginBottom: 5,
    fontSize: '0.9rem',
    height: '1.1rem',
    color: '#000',
  },
  gridItemRight: {
    width: '100%',
    height: 160,
    padding: '3em',
    //marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      padding: '2em',
      height: 160,
    },
  },
  policy: { height: 40, [theme.breakpoints.down('xs')]: { height: 80 } },

  policy_link: {
    paddingRight: 40,
    color: theme.palette.common.aitiBlack,
    fontFamily: 'PT Sans',
    fontSize: '0.9rem',
    fontWeight: 600,
    //textDecoration: 'none',
    '&:hover': {
      color: '#2b04d6',
      textDecoration: 'underline #2b04d6',
      textDecorationThickness: 2,
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
    },
  },
  socialMedia: {
    height: 60,
  },
  socialFacebook: {
    marginRight: 10,
    paddingTop: 0,
    '& circle': {
      fill: '#4a4a4a',
      marginTop: 20,
      '&:hover': {
        fill: '#2b04d6',
        marginBottom: 20,
      },
    },
    '&:hover': {
      paddingTop: 40,
    },
  },
  socialTwitter: {
    marginRight: 10,
    paddingTop: 0,
    '& circle': {
      fill: '#4a4a4a',
      marginTop: 20,
      '&:hover': {
        fill: '#2b04d6',
        marginTop: 20,
      },
    },
    '&:hover': {
      paddingTop: 40,
    },
  },
  socialLinkedin: {
    marginRight: 10,
    paddingTop: 0,
    '& circle': {
      fill: '#4a4a4a',
      marginTop: 20,
      '&:hover': {
        fill: '#2b04d6',
        marginTop: 20,
      },
    },
    '&:hover': {
      paddingTop: 40,
    },
  },
  gridItemBottom: {
    width: '100%',
    padding: '3em',
    paddingTop: '1em',
    [theme.breakpoints.down('xs')]: {
      padding: '2em',
    },
  },
  link_support: {
    marginTop: 0,
    //paddingLeft: '3em',
    color: theme.palette.common.aitiBlack,
    fontFamily: 'PT Sans',
    fontSize: '0.9rem',
    fontWeight: 600,
  },
  link_dvv: {
    marginTop: 0,
    //paddingLeft: '3em',
    color: theme.palette.common.aitiBlack,
    fontFamily: 'Roboto',
    fontSize: '0.9rem',
    fontWeight: '400',
  },
  link_aldi: {
    marginTop: 0,
    //paddingLeft: '3em',
    color: theme.palette.common.aitiBlack,
    fontFamily: 'Roboto',
    fontSize: '0.9rem',
    fontWeight: '400',
  },
}))

const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <Grid container direction='row'>
        <Grid container justify='flex-start' className={classes.mainContainter}>
          <Grid item className={classes.gridItem}>
            <Grid containter>
              <Grid item className={classes.link}>
                <p className={classes.title}>AITI</p>
                <p className={classes.subtitle}>
                  Napredni institut za inovacije i tehnologije
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Grid containter>
              <Grid item className={classes.contactTitle}>
                Kontaktirajte nas
              </Grid>
              <Grid item className={classes.link}>
                <a
                  href='mailto:aiti@aiti.ba'
                  target='_top'
                  className={classes.link}
                >
                  aiti@aiti.ba
                </a>
              </Grid>
              <Grid item className={classes.link}>
                <a
                  href='tel:38738227850'
                  target='_top'
                  className={classes.link}
                >
                  +387-38-227-850
                </a>
              </Grid>
              <Grid item className={classes.link}>
                <a
                  href='tel:38738228594'
                  target='_top'
                  className={classes.link}
                >
                  +387-38-228-594
                </a>
              </Grid>
              <Grid containter>
                <Grid item className={classes.titleAddress}>
                  Adresa
                </Grid>
                <p className={classes.paragraf}>Panorama bb</p>
                <p className={classes.paragraf}>73000 Gorazde</p>
                <p className={classes.paragraf}>Bosna i Hercegovina</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction='column'
          justify='flex-start'
          className={classes.mainContainter}
        >
          <Grid item className={classes.gridItemRight}>
            <Grid direction='row' className={classes.policy}>
              <Grid
                item
                component={Link}
                to='/privacy'
                className={classes.policy_link}
              >
                Politika privatnosti
              </Grid>
              <Grid
                item
                component={Link}
                to='/cookies'
                className={classes.policy_link}
              >
                Politika kolacica
              </Grid>
              <Grid
                item
                component={Link}
                to='/uslovi-koristenja'
                className={classes.policy_link}
              >
                Uslovi koristenja
              </Grid>
            </Grid>
            <Grid className={classes.socialMedia}>
              <FacebookShareButton
                url={'http://www.aiti.ba'}
                quote={'Napredni institut za tehnologije i inovacije'}
                hashtag='#AITI_Digital'
                className={classes.socialFacebook}
              >
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={'http://www.aiti.ba'}
                quote={'Napredni institut za tehnologije i inovacije'}
                hashtag='#AITI_Digital'
                className={classes.socialTwitter}
              >
                <TwitterIcon size={32} round={true} fill={'#000'} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={'http://www.aiti.ba'}
                quote={'Napredni institut za tehnologije i inovacije'}
                hashtag='#AITI_Digital'
                className={classes.socialLinkedin}
                t
              >
                <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>
            </Grid>
            <Divider />
          </Grid>

          <Grid className={classes.gridItemBottom}>
            <p item className={classes.link_support}>
              Nasa podrska
            </p>
            <p item className={classes.link_dvv}>
              AITI Internet platforma je kreirana i održavana uz finansijsku
              podršku DVV International Ured za Bosnu i Hercegovinu. Njen
              sadržaj je isključivo odgovornost AITI.
            </p>
            <p item className={classes.link_aldi}>
              Napredni institut za tehnologije i inovacije je samostalno pravno
              lice osnovano od strane Udruzenja ALDI Gorazde.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  )
}

export default Footer
