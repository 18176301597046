export const VIJEST_LIST_REQUEST = 'VIJEST_LIST_REQUEST'
export const VIJEST_LIST_SUCESS = 'VIJEST_LIST_SUCESS'
export const VIJEST_LIST_FAIL = 'VIJEST_LIST_FAIL'

export const VIJEST_DETAILS_REQUEST = 'VIJEST_DETAILS_REQUEST'
export const VIJEST_DETAILS_SUCESS = 'VIJEST_DETAILS_SUCESS'
export const VIJEST_DETAILS_FAIL = 'VIJEST_DETAILS_FAIL'

export const VIJEST_DELETE_REQUEST = 'VIJEST_DELETE_REQUEST'
export const VIJEST_DELETE_SUCCESS = 'VIJEST_DELETE_SUCCESS'
export const VIJEST_DELETE_FAIL = 'VIJEST_DELETE_FAIL'

export const VIJEST_CREATE_REQUEST = 'VIJEST_CREATE_REQUEST'
export const VIJEST_CREATE_SUCCESS = 'VIJEST_CREATE_SUCCESS'
export const VIJEST_CREATE_FAIL = 'VIJEST_CREATE_FAIL'
export const VIJEST_CREATE_RESET = 'VIJEST_CREATE_RESET'

export const VIJEST_UPDATE_REQUEST = 'VIJEST_UPDATE_REQUEST'
export const VIJEST_UPDATE_SUCCESS = 'VIJEST_UPDATE_SUCCESS'
export const VIJEST_UPDATE_FAIL = 'VIJEST_UPDATE_FAIL'
export const VIJEST_UPDATE_RESET = 'VIJEST_UPDATE_RESET'

export const VIJEST_ONE_REQUEST = 'VIJEST_ONE_REQUEST'
export const VIJEST_ONE_SUCESS = 'VIJEST_ONE_SUCESS'
export const VIJEST_ONE_FAIL = 'VIJEST_ONE_FAIL'
