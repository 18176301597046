import React from 'react'
import { Link } from 'react-router-dom'
//CSS
import {
  makeStyles,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  main: { width: 375, height: 550 },
  media: { height: 250 },
  mainTitle: { textTransform: 'none' },
  mainContent: {
    width: '100%',
  },
}))

const CareerList = ({ career }) => {
  const classes = useStyles()
  return (
    <Card className={classes.main}>
      <CardActionArea>
        <Link to={`/career/${career._id}`}>
          <CardMedia
            component='img'
            variant='top'
            className={classes.media}
            image={career.image}
          />
        </Link>

        <CardContent className={classes.mainContent}>
          <Link to={`/career/${career._id}`}>
            <Typography>{career.status}</Typography>
            <Typography
              gutterBottom
              variant='h5'
              component='h2'
              className={classes.mainTitle}
            >
              {career.title}
            </Typography>
          </Link>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default CareerList
