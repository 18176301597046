import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listVijestDetails, updateVijest } from '../../actions/vijestActions'
import { VIJEST_UPDATE_RESET } from '../../constants/vijestConstants'
import { Editor } from '@tinymce/tinymce-react'
//Components
import Message from '../../components/UI/Message'
import Loader from '../../components/UI/Loader'
import FormContainer from '../../components/OBUKE/FormContainer'
//Styles
import { Form } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import theme from '../../components/UI/Theme'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormControl,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    ...theme.paperMain,
    height: 'auto',
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 0,
    },
  },
  paper_bottom: {
    ...theme.paperMain,
    height: 'auto',
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 0,
    },
  },
  gridCardLeft: {
    width: '60%',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  gridCardRight: {
    width: '37%',
    marginLeft: '3%',
    padding: 20,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 10,
    },
  },
  box: {
    display: 'flex',
    verticalAlign: 'center',
    height: 50,
    marginTop: 80,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginBottom: 60,
    },
  },
  coverArrow: { height: 50, verticalAlign: 'center' },
  coverArrow1: { height: 50, paddingTop: 15 },

  coverBox: {
    height: 150,
    [theme.breakpoints.down('xs')]: {
      height: 250,
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    marginTop: 20,
    letterSpacing: '2px',
    fontSize: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 20,
      fontSize: '2rem',
    },
  },
  mainSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 0,
    marginBottom: 20,
    fontFamily: 'PT Sans',
    fontSize: '1.4rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      height: '2rem',
      paddingBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      height: 50,
      margin: 0,
      paddingLeft: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },

  prijavaButton: {
    ...theme.prijavaButton,
    marginTop: 50,
    marginBottom: 50,
  },
  boxForm: {
    marginTop: 20,
  },
  mainFormGroup: {
    marginTop: 20,
    marginBottom: 20,
  },
})

const VijestEditScreen = ({ match, history }) => {
  const classes = useStyles()
  const vijestId = match.params.id

  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [image, setImage] = useState('')
  const [description, setDescription] = useState('')
  const [newsType, setNewsType] = useState('')
  const [author, setAuthor] = useState('')
  const [category, setCategory] = useState('')
  const [date, setDate] = useState('')
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  const vijestDetails = useSelector((state) => state.vijestDetails)
  const { loading, error, vijest } = vijestDetails

  const vijestUpdate = useSelector((state) => state.vijestUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = vijestUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: VIJEST_UPDATE_RESET })
      history.push('/admin/vijestlist')
    } else {
      if (!vijest.title || vijest._id !== vijestId) {
        dispatch(listVijestDetails(vijestId))
      } else {
        setTitle(vijest.title)
        setSubTitle(vijest.subTitle)
        setImage(vijest.image)
        setDescription(vijest.description)
        setNewsType(vijest.newsType)
        setAuthor(vijest.author)
        setCategory(vijest.category)
        setDate(vijest.date)
      }
    }
  }, [dispatch, history, vijestId, vijest, successUpdate])
  //tinymce
  const handleDescription = (content, e) => {
    setDescription(content)
  }
  const [value, setValue] = useState(description ?? '')
  useEffect(() => setValue(description ?? ''), [description])

  const handleChangeCategory = (e) => {
    setCategory(e.target.value)
  }
  const handleChangeNewsType = (e) => {
    setNewsType(e.target.value)
  }
  //tinymce end
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateVijest({
        _id: vijestId,
        title,
        subTitle,
        image,
        description,
        newsType,
        author,
        category,
        date,
      })
    )
  }

  return (
    <div className={classes.main}>
      <Paper className={classes.paper_top}>
        <Box>
          <Link to='/admin/vijestlist'>
            <div className={classes.box}>
              <Box component='div' display='inline'>
                <KeyboardArrowLeftIcon className={classes.coverArrow} />
              </Box>
              <Box
                component='div'
                display='inline'
                className={classes.coverArrow1}
              >
                NAZAD NA SVE VIJESTI
              </Box>
            </div>
          </Link>
        </Box>
        <Grid container justify='space-between' className={classes.coverBox}>
          <Box className={classes.titleBox}>
            <h3 className={classes.mainTitle}>
              Kreiranje nove ili uredjivanje postojece vijesti
            </h3>
            <p className={classes.mainSubTitle}>
              Editovanje selektovane vijesti
            </p>
          </Box>
        </Grid>
        <Divider />
      </Paper>
      <Paper className={classes.paper_bottom}>
        <FormContainer>
          <h5>VIJEST:</h5>
          <h1>{title}</h1>
          <Divider />
          {loadingUpdate && <Loader />}
          {errorUpdate && <Message variant='denger'>{errorUpdate}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Form onSubmit={submitHandler}>
              <FormGroup controlId='name' className={classes.mainFormGroup}>
                <FormLabel>Naslov vijesti</FormLabel>
                <FormControl
                  type='text'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                >
                  <TextField
                    id='outlined-basic'
                    label='Naslov vijesti'
                    variant='outlined'
                    value={title}
                  />
                </FormControl>
              </FormGroup>
              <Divider />
              <FormGroup controlId='subTitle' className={classes.mainFormGroup}>
                <FormLabel>Podnaslov</FormLabel>
                <FormControl
                  type='text'
                  placeholder='Podnaslov za vijest'
                  value={subTitle}
                  onChange={(e) => setSubTitle(e.target.value)}
                >
                  <TextField
                    id='outlined-basic'
                    label='Podnaslov za vijest'
                    variant='outlined'
                    value={subTitle}
                  />
                </FormControl>
              </FormGroup>
              <Divider />
              <Form.Group controlId='image'>
                <Form.Label>Fotografija</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Slika za vijest'
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                ></Form.Control>
                <Form.File
                  id='image-file'
                  label='Izaberi datoteku'
                  type='file'
                  custum
                  onChange={uploadFileHandler}
                ></Form.File>
                {uploading && <Loader />}
              </Form.Group>
              <Divider />
              <FormGroup
                controlId='description'
                className={classes.mainFormGroup}
              >
                <FormLabel>Sadrzaj vijesti</FormLabel>
                <FormControl
                  type='text'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                >
                  <Editor
                    value={description}
                    init={{
                      height: 800,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                    onEditorChange={handleDescription}
                  />
                </FormControl>
              </FormGroup>
              <Divider />
              <FormGroup controlId='newsType' className={classes.mainFormGroup}>
                <FormLabel as='legend'>Odaberi tip vijesti</FormLabel>
                <RadioGroup value={newsType} onChange={handleChangeNewsType}>
                  <FormControlLabel
                    control={<Radio />}
                    label='Vijest u fokusu'
                    value='Vijest u fokusu'
                    onChange={(e) => setNewsType(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Generalna vijest'
                    value='generalNews'
                    onChange={(e) => setNewsType(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Oglas'
                    value='Oglas'
                    onChange={(e) => setNewsType(e.target.value)}
                  ></FormControlLabel>
                </RadioGroup>
              </FormGroup>
              <Divider />
              <FormGroup controlId='category' className={classes.mainFormGroup}>
                <FormLabel as='legend'>Odaberi kategoriju vijesti</FormLabel>
                <RadioGroup value={category} onChange={handleChangeCategory}>
                  <FormControlLabel
                    control={<Radio />}
                    label='Opsta vijest iz AITI'
                    value='generalNews'
                    onChange={(e) => setCategory(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Vijest povezana sa obukama'
                    value='educationNews'
                    onChange={(e) => setCategory(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Vijest povezana sa tehnologijama'
                    value='techNews'
                    onChange={(e) => setCategory(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Vijest povezana sa inovacijama'
                    value='innoNews'
                    onChange={(e) => setCategory(e.target.value)}
                  ></FormControlLabel>
                </RadioGroup>
              </FormGroup>
              <Divider />
              <FormGroup controlId='author' className={classes.mainFormGroup}>
                <FormLabel>Autor vijest</FormLabel>
                <FormControl
                  type='text'
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                >
                  <TextField
                    id='outlined-basic'
                    label='Autor'
                    variant='outlined'
                    value={author}
                  />
                </FormControl>
              </FormGroup>
              <Divider />
              <FormGroup controlId='date' className={classes.mainFormGroup}>
                <FormLabel>Datum objavljivanja</FormLabel>
                <FormControl
                  type='text'
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                >
                  <TextField
                    id='outlined-basic'
                    label='Broj mjesta'
                    variant='outlined'
                    value={date}
                  />
                </FormControl>
              </FormGroup>
              <Divider />
              <Button
                type='submit'
                variant='primary'
                className={classes.prijavaButton}
              >
                Azuriraj
              </Button>
            </Form>
          )}
        </FormContainer>
      </Paper>
    </div>
  )
}

export default VijestEditScreen
