import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listOneKurs } from '../../actions/kursActions'
//Components
import Loader from '../UI/Loader'
import Message from '../UI/Message'
import ButtonArrow from '../UI/ButtonArrow'
//CSS
import { makeStyles } from '@material-ui/core/styles'
import theme from '../UI/Theme'
import { Card, CardMedia, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
  },
  card: {
    margin: 0,
    padding: 0,
    minWidth: '100%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 600,
    },
  },
  main_grid: {
    width: '100%',
    margin: 0,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  gridTop: {
    minWidth: '50%',
    margin: 0,
    backgroundColor: theme.palette.common.aitiWhite,
    [theme.breakpoints.down('lg')]: { width: '50%' },
    [theme.breakpoints.down('md')]: { width: '50%' },
    [theme.breakpoints.down('sm')]: { width: '100%' },
  },
  media: {
    width: '100%',
    height: 600,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 300,
    },
  },
  gridBottom: {
    minWidth: '50%',
    margin: 0,
    backgroundColor: theme.palette.common.aitiWhite,
    [theme.breakpoints.down('lg')]: { width: '50%' },
    [theme.breakpoints.down('md')]: {
      width: '50%',
      paddingLeft: 60,
      paddingRight: 20,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  titleBox: {
    height: 350,
    marginLeft: 200,
    marginRight: 50,
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: 300,
      marginLeft: 50,
      marginRight: 20,
    },
    [theme.breakpoints.down('sm')]: {
      height: 200,
      marginLeft: 20,
    },
  },
  title: {
    height: 150,
    paddingTop: 80,
    textAlign: 'left',
    fontFamily: 'Playfair Display',
    color: '#444444',
    fontSize: '3.5rem',
    fontWeight: '600',
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      paddingTop: '2.5rem',
      paddingLeft: 0,
      fontSize: '2rem',
    },
  },
  sub_title: {
    marginRight: 0,
    paddingTop: 10,
    height: '3rem',
    textAlign: 'left',
    color: '#444444',
    fontSize: '1.5rem',
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
      height: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      height: 120,
      paddingTop: '1rem',
      paddingLeft: 0,
      fontSize: '1rem',
    },
  },
  grid_link: {
    height: 50,
    marginLeft: 200,
    marginRight: 50,
    [theme.breakpoints.down('md')]: { marginLeft: 50 },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      paddingLeft: 20,
    },
  },
  main_grid_link: {
    height: '1.1rem',
    textAlign: 'left',
    color: '#545555',
    fontSize: '1.2rem',
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      paddingLeft: 0,
      fontSize: '1rem',
    },
  },
}))

const KursOne = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const kursOne = useSelector((state) => state.kursOne)
  const { loading, error, kursevi } = kursOne

  useEffect(() => {
    dispatch(listOneKurs())
  }, [dispatch])

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <div className={classes.main}>
      {kursevi.map((kurs) => (
        <Card className={classes.card} key={kurs._id}>
          <Grid container direction='row-reverse' className={classes.main_grid}>
            <Grid sm item className={classes.gridTop}>
              <CardMedia
                component='img'
                className={classes.media}
                image={kurs.image}
              />
            </Grid>
            <Grid sm item className={classes.gridBottom}>
              <div className={classes.titleBox}>
                <h3 className={classes.title}>{kurs.name}</h3>
                <p className={classes.sub_title}>{kurs.subTitle}</p>
              </div>
              <Grid sm item className={classes.grid_link}>
                <Link to={`/kurs/${kurs._id}`}>
                  <p className={classes.main_grid_link}>
                    <span style={{ marginRight: 10 }}>
                      Napravi prvi korak: Prijavi se
                    </span>
                    <ButtonArrow
                      className={classes.coverArrow}
                      width={35}
                      height={25}
                      fill={theme.palette.common.aitiBlue}
                    />
                  </p>
                </Link>
              </Grid>
              <Grid sm item className={classes.grid_link}>
                <Link to='/kursevi'>
                  <p className={classes.main_grid_link}>
                    <span style={{ marginRight: 10 }}>
                      Istrazi sve nase kurseve
                    </span>
                    <ButtonArrow
                      className={classes.coverArrow}
                      width={35}
                      height={25}
                      fill={theme.palette.common.aitiBlue}
                    />
                  </p>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      ))}
    </div>
  )
}

export default KursOne
