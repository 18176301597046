import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { savePaymentMethod } from '../../actions/cartActions'
//Components
import FormContainer from '../../components/OBUKE/FormContainer'
import CheckoutSteps from '../../components/OBUKE/CheckoutSteps'
//Styles
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import theme from '../../components/UI/Theme'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    ...theme.paperMain,
    height: 'auto',
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 0,
    },
  },
  gridCardLeft: {
    width: '60%',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  gridCardRight: {
    width: '37%',
    marginLeft: '3%',
    padding: 20,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 10,
    },
  },
  box: {
    display: 'flex',
    verticalAlign: 'center',
    height: 50,
    marginTop: 80,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginBottom: 60,
    },
  },
  coverArrow: { height: 50, verticalAlign: 'center' },
  coverArrow1: { height: 50, paddingTop: 15 },

  coverBox: {
    height: 200,
    [theme.breakpoints.down('xs')]: {
      height: 250,
    },
  },

  headerSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 0,
    marginBottom: 0,
    fontFamily: 'PT Sans',
    fontSize: '1.6rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      height: '2rem',
      paddingBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      height: 50,
      margin: 0,
      paddingLeft: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    marginTop: 20,
    letterSpacing: '2px',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 20,
      fontSize: '2.5rem',
    },
  },
  prijavaButton: {
    ...theme.prijavaButton,
    marginTop: 50,
    marginBottom: 50,
  },
  boxForm: {
    marginTop: 20,
  },
})

const PaymentScreen = ({ history }) => {
  const classes = useStyles()
  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  if (!shippingAddress) {
    history.push('/shipping')
  }

  const [paymentMethod, setPaymentMethod] = useState('licno')

  const dispatch = useDispatch()
  const handleChange = (e) => {
    setPaymentMethod(e.target.value)
  }
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(savePaymentMethod(paymentMethod))
    history.push('/placeorder')
  }

  return (
    <div className={classes.main}>
      <Paper className={classes.paper_top}>
        <Box>
          <Link to='/kursevi'>
            <div className={classes.box}>
              <Box component='div' display='inline'>
                <KeyboardArrowLeftIcon className={classes.coverArrow} />
              </Box>
              <Box
                component='div'
                display='inline'
                className={classes.coverArrow1}
              >
                SVI KURSEVI
              </Box>
            </div>
          </Link>
        </Box>
        <Grid
          container
          direction='column'
          justify='space-between'
          className={classes.coverBox}
        >
          <Box>
            <h3 className={classes.mainTitle}>Placanje</h3>
            <p className={classes.headerSubTitle}>
              Molimo popunite podatke ispod i odaberite nacin placanja odabrane
              edukacije
            </p>
          </Box>
          <Divider />
        </Grid>
      </Paper>
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        className={classes.paper_top}
      >
        <Grid item className={classes.gridCardLeft}>
          <FormContainer>
            <CheckoutSteps step1 step2 step3 />
            <h1>Izaberite metod placanja</h1>
            <form onSubmit={submitHandler}>
              <FormGroup>
                <FormLabel as='legend'>Odaberi metod placanja</FormLabel>

                <RadioGroup value={paymentMethod} onChange={handleChange}>
                  <FormControlLabel
                    control={<Radio />}
                    label='Orgnizacija (putem bankovnog racuna)'
                    id='organizacija'
                    name='organizacijaMethod'
                    value='organizacija'
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Licno'
                    id='Licno'
                    name='licnoMethod'
                    value='licno'
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Grant/Stipendija'
                    id='Grant'
                    name='grantMethod'
                    value='Grant'
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='PayPal ili kreditna kartica (trenutno onemoguceno)'
                    id='PayPal'
                    disabled
                    name='paymentMethod'
                    value='disabled'
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  ></FormControlLabel>
                </RadioGroup>
              </FormGroup>

              <Button type='submit' className={classes.prijavaButton}>
                Nastavi
              </Button>
            </form>
          </FormContainer>
        </Grid>
        <Grid item className={classes.gridCardRight}>
          <Grid container>
            <Grid item>
              <Box>
                <p>POJASNJENJE</p>
              </Box>
              <Divider />
              <Grid item>
                <Box className={classes.boxForm}>
                  <h6>Placanje putem organizacije</h6>
                  <p>
                    Ovu opciju mozete izabrati ukoliko vasu edukaciju placa vase
                    preduzece ili druga organizacija koja placa troskove vase
                    edukacije.
                  </p>
                </Box>
                <Divider />
                <Box className={classes.boxForm}>
                  <h6>Licno</h6>
                  <p>
                    Ovu opciju mozete izabrati ukoliko vasu edukaciju vi licno
                    placate troskove vase edukacije.
                  </p>
                </Box>
                <Box className={classes.boxForm}>
                  <h6>Grant/Stipencija</h6>
                  <p>
                    Ovu opciju mozete izabrati u slucaju kada je naznaceno da su
                    za pohadjanje programa obrazovaja obezbjedjeni
                    grantovni/placene skolarine za polaznike.
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default PaymentScreen
