import React from 'react'
import { useSpring, animated } from 'react-spring'
import { Link } from 'react-router-dom'
//Components
import Meta from '../../components/Meta'
import imageOne from '../../images/uslugeNapredne.jpg'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import VideoModal from '../../components/UI/VideoModal'
//Styles
import { makeStyles } from '@material-ui/core/styles'
import { Box, Card, Grid, CardMedia } from '@material-ui/core'
//Images
import coverImage from '../../images/usluge.jpg'

const linkBox = {
  bgcolor: 'transparent',
  m: 1,
  margin: 'auto',
  style: { width: '300px', height: '60px' },
  borderColor: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    height: '80vh',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
    },
  },
  media: {
    height: '80vh',
    alignItem: 'bottom',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
      width: 'auto',
    },
  },
  coverBox: {
    width: 500,
    marginTop: -480,
    marginLeft: 140,
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      marginTop: -450,
      marginLeft: 50,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: -280,
      marginLeft: 0,
      backgroundColor: '#000',
      opacity: 0.8,
    },
  },
  coverTitle: {
    fontFamily: 'Playfair Display',
    color: '#fff',
    fontSize: '2.8rem',
    fontWeight: '600',
    letterSpacing: 8,
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
      fontSize: '2.0rem',
      fontWeight: '500',
      marginBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.0rem',
      fontWeight: '400',
      marginTop: 20,
      marginBottom: 10,
      paddingLeft: 20,
      zIndex: '1250 !important',
    },
  },
  coverSubTitle: {
    color: '#fff',
    marginTop: 10,
    marginBottom: 10,
    fontWeight: '300',
    fontSize: '1.1rem',
    lineHeight: '1.5',
    alignItem: 'center',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 0,
      paddingLeft: 20,
      zIndex: '1250 !important',
    },
  },
  linkBox: {
    display: 'flex',
    verticalAlign: 'center',
    height: 50,
    marginTop: 20,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 80,
      paddingTop: 40,
      paddingLeft: 20,
    },
  },
  coverArrow1: {
    height: 30,
    paddingRight: 10,
    paddingTop: 10,
    color: '#fff',
    fontSize: '1.1rem',
    verticalAlign: 'center',
    '&:hover': {
      paddingRight: 15,
      transition: 1500,
      textDecoration: 'none',
    },
  },
  coverArrow: {
    height: 50,
    marginRight: '1rem',
    marginTop: 0,
    verticalAlign: 'center',
  },
  //PRVI SLAJD
  cardBody: {
    height: 600,
    backgroundColor: '#b0afad',
    color: '#fff',
    verticalAlign: 'center',
    fontSize: '1.1rem',
    marginLeft: 0,
    paddingLeft: 0,
    '&:hover': {
      color: '#d5cfd5',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      backgroundColor: '#4C1450',
      opacity: '0.8',
      fontSize: '1rem',
    },
  },
  cardBodyBox: {
    position: 'center',
    width: 600,
    height: 300,
    margin: 'auto',
    padding: 0,
    verticalAlign: 'bottom',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 80,
    },
  },
  cardBodyBoxMain: {
    ...theme.mainTitle,
    marginTop: 100,
    textAlign: 'center',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      marginTop: 60,
      fontSize: '2rem',
      fontWeight: 400,
      letterSpacing: 5,
    },
  },
  cardBodyBoxTitle: {
    paddingBottome: 20,
    color: '#fdba45',
    [theme.breakpoints.down('sm')]: {
      padding: 20,
      fontSize: '1rem',
      lineHeight: 1.6,
      fontWeight: 400,
    },
  },
  cardBodyBoxSubTitle: {
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
      padding: 20,
      fontSize: '1rem',
      lineHeight: 1.6,
    },
  },
  // DRUGI SLAJD
  main_grid_item: {
    height: 600,
    [theme.breakpoints.down('xs')]: {
      height: 300,
      width: 'auto',
    },
  },
  media_II: {
    height: '80vh',
    alignItem: 'bottom',
    [theme.breakpoints.down('sm')]: {
      height: 300,
      width: 'auto',
    },
  },
  main_grid_item_II: {
    height: 600,
    [theme.breakpoints.down('xs')]: {
      height: 500,
      width: 'auto',
    },
  },
  secondGrid: { height: '100%', backgroundColor: '#2c3d46' },
  secondBox: {
    height: 'auto',
    width: 600,
    margin: 'auto',
    paddingTop: 100,
    verticalAlign: 'center',
    alignItem: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      padding: 20,
      paddingTop: 60,
    },
  },
  secondTitle: {
    ...theme.mainTitle,
    textAlign: 'center',
    color: '#fff',
    '& span': {
      color: '#209fbb',
    },
    [theme.breakpoints.down('sm')]: { fontSize: '2.4rem' },
  },
  secondSubTitle: {
    ...theme.mainSubTitle,
    textAlign: 'center',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
      padding: 20,
      fontSize: '1rem',
      lineHeight: 1.6,
      fontWeight: 400,
    },
  },
  videoBoxMain: {
    paddingTop: 15,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#000',
    },
    [theme.breakpoints.down('sm')]: { marginTop: 40 },
  },
  linkBoxText: {
    height: 20,
    margin: 0,
    padding: 0,
    paddingRight: 10,
    paddingTop: 1,
    fontSize: '1rem',
    fontWeight: '1.2rem',
    color: '#fff',
  },
}))

const UslugeScreen = ({ match }) => {
  const classes = useStyles()
  const springAnimate = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0.5 },
    //delay: 500,
  })
  const loop = useSpring({
    loop: { reverse: false },
    from: { x: 0 },
    to: { x: 10 },
  })

  return (
    <>
      <Meta />
      <Card className={classes.root}>
        <CardMedia className={classes.media} image={coverImage} title='AITI' />
        <Grid container direction='column' className={classes.coverBox}>
          <animated.div style={springAnimate}>
            <Grid sm item>
              <animated.div className={classes.coverTitle}>
                NAS PRISTUP
              </animated.div>
              <p className={classes.coverSubTitle}>
                Mi vam mozemo pomoci da usvojite dobre prakse koje ce vam
                olaksati kreiranje promjena u novom dobu
              </p>
            </Grid>
            <Grid sm item>
              <Box>
                <div className={classes.linkBox}>
                  <Box
                    component={Link}
                    to='/'
                    display='inline'
                    className={classes.coverArrow1}
                  >
                    Saznaj vise
                  </Box>
                  <Box component='div' display='inline'>
                    <ArrowForwardIcon className={classes.coverArrow} />
                  </Box>
                </div>
              </Box>
            </Grid>
          </animated.div>
        </Grid>
      </Card>
      <Card className={classes.cardBody}>
        <Box className={classes.cardBodyBox}>
          <h1 className={classes.cardBodyBoxMain}>AITI:DIGITAL</h1>
          <h3 className={classes.cardBodyBoxTitle}>
            Izvrsni u podrsci kompleksnim promjenama
          </h3>
          <p className={classes.cardBodyBoxSubTitle}>
            Mi vam mozemo pruziti podrsku u kreiranju vase vizije, razvoju
            vjestina, planiranju resursa i dizajniranju plana usmjerenog na
            povecanje vasih potenijala
          </p>
        </Box>
        <VideoModal />
      </Card>
      <Card>
        <Grid container direction='row'>
          <Grid sm item className={classes.main_grid_item}>
            <CardMedia
              component='img'
              className={classes.media_II}
              image={imageOne}
            />
          </Grid>
          <Grid sm item className={classes.main_grid_item_II}>
            <Box className={classes.secondGrid}>
              <Box className={classes.secondBox}>
                <h3 className={classes.secondTitle}>
                  Fokus na <span>tehnologiji</span>
                </h3>
                <p className={classes.secondSubTitle}>
                  Predani podrsci usvajanju naprednih tehnologija koje mogu
                  doprinijeti postizanju punog potecijala koje svako od nas ima.
                </p>
                <Link to='/usluge/pregled'>
                  <Box
                    display='flex'
                    justifyContent='center'
                    border={1}
                    cursor='pointer'
                    className={classes.videoBoxMain}
                    {...linkBox}
                    alignItem='center'
                  >
                    <p className={classes.linkBoxText}>Katalog usluga</p>
                    <span>
                      <ArrowForwardIosIcon style={{ fill: '#ffffff' }} />
                    </span>
                  </Box>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>

      <Card className={classes.cardBody}>
        <Box className={classes.cardBodyBox}>
          <h1 className={classes.cardBodyBoxMain}>AITI:EDUCATION</h1>
          <h3 className={classes.cardBodyBoxTitle}>
            Programi obrazovanja vrhunske kvalitete
          </h3>
          <p className={classes.cardBodyBoxSubTitle}>
            Mi smo specijalizirani za razvoj vjestina radne snage. Digitalne,
            socijalne i emocionalne vjestine su u potraznji i AITI ima recept za
            vasu uspjesnu transformaciju vjestina
          </p>
        </Box>
        <Link to='/obuke'>
          <Box
            display='flex'
            justifyContent='center'
            border={1}
            cursor='pointer'
            className={classes.videoBoxMain}
            {...linkBox}
            alignItem='center'
          >
            <p className={classes.linkBoxText}>Visee o obrazovanju</p>
            <span>
              <ArrowForwardIosIcon style={{ fill: '#ffffff' }} />
            </span>
          </Box>
        </Link>
      </Card>
    </>
  )
}

export default UslugeScreen
