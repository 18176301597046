import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listOrders } from '../../actions/orderActions'
//Komponente
import Message from '../../components/UI/Message'
import Loader from '../../components/UI/Loader'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import theme from '../../components/UI/Theme'
import {
  makeStyles,
  Paper,
  Box,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    padding: theme.spacing(1.4),
    margin: 'auto',
    width: 1350,
    backgroundColor: theme.palette.common.aitiGrey,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paperBottom: {
    padding: theme.spacing(1.4),
    margin: 'auto',
    width: 1350,
    backgroundColor: theme.palette.common.aitiGrey,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '400px',
      padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  box: {
    display: 'flex',
    verticalAlign: 'center',
    height: 50,
    marginTop: 80,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginBottom: 60,
    },
  },
  coverArrow: { height: 50, verticalAlign: 'center' },
  coverArrow1: { height: 50, paddingTop: 15 },

  coverBox: {
    height: 'auto',
    width: 'auto',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      height: 420,
      width: '370px',
    },
  },
  titleBox: {
    width: '900px',
    height: 'auto',

    paddingTop: 80,
    marginBottom: 80,
    [theme.breakpoints.down('md')]: {
      height: '400px',
      width: '80%',
      paddingTop: 80,
    },
    [theme.breakpoints.down('sm')]: {
      height: '500px',
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      height: 320,
      width: '100%',
      padding: 20,
      paddingTop: 80,
      marginBottom: 0,
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    height: 'auto',
    marginTop: 20,
    fontSize: '2.5rem',
    letterSpacing: '0.1rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem',
      height: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '1.8rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 0,
      fontSize: '1.8rem',
    },
  },
  mainSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 40,
    fontFamily: 'PT Sans',
    fontSize: '1.8rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
      height: '2rem',
      paddingBottom: 50,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      height: '10rem',
      margin: 0,
      paddingRight: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
  root: {
    width: 350,
    marginBottom: 40,
    backgroundColor: theme.palette.common.aitiGrey,
    transition: 'transform 0.25s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: '5%',
      backgroundColor: theme.palette.common.aitiGrey,
    },
  },
  media: {
    height: 240,
    backgroundColor: theme.palette.common.aitiGrey,
  },
  cardHovered: {
    transform: 'scale3d(1.01, 1.01, 1)',
    backgroundColor: theme.palette.common.aitiWhite,
  },
  actions: {
    paddingLeft: 16,
    paddingBottom: 16,
  },
  button: {
    ...theme.button,
  },
  table: {
    minWidth: 650,
  },
})

const OrderListScreen = ({ history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const orderList = useSelector((state) => state.orderList)
  const { loading, error, orders } = orderList

  const orderAdminDetails = useSelector((state) => state.orderAdminDetails)
  const {
    loading: loadingAdmin,
    success: successAdmin,
    order: orderAdmin,
  } = orderAdminDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, orderAdmin, userInfo])

  return (
    <div className={classes.main}>
      <Paper className={classes.paper_top}>
        <Box>
          <Link to='/administracija'>
            <div className={classes.box}>
              <Box component='div' display='inline'>
                <KeyboardArrowLeftIcon className={classes.coverArrow} />
              </Box>
              <Box
                component='div'
                display='inline'
                className={classes.coverArrow1}
              >
                ADMINISTRACIJA
              </Box>
            </div>
          </Link>
        </Box>
        <Grid container justify='space-between' className={classes.coverBox}>
          <Box className={classes.titleBox}>
            <h3 className={classes.mainTitle}>
              ADMINISTRACIJA PRIJAVA NA OBUKE
            </h3>
            <p className={classes.mainSubTitle}>
              {userInfo.name} vi ste ovlasteni da administrirate podatke o o
              prijavama na obuke.
            </p>
          </Box>
        </Grid>
      </Paper>
      <Paper className={classes.paperBottom}>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              stickyHeader
              aria-label='sticky table'
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align='left'>Korisnik</TableCell>
                  <TableCell align='left'>Naziv kursa</TableCell>
                  <TableCell align='left'>Datum prijave</TableCell>
                  <TableCell align='left'>Ukupna cijena</TableCell>
                  <TableCell align='left'>Placeno</TableCell>
                  <TableCell align='left'>Isporuceno</TableCell>
                  <TableCell align='left'>Detalji</TableCell>
                  <TableCell align='left'>Administracija</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow key={order._id}>
                    <TableCell
                      style={{ width: 100 }}
                      component='th'
                      scope='row'
                    >
                      {order._id}
                    </TableCell>
                    <TableCell align='left'>
                      {order.user && order.user.name}
                    </TableCell>
                    <TableCell align='left'></TableCell>
                    <TableCell align='right'>
                      {order.createdAt.substring(0, 10)}
                    </TableCell>
                    <TableCell align='left'>{order.isPaid}</TableCell>
                    <TableCell align='left'>{order.isDelivered}</TableCell>
                    <TableCell align='right'>
                      <Button component={Link} to={`/order/${order._id}`}>
                        Detalji
                      </Button>
                    </TableCell>
                    <TableCell align='right'>
                      <Button
                        component={Link}
                        to={`/admin/order/${order._id}/edit`}
                      >
                        EDIT
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </div>
  )
}

export default OrderListScreen
