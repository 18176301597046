import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listKursDetails, createKursReview } from '../../actions/kursActions'
import { KURS_CREATE_REVIEW_RESET } from '../../constants/kursConstants'
//Komponente
import Rating from '../../components/Rating'
import Message from '../../components/UI/Message'
import Loader from '../../components/UI/Loader'
import Meta from '../../components/Meta'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import Npp from '../../components/OBUKE/npp'
//CSS
import { makeStyles } from '@material-ui/core/styles'
import { Col, ListGroup, Form } from 'react-bootstrap'
import theme from '../../components/UI/Theme'
import {
  Box,
  Grid,
  Paper,
  Button,
  CardActionArea,
  CardMedia,
  Divider,
} from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    padding: theme.spacing(1.4),
    margin: 'auto',
    width: 1350,
    backgroundColor: theme.palette.common.aitiGrey,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  box: {
    display: 'flex',
    verticalAlign: 'center',
    height: 50,
    marginTop: 80,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginBottom: 60,
    },
  },
  coverArrow: { height: 50, verticalAlign: 'center' },
  coverArrow1: { height: 50, paddingTop: 15 },

  headerSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 0,
    marginBottom: 0,
    fontFamily: 'PT Sans',
    fontSize: '1.6rem',
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      height: '2rem',
      paddingBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: 350,
      height: 50,
      margin: 0,
      paddingLeft: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    marginTop: 20,
    letterSpacing: 2,
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 20,
      fontSize: '2.5rem',
    },
  },
  prijavaButton: {
    ...theme.prijavaButton,
    marginLeft: 20,
    marginBottom: 50,
  },
  gridKursLeft: {
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  gridKursRight: {
    width: '40%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  boxComment: {
    width: '80%',
  },
})

const KursDisplej = ({ history, match }) => {
  const classes = useStyles()
  const [qty, setQty] = useState(1)
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')

  const dispatch = useDispatch()

  const kursDetails = useSelector((state) => state.kursDetails)
  const { loading, error, kurs } = kursDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const kursReviewCreate = useSelector((state) => state.kursCreateReview)
  const {
    success: successKursReview,
    loading: loadingKursReview,
    error: errorKursReview,
  } = kursReviewCreate

  useEffect(() => {
    if (successKursReview) {
      setRating(0)
      setComment('')
    }
    if (!kurs._id || kurs._id !== match.params.id) {
      dispatch(listKursDetails(match.params.id))
      dispatch({ type: KURS_CREATE_REVIEW_RESET })
    }
  }, [dispatch, match, successKursReview])

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createKursReview(match.params.id, {
        rating,
        comment,
      })
    )
  }

  return (
    <div className={classes.main}>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Paper className={classes.paper_top}>
            <Box>
              <Link to='/kursevi'>
                <div className={classes.box}>
                  <Box component='div' display='inline'>
                    <KeyboardArrowLeftIcon className={classes.coverArrow} />
                  </Box>
                  <Box
                    component='div'
                    display='inline'
                    className={classes.coverArrow1}
                  >
                    SVI KURSEVI
                  </Box>
                </div>
              </Link>
            </Box>
            <Grid
              container
              direction='column'
              justify='space-between'
              className={classes.coverBox}
            >
              <Meta title={kurs.name} />

              <Box>
                <p className={classes.headerSubTitle}>Digital skills</p>
                <h3 className={classes.mainTitle}>{kurs.name}</h3>

                <Button
                  onClick={addToCartHandler}
                  type='button'
                  variant='outlined'
                  disabled={kurs.countInStock === 0}
                  className={classes.prijavaButton}
                >
                  Odaberi
                </Button>

                <Divider />
              </Box>
            </Grid>
          </Paper>
          <Paper className={classes.paper_top}>
            <Grid container>
              <Grid item className={classes.gridKursLeft}>
                <Npp kurs={kurs} />
              </Grid>
              <Grid item className={classes.gridKursRight}>
                <Divider />

                <CardActionArea>
                  <CardMedia
                    component='img'
                    className={classes.media}
                    image={kurs.image}
                  />
                </CardActionArea>
                <Divider />
                <div>
                  <h3>{kurs.name}</h3>
                </div>
                <Divider />
                <div>
                  <Rating
                    value={kurs.rating}
                    text={`${kurs.numReviews} ocjena`}
                  />
                </div>
                <Divider />
                <div>
                  <p>Cijena: KM {kurs.price}</p>
                </div>
                <div>{kurs.subTitle}</div>
                <Grid container>
                  <Grid item>
                    <p>Trajanje kursa</p>
                  </Grid>
                  <Grid item>
                    <strong>{kurs.duration} sati</strong>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <p>Cijena</p>
                  </Grid>
                  <Grid item>
                    <strong>{kurs.price} KM </strong>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <p>Status:</p>
                    <p>
                      {kurs.countInStock > 0
                        ? 'Jos uvijek ima slobodih mjesta za prijavu.'
                        : 'Nemozete se prijaviti, sva mjesta su popunjena.'}
                    </p>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={addToCartHandler}
                      className='btn-block'
                      type='button'
                      disabled={kurs.countInStock === 0}
                    >
                      Prijavi se
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Box className={classes.boxComment}>
                  <Col md={6}>
                    <h2>Ocjene</h2>
                    {kurs.reviews.length === 0 && (
                      <Message>Nema ocjena</Message>
                    )}
                    <ListGroup variant='flush'>
                      {kurs.reviews.map((review) => (
                        <ListGroup.Item key={review._id}>
                          <strong>{review.name}</strong>
                          <Rating value={review.rating} />
                          <p>{review.createdAt.substring(0, 10)}</p>
                          <p>{review.comment}</p>
                        </ListGroup.Item>
                      ))}
                      <ListGroup.Item>
                        <h2>Napisite komentar</h2>
                        {successKursReview && (
                          <Message variant='success'>
                            Vas komentar je registrovan
                          </Message>
                        )}
                        {loadingKursReview && <Loader />}
                        {errorKursReview && (
                          <Message variant='danger'>{errorKursReview}</Message>
                        )}
                        {userInfo ? (
                          <Form onSubmit={submitHandler}>
                            <Form.Group controlId='rating'>
                              <Form.Label>Ocjena</Form.Label>
                              <Form.Control
                                as='select'
                                value={rating}
                                onChange={(e) => setRating(e.target.value)}
                              >
                                <option value=''>Odaberi...</option>
                                <option value='1'>1 - Slabo</option>
                                <option value='2'>2 - Dovoljno</option>
                                <option value='3'>3 - Dobro</option>
                                <option value='4'>4 - Vrlodobro</option>
                                <option value='5'>5 - Odlicno</option>
                              </Form.Control>
                            </Form.Group>
                            <Form.Group controlId='comment'>
                              <Form.Label>Komentar</Form.Label>
                              <Form.Control
                                as='textarea'
                                row='3'
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                            <Button
                              disabled={loadingKursReview}
                              type='submit'
                              variant='primary'
                            >
                              Posalji
                            </Button>
                          </Form>
                        ) : (
                          <Message>
                            Potrebno je da se
                            <Link to='/login'> prijavite</Link> kako bi napisali
                            komentar
                          </Message>
                        )}
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </div>
  )
}

export default KursDisplej
