import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { register } from '../../actions/userActions'
//Components
import Message from '../../components/UI/Message'
import Loader from '../../components/UI/Loader'
//Styles
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundColor: theme.palette.common.aitiGreyIV,
  },
  main: {
    marginTop: 80,
    backgroundColor: theme.palette.common.aitiGreyIII,
  },
  paper: {
    marginTop: theme.spacing(8),
    //paddingTop: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  textfield: {
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.common.aitiGreyIV,
      color: '#000',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    ...theme.loginButton,
    margin: theme.spacing(3, 0, 2),
  },
}))

const RegisterScreen = ({ location, history }) => {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userInfo } = userRegister

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Lozinka se ne podudaraju')
    } else {
      dispatch(register(name, email, password))
    }
  }

  return (
    <Grid container className={classes.root}>
      <Container component='main' maxWidth='xs' className={classes.main}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h3' variant='h5'>
            Kreiraj svoj AITI racun
          </Typography>
          {message && <Message variant='danger'>{message}</Message>}
          {error && <Message variant='danger'>{error}</Message>}
          {loading && <Loader />}
          <form className={classes.form} noValidate onSubmit={submitHandler}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete='fname'
                  name='firstName'
                  variant='outlined'
                  required
                  fullWidth
                  id='firstName'
                  label='Ime'
                  autoFocus
                  className={classes.textfield}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  id='lastName'
                  label='Prezime'
                  name='lastName'
                  autoComplete='lname'
                  className={classes.textfield}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  id='email'
                  label='Email adresa'
                  name='email'
                  autoComplete='email'
                  className={classes.textfield}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  name='password'
                  label='Lozinka'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  className={classes.textfield}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  name='password'
                  label='Potvrda lozinke'
                  type='password'
                  id='password'
                  autoComplete='confirm-password'
                  className={classes.textfield}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value='allowExtraEmails' color='primary' />
                  }
                  label='Zelim da dobijam vise informacija o desavanjima u AITI  putem emaila.'
                />
              </Grid>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                Registruj se
              </Button>
              <Grid container justify='flex-start'>
                <Grid item>
                  <Link
                    to={redirect ? `/login?redirect=${redirect}` : '/login'}
                    variant='body2'
                  >
                    Vec ste registrovani? Prijavite se
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </Grid>
  )
}

export default RegisterScreen
