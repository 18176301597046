import React, { useState } from 'react'
import { Link } from 'react-router-dom'
//Components
import Rating from '../Rating'
//CSS
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../components/UI/Theme'
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    width: 350,
    backgroundColor: theme.palette.common.aitiGrey,
    transition: 'transform 0.25s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: '5%',
      backgroundColor: theme.palette.common.aitiGrey,
    },
  },
  media: {
    height: 240,
  },
  cardHovered: {
    transform: 'scale3d(1.01, 1.01, 1)',
    backgroundColor: theme.palette.common.aitiWhite,
  },
  actions: {
    paddingLeft: 16,
    paddingBottom: 16,
  },
  button: {
    ...theme.button,
  },
})

const Kurs = ({ kurs }) => {
  const classes = useStyles()

  const [state, setState] = useState({
    raised: false,
    shadow: 1,
  })

  return (
    <Card
      className={classes.root}
      classes={{ root: state.raised ? classes.cardHovered : '' }}
      onMouseOver={() => setState({ raised: true, shadow: 3 })}
      onMouseOut={() => setState({ raised: false, shadow: 1 })}
      raised={state.raised}
      zdepth={state.shadow}
    >
      <CardActionArea>
        <CardMedia
          component='img'
          className={classes.media}
          image={kurs.image}
        />
      </CardActionArea>
      <CardContent>
        <Link to={`/kurs/${kurs._id}`}>
          <Typography gutterBottom variant='h5' component='h2'>
            <strong>{kurs.name}</strong>
          </Typography>
        </Link>

        <Typography variant='body2' color='textSecondary' component='p'>
          {kurs.subTitle}
        </Typography>
        <Typography>
          <div>
            <div>
              <Rating value={kurs.rating} text={`${kurs.numReviews} ocjena`} />
            </div>
            <div>
              <p>{kurs.price} KM</p>
            </div>
          </div>
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Link to={`/kurs/${kurs._id}`}>
          <Button
            variant='outlined'
            size='medium'
            color='secondary'
            className={classes.button}
          >
            Saznaj vise
          </Button>
        </Link>
      </CardActions>
    </Card>
  )
}

export default Kurs
