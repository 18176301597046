import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import theme from './components/UI/Theme'
import ScrollToTop from './components/ScroolToTop'
//ADMIN SCREENS
import AdminScreen from './screens/Admin/AdminScreen'
import VijestListScreen from './screens/Admin/VijestListScreen'
import KursListScreen from './screens/Admin/KursListScreen'
import OrderListScreen from './screens/Admin/OrderListScreen'
import CareerListScreen from './screens/Admin/CareerListScreen'
import UserListScreen from './screens/Admin/UserListScreen'
import KursEditScreen from './screens/Admin/KursEditScreen'
import VijestEditScreen from './screens/Admin/VijestEditScreen'
import OrderEditScreen from './screens/Admin/OrderEditScreen'
import CareerEditScreen from './screens/Admin/CareerEditScreen'
//PUBLIC SCREENS
import HomeScreen from './screens/HomeScreen'
import Header from './components/Header'
import Footer from './components/Footer'
//Careers screens
import CareersScreen from './screens/Career/CareersScreen'
import CareerScreen from './screens/Career/CareerScreen'
//News screens
import VijestiScreen from './screens/Vijesti/VijestiScreen'
import VijestDisplej from './screens/Vijesti/VijestScreen'
//Obuka screens
import KursDisplej from './screens/Obuke/KursScreen'
import CartScreen from './screens/Obuke/CartScreen'
import ShippingScreen from './screens/Obuke/ShippingScreen'
import PaymentScreen from './screens/Obuke/PaymentScreen'
import PlaceOrderScreen from './screens/Obuke/PlaceOrderScreen'
import OrderScreen from './screens/Obuke/OrderScreen'
import ObukeScreen from './screens/Obuke/ObukeScreen'
import KurseviScreen from './screens/Obuke/KurseviScreen'
//Users screens
import LoginScreen from './screens/Users/LoginScreen'
import RegisterScreen from './screens/Users/RegisterScreen'
import ProfileScreen from './screens/Users/ProfileScreen'
import UserEditScreen from './screens/Users/userEditScreen'
import UserPortalScreen from './screens/Users/UserPortalScreen'
//Pages screens
import UslugeScreen from './screens/Pages/Usluge'
import UslugePregledScreen from './screens/Pages/UslugePregledScreen'
import OblastiScreen from './screens/Pages/OblastiScreen'
import OblastiTehnologijeScreen from './screens/Pages/OblastiTehnologijeScreen'
import VjestineScreen from './screens/Pages/Vjestine'
import AboutUs from './screens/Pages/AboutUs'

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Header />
        <main>
          <Switch>
            <Route
              path='/oblasti/tehnologije'
              component={OblastiTehnologijeScreen}
            />
            <Route path='/oblasti' component={OblastiScreen} />
            <Route path='/usluge/pregled' component={UslugePregledScreen} />
            <Route path='/usluge' component={UslugeScreen} />
            <Route path='/aiti' component={AboutUs} />
            <Route path='/administracija' component={AdminScreen} />
            <Route path='/careers' component={CareersScreen} />
            <Route path='/career/:id' component={CareerScreen} />
            <Route path='/cart/:id?' component={CartScreen} />
            <Route path='/admin/career/:id/edit' component={CareerEditScreen} />
            <Route path='/kurs/:id' component={KursDisplej} />
            <Route path='/admin/kurs/:id/edit' component={KursEditScreen} />
            <Route path='/admin/kurslist' component={KursListScreen} exact />
            <Route path='/vijest/:id' component={VijestDisplej} />
            <Route path='/admin/vijest/:id/edit' component={VijestEditScreen} />
            <Route
              path='/admin/vijestlist'
              component={VijestListScreen}
              exact
            />
            <Route
              path='/admin/kurslist/:pageNumber'
              component={KursListScreen}
              exact
            />
            <Route path='/login' component={LoginScreen} />
            <Route path='/order/:id' component={OrderScreen} />
            <Route path='/admin/order/:id/edit' component={OrderEditScreen} />
            <Route path='/kursevi' component={KurseviScreen} />
            <Route path='/vjestine' component={VjestineScreen} />
            <Route path='/obuke' component={ObukeScreen} />
            <Route path='/admin/orderlist' component={OrderListScreen} />
            <Route
              path='/admin/careerlist'
              component={CareerListScreen}
              exact
            />
            <Route path='/payment' component={PaymentScreen} />
            <Route path='/placeorder' component={PlaceOrderScreen} />
            <Route path='/register' component={RegisterScreen} />
            <Route path='/user/:id' component={UserPortalScreen} />
            <Route path='/profile' component={ProfileScreen} />
            <Route path='/shipping' component={ShippingScreen} />
            <Route path='/admin/userlist' component={UserListScreen} />
            <Route path='/admin/user/:id/edit' component={UserEditScreen} />
            <Route path='/vijesti' component={VijestiScreen} />
            <Route path='/search/:keyword' component={ObukeScreen} exact />
            <Route
              path='/search/:keyword/page/:pageNumber'
              component={ObukeScreen}
            />
            <Route path='/page/:pageNumber' component={ObukeScreen} />
            <Route path='/' component={HomeScreen} exact />
          </Switch>
        </main>
        <Footer />
      </Router>
    </ThemeProvider>
  )
}

export default App
