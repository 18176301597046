import React from 'react'
import { Link } from 'react-router-dom'
import Pagination from '@material-ui/lab/Pagination'

function Paginate({ pages, page, isAdmin = false, keyword = '' }) {
  return (
    pages > 1 && (
      <>
        {[...Array(pages).keys()].map((x) => (
          <Link
            key={x + 1}
            to={
              !isAdmin
                ? keyword
                  ? `/search/${keyword}/page/${x + 1}`
                  : `/page/${x + 1}`
                : `/admin/kurslist/${x + 1}`
            }
          >
            <div active={x + 1 === page}>{x + 1}</div>
          </Link>
        ))}
      </>
    )
  )
}

export default Paginate
