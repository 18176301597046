import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../actions/userActions'
//Components
import Message from '../../components/Message'
import Loader from '../../components/Loader'
//Styles
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
//import aitiTheme from '../../components/UI/AitiTheme'
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    '& .MuiFilledInput-input': {
      color: '#000111',
      '& input': {
        color: '#cccccc',
      },
    },
  },
  main: {
    backgroundColor: theme.palette.common.aitiGreyIII,
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    paddingTop: 80,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.common.aitiGreyIII,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  textfield: {
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.common.aitiGreyIV,
      color: '#000',
    },
  },

  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    ...theme.loginButton,
    margin: theme.spacing(3, 0, 2),
  },
}))

const LoginScreen = ({ location, history }) => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }
  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        className={classes.main}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Prijavi se
          </Typography>
          <form className={classes.form} noValidate onSubmit={submitHandler}>
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email adresa'
              name='email'
              autoComplete='email'
              autoFocus
              className={classes.textfield}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Lozinka'
              type='password'
              id='password'
              autoComplete='current-password'
              className={classes.textfield}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value='remember' color='secondary' />}
              label='Zapamti me'
            />

            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.submit}
              fullWidth
            >
              Prijavi se
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href='#' variant='body2'>
                  Zaboravili ste lozinku?
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to={redirect ? `/register?redirect=${redirect}` : '/register'}
                  variant='body2'
                >
                  {'Nemate registrovan racun? Registruj se'}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}

export default LoginScreen
