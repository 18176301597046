import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
//CSS
import { makeStyles, useTheme, ThemeProvider } from '@material-ui/core/styles'
import { AppBar, Box, Tabs, Tab, Typography } from '@material-ui/core'
import outerTheme from '../UI/OuterTheme'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: 600,
    width: '100%',
  },
  tab: {
    backgroundColor: theme.palette.common.aitiGrey,
    color: '#000000',
    '&,&:hover': {
      color: '#000000',
    },
  },
  customStyleOnActiveTab: {
    color: 'red',
  },
}))

const Npp = ({ kurs }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  return (
    <div className={classes.root}>
      <ThemeProvider theme={outerTheme}>
        <AppBar position='static' color='default'>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            aria-label='full width tabs example'
            className={classes.tabs}
          >
            <Tab label='UKRATKO' {...a11yProps(0)} className={classes.tab} />
            <Tab label='ISHODI' {...a11yProps(1)} className={classes.tab} />
            <Tab
              label='PERSPEKTIVA'
              {...a11yProps(2)}
              className={classes.tab}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <div dangerouslySetInnerHTML={{ __html: kurs.shortDesc }} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <div dangerouslySetInnerHTML={{ __html: kurs.ishodiObuke }} />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <div dangerouslySetInnerHTML={{ __html: kurs.perspektiveObuke }} />
          </TabPanel>
        </SwipeableViews>
      </ThemeProvider>
    </div>
  )
}
export default Npp
