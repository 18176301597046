import React from 'react'
import { useScrollTrigger } from '@material-ui/core'

export default function ScrollColor(props) {
  const {
    threshold,
    bgColorBefore,
    bgColorAfter,
    textColorBefore,
    textColorAfter,
    fadeIn,
    fadeOut,
    paddingBefore,
    paddingAfter,
    heightBefore,
    heightAfter,
    opacityBefore,
    opacityAfter,
    children,
    ...other
  } = {
    threshold: 100,
    bgColorBefore: '#000',
    bgColorAfter: '#fff',
    textColorBefore: '#fff',
    textColorAfter: '#000',
    fadeIn: '0.5s ease-in',
    fadeOut: '0,5s ease-out',
    paddingBefore: '0.5rem',
    heightAfter: 50,
    heightBefore: 80,
    opacityBefore: 0.5,
    opacityAfter: 0.95,
    ...props,
  }

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: threshold,
    target: props.window ? window() : undefined,
  })

  return React.cloneElement(children, {
    style: {
      boxShadow: trigger ? '5px 10px 18px #888888' : 'none',
      paddingTop: trigger ? paddingAfter : paddingBefore,
      paddingBottom: trigger ? paddingAfter : paddingBefore,
      backgroundColor: trigger ? bgColorAfter : bgColorBefore,
      color: trigger ? textColorAfter : textColorBefore,
      transition: trigger ? fadeIn : fadeOut,
      height: trigger ? heightAfter : heightBefore,
      opacity: trigger ? opacityAfter : opacityBefore,
    },
    ...other,
  })
}
