import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listKursDetails, updateKurs } from '../../actions/kursActions'
import { KURS_UPDATE_RESET } from '../../constants/kursConstants'
//Komponente
import Message from '../../components/UI/Message'
import Loader from '../../components/UI/Loader'
import FormContainer from '../../components/UI/FormContainer'
import theme from '../../components/UI/Theme'
import { Form } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormControl,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'

const useStyles = makeStyles({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    ...theme.paperMain,
    height: 'auto',
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 0,
    },
  },
  gridCardLeft: {
    width: '60%',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  gridCardRight: {
    width: '37%',
    marginLeft: '3%',
    padding: 20,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 10,
    },
  },
  box: {
    display: 'flex',
    verticalAlign: 'center',
    height: 50,
    marginTop: 80,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginBottom: 60,
    },
  },
  coverArrow: { height: 50, verticalAlign: 'center' },
  coverArrow1: { height: 50, paddingTop: 15 },

  coverBox: {
    height: 200,
    [theme.breakpoints.down('xs')]: {
      height: 250,
    },
  },

  headerSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 0,
    marginBottom: 0,
    fontFamily: 'PT Sans',
    fontSize: '1.6rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      height: '2rem',
      paddingBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      height: 50,
      margin: 0,
      paddingLeft: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    marginTop: 20,
    letterSpacing: '2px',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 20,
      fontSize: '2.5rem',
    },
  },
  prijavaButton: {
    ...theme.prijavaButton,
    marginTop: 50,
    marginBottom: 50,
  },
  boxForm: {
    marginTop: 20,
  },
  mainFormGroup: {
    marginBottom: 20,
  },
})

const KursEditScreen = ({ match, history }) => {
  const classes = useStyles()
  const kursId = match.params.id

  const [name, setName] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [image, setImage] = useState('')
  const [shortDesc, setShortDesc] = useState('')
  const [description, setDescription] = useState('')
  const [start, setStart] = useState('')
  const [duration, setDuration] = useState('')
  const [category, setCategory] = useState('')
  const [tipObuke, setTipObuke] = useState('')
  const [ishodiObuke, setIshodiObuke] = useState('')
  const [perspektiveObuke, setPerspektiveObuke] = useState('')
  const [price, setPrice] = useState(0)
  const [countInStock, setCountInStock] = useState(0)
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  const kursDetails = useSelector((state) => state.kursDetails)
  const { loading, error, kurs } = kursDetails

  const kursUpdate = useSelector((state) => state.kursUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = kursUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: KURS_UPDATE_RESET })
      history.push('/admin/kurslist')
    } else {
      if (!kurs.name || kurs._id !== kursId) {
        dispatch(listKursDetails(kursId))
      } else {
        setName(kurs.name)
        setSubTitle(kurs.subTitle)
        setImage(kurs.image)
        setShortDesc(kurs.shortDesc)
        setDescription(kurs.description)
        setStart(kurs.start)
        setDuration(kurs.duration)
        setCategory(kurs.category)
        setTipObuke(kurs.tipObuke)
        setIshodiObuke(kurs.ishodiObuke)
        setPerspektiveObuke(kurs.perspektiveObuke)
        setPrice(kurs.price)
        setCountInStock(kurs.countInStock)
      }
    }
  }, [dispatch, history, kursId, kurs, successUpdate])
  //tinymce
  const handleShortDesc = (content, e) => {
    setShortDesc(content)
  }
  const [value, setValue] = useState(shortDesc ?? '')
  useEffect(() => setValue(shortDesc ?? ''), [shortDesc])

  const handleDescription = (content, e) => {
    setDescription(content)
  }
  const [value1, setValue1] = useState(description ?? '')
  useEffect(() => setValue1(description ?? ''), [description])

  const handleIshodiObuke = (content, e) => {
    setIshodiObuke(content)
  }
  const [value2, setValue2] = useState(ishodiObuke ?? '')
  useEffect(() => setValue2(ishodiObuke ?? ''), [ishodiObuke])

  const handlePerspektiveObuke = (content, e) => {
    setPerspektiveObuke(content)
  }
  const [value3, setValue3] = useState(perspektiveObuke ?? '')
  useEffect(() => setValue3(perspektiveObuke ?? ''), [perspektiveObuke])

  //tinymce end
  const handleChangeCategory = (e) => {
    setCategory(e.target.value)
  }
  const handleChangeTipObuke = (e) => {
    setTipObuke(e.target.value)
  }

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateKurs({
        _id: kursId,
        name,
        subTitle,
        image,
        shortDesc,
        description,
        start,
        duration,
        category,
        tipObuke,
        ishodiObuke,
        perspektiveObuke,
        price,
        countInStock,
      })
    )
  }

  return (
    <div className={classes.main}>
      <Paper className={classes.paper_top}>
        <Box>
          <Link to='/admin/kurslist'>
            <div className={classes.box}>
              <Box component='div' display='inline'>
                <KeyboardArrowLeftIcon className={classes.coverArrow} />
              </Box>
              <Box
                component='div'
                display='inline'
                className={classes.coverArrow1}
              >
                NAZAD NA SVE KURSEVE
              </Box>
            </div>
          </Link>
        </Box>
        <Grid container justify='space-between' className={classes.coverBox}>
          <Box className={classes.titleBox}>
            <h3 className={classes.mainTitle}>OBRAZOVNI PROGRAM</h3>
            <p className={classes.mainSubTitle}>
              Editovanje selektovanog obrazovnog programa
            </p>
          </Box>
        </Grid>
      </Paper>
      <FormContainer>
        <h1>Uredi podatke o kursu</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='denger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <FormGroup controlId='name' className={classes.mainFormGroup}>
              <FormLabel>Naziv kursa</FormLabel>
              <FormControl
                type='text'
                placeholder='Naziv kursa'
                value={name}
                onChange={(e) => setName(e.target.value)}
              >
                <TextField
                  id='outlined-basic'
                  label='Naziv kursa'
                  variant='outlined'
                  value={name}
                />
              </FormControl>
            </FormGroup>
            <FormGroup controlId='subTitle' className={classes.mainFormGroup}>
              <FormLabel>Podnaslov</FormLabel>
              <FormControl
                type='text'
                placeholder='Podnaslov kursa'
                value={subTitle}
                onChange={(e) => setSubTitle(e.target.value)}
              >
                <TextField
                  id='outlined-basic'
                  label='Podnaslov kursa'
                  variant='outlined'
                  value={subTitle}
                />
              </FormControl>
            </FormGroup>

            <Form.Group controlId='image'>
              <Form.Label>Slika</Form.Label>
              <Form.Control
                type='text'
                placeholder='Slika kursa'
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
              <Form.File
                id='image-file'
                label='Izaberi datoteku'
                type='file'
                custum
                onChange={uploadFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </Form.Group>

            <FormGroup controlId='shortDesc' className={classes.mainFormGroup}>
              <FormLabel>Kratak opis</FormLabel>
              <FormControl
                type='text'
                placeholder='Max 100 karaktera'
                value={shortDesc}
                onChange={(e) => setShortDesc(e.target.value)}
              >
                <Editor
                  value={shortDesc}
                  init={{
                    height: 500,
                    menubar: false,
                    forced_root_block: 'p',
                    outputFormat: 'text',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                  onEditorChange={handleShortDesc}
                />
              </FormControl>
            </FormGroup>
            <FormGroup
              controlId='description'
              className={classes.mainFormGroup}
            >
              <FormLabel>Puni opis programa</FormLabel>
              <FormControl
                type='text'
                placeholder='Max 100 karaktera'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              >
                <Editor
                  value={description}
                  init={{
                    height: 500,
                    menubar: false,
                    forced_root_block: 'p',
                    outputFormat: 'text',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                  onEditorChange={handleDescription}
                />
              </FormControl>
            </FormGroup>

            <FormGroup controlId='start' className={classes.mainFormGroup}>
              <FormLabel>Pocetak kursa</FormLabel>
              <FormControl
                type='text'
                placeholder='Datum pocetka kursa'
                value={start}
                onChange={(e) => setStart(e.target.value)}
              >
                <TextField
                  id='outlined-basic'
                  label='Datum pocetka obuke'
                  variant='outlined'
                  value={start}
                />
              </FormControl>
            </FormGroup>

            <FormGroup controlId='duration' className={classes.mainFormGroup}>
              <FormLabel>Trajanje kursa</FormLabel>
              <FormControl
                type='text'
                placeholder='broj sati kursa'
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              >
                <TextField
                  id='outlined-basic'
                  label='Trajanje obuke u satima'
                  variant='outlined'
                  value={duration}
                />
              </FormControl>
            </FormGroup>

            <FormGroup controlId='category' className={classes.mainFormGroup}>
              <FormLabel as='legend'>Kategorija obrazovnog programa</FormLabel>
              <RadioGroup value={category} onChange={handleChangeCategory}>
                <FormControlLabel
                  control={<Radio />}
                  label='Soft Skills'
                  value='Soft Skills'
                  onChange={(e) => setCategory(e.target.value)}
                ></FormControlLabel>
                <FormControlLabel
                  control={<Radio />}
                  label='Digital Skills'
                  value='Digital Skills'
                  onChange={(e) => setCategory(e.target.value)}
                ></FormControlLabel>
                <FormControlLabel
                  control={<Radio />}
                  label='Tehnicke vjestine'
                  value='Tehnicke'
                  onChange={(e) => setCategory(e.target.value)}
                ></FormControlLabel>
              </RadioGroup>
            </FormGroup>

            <FormGroup controlId='tipObuke' className={classes.mainFormGroup}>
              <FormLabel as='legend'>Tip obuke</FormLabel>
              <RadioGroup value={tipObuke} onChange={handleChangeTipObuke}>
                <FormControlLabel
                  control={<Radio />}
                  label='Obrazovni program'
                  value='Obrazovni program'
                  onChange={(e) => setTipObuke(e.target.value)}
                ></FormControlLabel>
                <FormControlLabel
                  control={<Radio />}
                  label='Trening kurs'
                  value='Trening kurs'
                  onChange={(e) => setTipObuke(e.target.value)}
                ></FormControlLabel>
                <FormControlLabel
                  control={<Radio />}
                  label='Specificna obuka'
                  value='Specificna obuka'
                  onChange={(e) => setTipObuke(e.target.value)}
                ></FormControlLabel>
              </RadioGroup>
            </FormGroup>

            <FormGroup
              controlId='ishodiObuke'
              className={classes.mainFormGroup}
            >
              <FormLabel>Opis ishoda obuke iz NPP</FormLabel>
              <FormControl
                type='text'
                value={ishodiObuke}
                onChange={(e) => setIshodiObuke(e.target.value)}
              >
                <Editor
                  value={ishodiObuke}
                  init={{
                    height: 500,
                    menubar: false,
                    forced_root_block: 'p',
                    outputFormat: 'text',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                  onEditorChange={handleIshodiObuke}
                />
              </FormControl>
            </FormGroup>

            <FormGroup
              controlId='perspektiveObuke'
              className={classes.mainFormGroup}
            >
              <FormLabel>
                Opis perspektive nakon zavrsetka trening programa
              </FormLabel>
              <FormControl
                type='text'
                value={perspektiveObuke}
                onChange={(e) => setPerspektiveObuke(e.target.value)}
              >
                <Editor
                  value={perspektiveObuke}
                  init={{
                    height: 500,
                    menubar: false,
                    forced_root_block: 'p',
                    outputFormat: 'text',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                  onEditorChange={handlePerspektiveObuke}
                />
              </FormControl>
            </FormGroup>

            <FormGroup controlId='price'>
              <FormLabel>Cijena kursa</FormLabel>
              <FormControl
                type='number'
                placeholder='cijena kursa u KM'
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              >
                <TextField
                  id='outlined-basic'
                  label='Cijena obuke'
                  variant='outlined'
                  value={price}
                />
              </FormControl>
            </FormGroup>

            <FormGroup controlId='countInStock'>
              <FormLabel>Broj mjesta</FormLabel>
              <FormControl
                type='number'
                placeholder='Broj mjesta'
                value={countInStock}
                onChange={(e) => setCountInStock(e.target.value)}
              >
                <TextField
                  id='outlined-basic'
                  label='Broj mjesta'
                  variant='outlined'
                  value={countInStock}
                />
              </FormControl>
            </FormGroup>
            <Button
              type='submit'
              variant='primary'
              className={classes.prijavaButton}
            >
              Azuriraj
            </Button>
          </Form>
        )}
      </FormContainer>
    </div>
  )
}

export default KursEditScreen
