import React from 'react'
import { Link } from 'react-router-dom'
//CSS
import { makeStyles } from '@material-ui/core/styles'
import { Button, ButtonGroup } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    marginBottom: 40,
  },
}))

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <ButtonGroup>
        {step1 ? (
          <Button>
            <Link to='/login'>Prijavi se</Link>
          </Button>
        ) : (
          <Button disabled>Prijavi se</Button>
        )}
        {step2 ? (
          <Button>
            <Link to='/shipping'>Isporuka</Link>
          </Button>
        ) : (
          <Button disabled>Isporuka</Button>
        )}
        {step3 ? (
          <Button>
            <Link to='/payment'>Placanje</Link>
          </Button>
        ) : (
          <Button disabled>Placanje</Button>
        )}
        {step4 ? (
          <Button>
            <Link to='/placeorder'>Narudzbe</Link>
          </Button>
        ) : (
          <Button disabled>Narudzbe</Button>
        )}
      </ButtonGroup>
    </div>
  )
}

export default CheckoutSteps
