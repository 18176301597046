import React from 'react'
//Components
import Meta from '../../components/Meta'
import UslugePregledComponent from '../../components/Pages/UslugePregled'
//Style
import { makeStyles, Box, Grid, Paper, Divider } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 0,
      padding: 0,
    },
  },
  paper_top: {
    width: 1350,
    margin: 'auto',
    padding: theme.spacing(1.4),
    paddingLeft: 0,
    backgroundColor: theme.palette.common.aitiGrey,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paperBox: {
    padding: theme.spacing(1.4),
    margin: 'auto',
    width: 1350,
    paddingLeft: 0,
    backgroundColor: theme.palette.common.aitiGrey,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '400px',
      padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  coverBox: {
    height: 'auto',
    width: 'auto',
    margin: 0,
    marginTop: 40,
    [theme.breakpoints.down('xs')]: {
      height: 160,
      width: 370,
      marginTop: 50,
      padding: 20,
      paddingTop: 50,
    },
  },
  titleBox: {
    width: '900px',
    height: 'auto',

    paddingTop: 80,
    marginBottom: 80,
    [theme.breakpoints.down('md')]: {
      height: '400px',
      width: '80%',
      paddingTop: 80,
    },
    [theme.breakpoints.down('sm')]: {
      height: '500px',
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      height: 440,
      width: '100%',
      padding: 20,
      paddingTop: 80,
      marginBottom: 0,
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    width: 500,
    marginTop: 40,
    letterSpacing: '1.5px',
    fontWeight: 400,
    fontSize: '3rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 0,
      fontSize: '3rem',
    },
  },
  mainSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 100,
    fontFamily: 'PT Sans',
    fontSize: '2.3rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      height: '2rem',
      paddingBottom: 50,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      height: '10rem',
      margin: 0,
      paddingRight: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
}))

const UslugePregledScreen = () => {
  const classes = useStyles()

  return (
    <div className={classes.main}>
      <Meta />
      <Paper className={classes.paper_top}>
        <Grid container justify='space-between' className={classes.coverBox}>
          <Box>
            <p>AITI KATALOG</p>
            <h3 className={classes.mainTitle}>AITI USLUGE</h3>
          </Box>
        </Grid>
        <Divider />
      </Paper>

      <Paper className={classes.paperBox}>
        <UslugePregledComponent />
      </Paper>
    </div>
  )
}

export default UslugePregledScreen
