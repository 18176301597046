import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { PayPalButton } from 'react-paypal-button-v2'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from '../../actions/orderActions'
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from '../../constants/orderConstants'
//Components
import Message from '../../components/UI/Message'
import Loader from '../../components/UI/Loader'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
//CSS
import { Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../components/UI/Theme'
import { Box, Button, Divider, Grid, Paper } from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    ...theme.paperMain,
    height: 'auto',
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 0,
    },
  },
  gridCardLeft: {
    width: '60%',
    padding: 20,
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  gridCardRight: {
    width: '37%',
    marginLeft: '3%',
    padding: 20,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 10,
    },
  },
  box: {
    display: 'flex',
    verticalAlign: 'center',
    height: 50,
    marginTop: 80,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginBottom: 60,
    },
  },
  coverArrow: { height: 50, verticalAlign: 'center' },
  coverArrow1: { height: 50, paddingTop: 15 },

  coverBox: {
    height: 200,
    [theme.breakpoints.down('xs')]: {
      height: 250,
    },
  },

  headerSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 0,
    marginBottom: 0,
    fontFamily: 'PT Sans',
    fontSize: '1.6rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      height: '2rem',
      paddingBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      height: 50,
      margin: 0,
      paddingLeft: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    marginTop: 20,
    letterSpacing: '2px',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 20,
      fontSize: '2.5rem',
    },
  },
  prijavaButton: {
    ...theme.prijavaButton,
    marginTop: 50,
    marginBottom: 50,
  },
  boxForm: {
    marginTop: 20,
  },
})

const OrderScreen = ({ match, history }) => {
  const classes = useStyles()
  const orderId = match.params.id

  const [sdkReady, setSdkReady] = useState(false)

  const dispatch = useDispatch()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderPay = useSelector((state) => state.orderPay)
  const { loading: loadingPay, success: successPay } = orderPay

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  if (!loading) {
    //   Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2)
    }

    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    )
  }

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }

    const addPayPalScript = async () => {
      const { data: clientId } = await axios.get('/api/config/paypal')
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`
      script.async = true
      script.onload = () => {
        setSdkReady(true)
      }
      document.body.appendChild(script)
    }

    if (!order || successPay || successDeliver || order._id !== orderId) {
      dispatch({ type: ORDER_PAY_RESET })
      dispatch({ type: ORDER_DELIVER_RESET })
      dispatch(getOrderDetails(orderId))
    } else if (!order.isPaid) {
      if (!window.paypal) {
        addPayPalScript()
      } else {
        setSdkReady(true)
      }
    }
  }, [dispatch, orderId, successPay, successDeliver, order])

  const successPaymentHandler = (paymentResult) => {
    console.log(paymentResult)
    dispatch(payOrder(orderId, paymentResult))
  }

  const deliverHandler = () => {
    dispatch(deliverOrder(order))
  }

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <div className={classes.main}>
      <Paper className={classes.paper_top}>
        <Box>
          <Link to='/kursevi'>
            <div className={classes.box}>
              <Box component='div' display='inline'>
                <KeyboardArrowLeftIcon className={classes.coverArrow} />
              </Box>
              <Box
                component='div'
                display='inline'
                className={classes.coverArrow1}
              >
                SVI KURSEVI
              </Box>
            </div>
          </Link>
        </Box>
        <Grid
          container
          direction='column'
          justify='space-between'
          className={classes.coverBox}
        >
          <Box>
            <h3 className={classes.mainTitle}>
              Vas personalni edukacijski portal
            </h3>
            <p className={classes.headerSubTitle}>
              Na ovom mjestu mozete saznati da li ste primljeni na edukaciju.
              Takodje ovde mozete pronaci sva obavjestenja koja su povezana sa
              vasim obrazovnim aktivnostima u AITI
            </p>
          </Box>
        </Grid>
        <Divider />
      </Paper>
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        className={classes.paper_top}
      >
        <Grid container className={classes.gridCardLeft}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Kontakt podaci</h2>
              <p>
                <strong>Ime: </strong> {order.user.name}
              </p>
              <p>
                <strong>Email: </strong>{' '}
                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              </p>
              <p>
                <strong>Kontakt adresa:</strong>
                {order.shippingAddress.address}, {order.shippingAddress.city}{' '}
                {order.shippingAddress.postalCode},{' '}
                {order.shippingAddress.country}
              </p>
              {order.isDelivered ? (
                <Message variant='success'>
                  Delivered on {order.deliveredAt}
                </Message>
              ) : (
                <Message variant='danger'>Not Delivered</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <p>
                <strong>Method: </strong>
                {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant='success'>Paid on {order.paidAt}</Message>
              ) : (
                <Message variant='danger'>Not Paid</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Order Items</h2>
              {order.orderItems.length === 0 ? (
                <Message>Niste odabrali ni jedan trening program</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/kurs/${item.kurs}`}>{item.name}</Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Grid>
        <Grid container className={classes.gridCardRight}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Items</Col>
                  <Col>${order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Shipping</Col>
                  <Col>${order.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>
                  <Col>${order.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>${order.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              {!order.isPaid && (
                <ListGroup.Item>
                  {loadingPay && <Loader />}
                  {!sdkReady ? (
                    <Loader />
                  ) : (
                    <PayPalButton
                      amount={order.totalPrice}
                      onSuccess={successPaymentHandler}
                    />
                  )}
                </ListGroup.Item>
              )}
              {loadingDeliver && <Loader />}
              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                !order.isDelivered && (
                  <ListGroup.Item>
                    <Button
                      type='button'
                      className='btn btn-block'
                      onClick={deliverHandler}
                    >
                      Mark As Delivered
                    </Button>
                  </ListGroup.Item>
                )}
            </ListGroup>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default OrderScreen
