import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
//Components
import Meta from '../../components/Meta'
import VideoModal from '../../components/UI/VideoModalUsluge'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
//Styles
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../components/UI/Theme'
import { Box, Card, Grid, CardMedia, Hidden, Paper } from '@material-ui/core'
// Images
import coverImage from '../../images/oblasti-cover.jpg'
const linkBox = {
  bgcolor: 'transparent',
  m: 1,
  margin: 'auto',
  style: { width: '300px', height: '60px' },
  borderColor: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
}

const lineBox = {
  bgcolor: 'transparent',
  m: 1,
  margin: 'auto',
  style: { width: 1, height: '100%' },
  borderColor: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}
const useStyles = makeStyles((theme) => ({
  //SHEET
  root: {
    maxWidth: '100%',
    height: 'auto',
    backgroundColor: '#2c3d46',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  //COVER
  cover: {
    maxWidth: '100%',
    height: '80vh',
    [theme.breakpoints.down('sm')]: { height: '100vh' },
  },
  media: {
    height: '80vh',
    alignItem: 'bottom',
    [theme.breakpoints.down('sm')]: { height: '100vh' },
  },
  coverBox: {
    color: '#fff',
    width: 500,
    marginTop: -530,
    marginLeft: 140,
    [theme.breakpoints.down('md')]: {
      width: '90%',
      marginTop: -450,
      marginLeft: 50,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: -550,
      marginLeft: 20,
    },
  },
  coverTitle: {
    marginBottom: 20,
    fontFamily: 'Playfair Display',
    color: '#ffffff',
    fontSize: '3.8rem',
    fontWeight: '600',
    '& span': { color: '#209fbb' },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.2rem',
      fontWeight: '500',
      marginBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.2rem',
      fontWeight: '600',
      marginBottom: 10,
      paddingLeft: 0,
    },
  },
  coverSubTitle: {
    color: '#ffffff',
    marginTop: 40,
    marginBottom: '10px',
    fontWeight: '300',
    fontSize: '1.1rem',
    lineHeight: '1.5',
    alignItem: 'center',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 0,
      paddingRight: 20,
    },
  },
  // PRVI DIO
  prviGrid: {
    height: 300,
    [theme.breakpoints.down('sm')]: { height: 400 },
  },
  prviBox: {
    height: 'auto',
    width: 600,
    margin: 'auto',
    paddingTop: 100,
    verticalAlign: 'center',
    alignItem: 'center',
    [theme.breakpoints.down('xs')]: { width: 375, paddingTop: 100 },
  },
  prviTitle: {
    ...theme.mainTitle,
    color: '#ffffff',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: { fontSize: '3rem' },
  },
  prviSubTitle: {
    ...theme.mainSubTitle,
    letterSpacing: 5,
    textAlign: 'center',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: { fontSize: '1.2rem' },
  },
  //DRUGI DIO
  drugiGrid: {
    height: 600,
    padding: 60,
    width: '100%',
    [theme.breakpoints.down('lg')]: { padding: 20 },
    [theme.breakpoints.down('sm')]: { height: 1400, margin: 0 },
  },
  main_grid_item: {
    width: '33%',
    [theme.breakpoints.down('sm')]: { width: '100%', height: 400 },
  },
  drugiBox: {
    height: 600,
    width: '90%',
    margin: 40,
    padding: 20,
    verticalAlign: 'center',
    alignItem: 'center',
    [theme.breakpoints.down('lg')]: { margin: 10 },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 400,
      margin: 0,
      //paddingLeft: 20,
    },
  },
  drugiTitle: {
    ...theme.mainTitle,
    color: '#fdba45',
    textAlign: 'center',
    [theme.breakpoints.down('xl')]: { fontSize: '3.5rem' },
    [theme.breakpoints.down('lg')]: { fontSize: '2.8rem' },
    [theme.breakpoints.down('md')]: { fontSize: '2.4rem' },
    [theme.breakpoints.down('sm')]: { fontSize: '2rem' },
  },
  drugiSubTitle: {
    height: 60,
    ...theme.mainSubTitle,
    letterSpacing: 5,
    textAlign: 'center',
    color: '#ffffff',
    [theme.breakpoints.down('xl')]: {
      height: 80,
      fontSize: '1.1rem',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      fontSize: '1.2rem',
      fontWeight: 400,
    },
  },
  drugiParagraf: {
    height: 80,
    marginBottom: 40,
    ...theme.mainSubTitle,
    fontSize: '1rem',
    textAlign: 'center',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      height: 100,
      fontWeight: 500,
      lineHeight: 1.7,
    },
  },
  lineGrid: { height: 440 },
  verticalLine: {
    marginTop: 10,
    paddingTop: 15,
    '&:hover': {
      backgroundColor: '#000000',
    },
  },
  // TRECI DIO
  cardBody: {
    height: 600,
    backgroundColor: '#b0afad',
    color: '#fff',
    verticalAlign: 'center',
    fontSize: '1.1rem',
    marginLeft: 0,
    paddingLeft: 0,
    '&:hover': {
      color: '#d5cfd5',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      //paddingLeft: 20,
      backgroundColor: '#4C1450',
      opacity: '0.8',
      fontSize: '1rem',
    },
  },
  cardBodyBox: {
    position: 'center',
    width: 600,
    height: 300,
    padding: 0,
    margin: 'auto',
    verticalAlign: 'bottom',
    textAlign: 'center',
    alignItem: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 40,
    },
  },
  cardBodyBoxMain: {
    ...theme.mainTitle,
    marginTop: 100,
    textAlign: 'center',
    color: '#fff',
    [theme.breakpoints.down('md')]: { fontSize: '2.4rem' },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginTop: 40,
      padding: 'auto',
      fontSize: '2rem',
    },
  },
  cardBodyBoxTitle: {
    paddingBottome: 20,
    color: '#209fbb',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginTop: 0,
      fontSize: '0.9rem',
      letterSpacing: 8,
      '& span': {
        fontSize: '1.8rem',
        color: theme.palette.common.aitiOrange,
      },
    },
  },
  cardBodyBoxSubTitle: {
    color: '#ffffff',
    lineHeight: '1.6',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginTop: 40,
      marginBottom: 40,
      fontSize: '1rem',
    },
  },
  videoBoxMain: {
    paddingTop: 15,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#000000',
    },
  },

  imageBox: {
    height: '100%',
    alignItem: 'bottom',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },

  treciTitle: {
    ...theme.mainTitle,
    height: 140,
    width: '90%',
    fontWeight: '300',
    textAlign: 'center',
    color: '#209fbb',
  },
  treciSubTitle: {
    ...theme.mainSubTitle,
    width: '90%',
    height: 120,
    marginBottome: 40,
    fontSize: '1.2rem',
    textAlign: 'center',
    color: '#ffffff',
  },
  linkBoxText: {
    height: 20,
    margin: 0,
    padding: 0,
    paddingRight: 10,
    paddingTop: 1,
    fontSize: '1rem',
    fontWeight: '1.2rem',
    color: '#ffffff',
  },
}))

const OblastiScreen = ({ match }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Meta />
      <Card className={classes.cover}>
        <CardMedia className={classes.media} image={coverImage} title='AITI' />

        <Grid container direction='column' className={classes.coverBox}>
          <Grid sm item>
            <h3 className={classes.coverTitle}>
              VJESTINE <span>TEHNOLOGIJE</span> INOVACIJE
            </h3>
          </Grid>
          <Grid sm item className={classes.coverSubTitle}>
            Predani novim vjestinama, modernizaciji tehnologija i generisanju
            inovativnog pulsa koji povecava mogucnosti za rast i unaprijedjenje
            performansi
          </Grid>
          <VideoModal />
        </Grid>
      </Card>
      <Grid className={classes.prviGrid}>
        <Box className={classes.prviBox}>
          <h3 className={classes.prviTitle}>Nas fokus je jasan</h3>
          <motion.p
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            transition={{
              type: 'tween',
              ease: 'easeIn',
              delay: 3,
              duration: 6,
            }}
          >
            <p className={classes.prviSubTitle}>PRIPREMITI SE ZA BUDUCNOST</p>
          </motion.p>
        </Box>
      </Grid>

      <Grid container direction='row' className={classes.drugiGrid}>
        <Grid item className={classes.main_grid_item}>
          <Box className={classes.drugiBox}>
            <h3 className={classes.drugiTitle}>VJESTINE</h3>
            <p className={classes.drugiSubTitle}>BUDUCNOST RADNOG MJESTA</p>
            <p className={classes.drugiParagraf}>
              Mi smo posveceni smanjivanju jaza u vjestinama i podsticanju
              kreiranja socijalnih inovacija koristenjem digitalnih tehnologija
            </p>
            <Link to='/vjestine'>
              <Box
                display='flex'
                justifyContent='center'
                border={1}
                cursor='pointer'
                className={classes.videoBoxMain}
                {...linkBox}
                alignItem='center'
              >
                <p className={classes.linkBoxText}>Saznaj vise</p>
                <span>
                  <ArrowForwardIosIcon style={{ fill: '#ffffff' }} />
                </span>
              </Box>
            </Link>
          </Box>
        </Grid>
        <Hidden smDown>
          <Box className={classes.lineGrid}>
            <Box
              display='flex'
              justifyContent='center'
              borderLeft={1}
              className={classes.verticalLine}
              {...lineBox}
              alignItem='center'
            ></Box>
          </Box>
        </Hidden>
        <Grid sm item className={classes.main_grid_item}>
          <Box className={classes.drugiBox}>
            <h3 className={classes.drugiTitle}>TEHNOLOGIJE</h3>
            <p className={classes.drugiSubTitle}>
              SPREMNOST ZA DIGITALNU TRANSFORMACIJU
            </p>
            <p className={classes.drugiParagraf}>
              Predani podrsci transformaciji nacina obavljanja posla koristenjem
              savremenih digitalnih tehnologija.
            </p>
            <Link to='/oblasti/tehnologije'>
              <Box
                display='flex'
                justifyContent='center'
                border={1}
                cursor='pointer'
                className={classes.videoBoxMain}
                {...linkBox}
                alignItem='center'
              >
                <p className={classes.linkBoxText}>Saznaj vise</p>
                <span>
                  <ArrowForwardIosIcon style={{ fill: '#ffffff' }} />
                </span>
              </Box>
            </Link>
          </Box>
        </Grid>
        <Hidden smDown>
          <Box className={classes.lineGrid}>
            <Box
              display='flex'
              justifyContent='center'
              borderLeft={1}
              className={classes.verticalLine}
              {...lineBox}
              alignItem='center'
            ></Box>
          </Box>
        </Hidden>
        <Grid sm item className={classes.main_grid_item}>
          <Box className={classes.drugiBox}>
            <h3 className={classes.drugiTitle}>INOVACIJE</h3>
            <p className={classes.drugiSubTitle}>
              ZA ODRZIVI POSLOVNI I SOIJALNI RAZVOJ
            </p>
            <p className={classes.drugiParagraf}>
              Predani podrsci usvajanju naprednih tehnologija koje mogu
              doprinijeti postizanju punog potecijala koje svako od nas ima.
            </p>
            <Link to='/usluge/pregled'>
              <Box
                display='flex'
                justifyContent='center'
                border={1}
                cursor='pointer'
                className={classes.videoBoxMain}
                {...linkBox}
                alignItem='center'
              >
                <p className={classes.linkBoxText}>Saznaj vise</p>
                <span>
                  <ArrowForwardIosIcon style={{ fill: '#ffffff' }} />
                </span>
              </Box>
            </Link>
          </Box>
        </Grid>
      </Grid>

      <Card className={classes.cardBody}>
        <Box className={classes.cardBodyBox}>
          <h1 className={classes.cardBodyBoxMain}>AITI:EDUCATION</h1>
          <h3 className={classes.cardBodyBoxTitle}>
            Obrazovanje vrhunske kvalitete<span>.</span>
          </h3>
          <p className={classes.cardBodyBoxSubTitle}>
            Mi smo specijalizirani za razvoj vjestina radne snage. Digitalne,
            socijalne i emocionalne vjestine su u potraznji i AITI ima recept za
            vasu uspjesnu transformaciju vjestina
          </p>
        </Box>
        <Link to='/obuke'>
          <Box
            display='flex'
            justifyContent='center'
            border={1}
            cursor='pointer'
            className={classes.videoBoxMain}
            {...linkBox}
            alignItem='center'
          >
            <p className={classes.linkBoxText}>Vise o obrazovanju</p>
            <span>
              <ArrowForwardIosIcon style={{ fill: '#ffffff' }} />
            </span>
          </Box>
        </Link>
      </Card>
    </Paper>
  )
}

export default OblastiScreen
