import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addToCart, removeFromCart } from '../../actions/cartActions'
//Komponente
import Message from '../../components/UI/Message'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KursTopTri from '../../components/OBUKE/KursTopTri'
//CSS
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../components/UI/Theme'
import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  FormControl,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    ...theme.paperMain,
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  box: {
    display: 'flex',
    verticalAlign: 'center',
    height: 50,
    marginTop: 80,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginBottom: 60,
    },
  },
  coverArrow: { height: 50, verticalAlign: 'center' },
  coverArrow1: { height: 50, paddingTop: 15 },

  headerSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 0,
    marginBottom: 0,
    fontFamily: 'PT Sans',
    fontSize: '1.6rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      height: '2rem',
      paddingBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      height: 50,
      margin: 0,
      paddingLeft: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    marginTop: 20,
    letterSpacing: '2px',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 20,
      fontSize: '2.5rem',
    },
  },
  prijavaButton: {
    ...theme.prijavaButton,
    marginBottom: 50,
  },
  gridCardLeft: {
    width: '60%',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 10,
    },
  },
  boxPrice: {
    width: 100,
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 70,
    },
  },
  gridCardLeftContainer: {
    display: 'inline',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  gridCardLeftContainerBox: {
    width: '100%',
    height: 150,
    marginTop: 0,
    marginRight: 40,
    marginBottom: 0,
    padding: 20,
    '&:hover': {
      transform: 'scale3d(1.01, 1.01, 1)',
      backgroundColor: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      marginRight: 20,
      padding: 0,
    },
  },
  mediaCard: {
    height: 100,
  },
  media: {
    width: 200,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 75,
      height: '100%',
    },
  },
  itemBox: {
    width: '55%',
    [theme.breakpoints.down('sm')]: {
      width: '52%',
    },
  },
  itemCategory: {
    color: '#444333',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
  },
  itemPrice: {
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      width: '20%',
    },
  },

  gridCardRight: {
    width: '37%',
    marginLeft: '3%',
    padding: 20,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 10,
    },
  },
  boxComment: {
    width: '80%',
  },
})

const CartScreen = ({ match, location, history }) => {
  const classes = useStyles()
  const kursId = match.params.id

  const qty = location.search ? Number(location.search.split('=')[1]) : 1

  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  useEffect(() => {
    if (kursId) {
      dispatch(addToCart(kursId, qty))
    }
  }, [dispatch, kursId, qty])

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
  }

  const checkoutHandler = () => {
    history.push('/login?redirect=shipping')
  }

  return (
    <div className={classes.main}>
      <Paper className={classes.paper_top}>
        <Box>
          <Link to='/kursevi'>
            <div className={classes.box}>
              <Box component='div' display='inline'>
                <KeyboardArrowLeftIcon className={classes.coverArrow} />
              </Box>
              <Box
                component='div'
                display='inline'
                className={classes.coverArrow1}
              >
                SVI KURSEVI
              </Box>
            </div>
          </Link>
        </Box>
        <Grid
          container
          direction='column'
          justify='space-between'
          className={classes.coverBox}
        >
          <Box>
            <p className={classes.headerSubTitle}>AITI Digital Lab</p>
            <h3 className={classes.mainTitle}>
              Vasi odabrani trening programi
            </h3>
            <Divider />
          </Box>
        </Grid>
      </Paper>
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        className={classes.paper_top}
      >
        <Grid item className={classes.gridCardLeft}>
          <div>
            <Grid container direction='row' alignItems='flex-end'>
              <Grid item>
                <p>VASA SELEKCIJA</p>
              </Grid>
              <Grid item className={classes.boxPrice}>
                <p>Cijena</p>
              </Grid>
            </Grid>
            {cartItems.length === 0 ? (
              <Message>
                Niste se prijavili ni na jedan kurs. Pogledajte sta ima novo kod
                nas
                <Link to='/kursevi'>Klikni ovde</Link>
              </Message>
            ) : (
              <Grid container direction='column'>
                {cartItems.map((item) => (
                  <Grid
                    container
                    key={item.kurs}
                    direction='row'
                    // spacing={1}
                    className={classes.gridCardLeftContainer}
                  >
                    <Grid
                      container
                      key={item.kurs}
                      direction='row'
                      spacing={2}
                      className={classes.gridCardLeftContainerBox}
                    >
                      <Grid item>
                        <Card className={classes.mediaCard}>
                          <CardMedia
                            component='img'
                            image={item.image}
                            className={classes.media}
                          />
                        </Card>
                      </Grid>
                      <Grid item className={classes.itemBox}>
                        <Link to={`/kurs/${item.kurs}`}>{item.name}</Link>
                        <Grid item className={classes.itemCategory}>
                          {item.category}
                        </Grid>
                        <Grid item>
                          <FormControl
                            as='select'
                            value={item.qty}
                            onChange={(e) =>
                              dispatch(
                                addToCart(item.kurs, Number(e.target.value))
                              )
                            }
                          ></FormControl>
                        </Grid>
                        <Grid item>
                          <Button
                            onClick={() => removeFromCartHandler(item.kurs)}
                            variant='outlined'
                            size='medium'
                            color='secondary'
                            className={classes.button}
                          >
                            Obrisi
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid item className={classes.itemPrice}>
                        <Typography>
                          <p fontWeight='fontWeightBold'>KM {item.price}</p>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
        </Grid>
        <Grid item className={classes.gridCardRight}>
          <Grid container>
            <Grid item>
              <Box>
                <p>ADMINISTRACIJA</p>
              </Box>
              <Grid item>
                <Box>
                  <h3>
                    Ukupno ({cartItems.reduce((acc, item) => acc + item.qty, 0)}
                    ) odabrane edukacije
                  </h3>
                  <h4>
                    KM
                    {cartItems
                      .reduce((acc, item) => acc + item.qty * item.price, 0)
                      .toFixed(2)}
                  </h4>
                </Box>
                <Box>
                  <Button
                    // type='button'
                    className={classes.prijavaButton}
                    disabled={cartItems.length === 0}
                    onClick={checkoutHandler}
                  >
                    Nastavite sa prijavom
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <h5>Nasi popularni kursevi</h5>
            </Box>
            <KursTopTri />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default CartScreen
