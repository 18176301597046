import React, { useState, useRef } from 'react'
import { useSpring, animated, IParallax } from 'react-spring'
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { Link } from 'react-router-dom'
//Components
import VideoModal from '../../components/UI/VideoModalOblasti'
import web from '../../images/web.jpg'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
//CSS
import { makeStyles, Box, CardMedia, Grid, Divider } from '@material-ui/core'
import theme from '../../components/UI/Theme'

const useStyles = makeStyles({
  parallaxGrid: { minWidth: '100%' },
  parallaxStyle: { width: '100%' },
  //FIRST PARALAX LAYER
  coverParallaxLayer: { backgroundColor: '#2c3d46', width: '100%' },
  coverGrid: {
    width: '100%',
    height: '100%',
    backgroundColor: '#2c3d46',
  },
  coverBox: {
    width: '100%',
    height: '65%',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 140,
    [theme.breakpoints.down('md')]: {
      marginLeft: 50,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  box: {
    display: 'flex',
    height: 50,
    marginTop: 80,
    marginBottom: 0,
    verticalAlign: 'center',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginBottom: 0,
    },
  },
  coverArrow: { height: 50, verticalAlign: 'center' },
  coverArrow1: { height: 50, paddingTop: 15 },

  coverTitle: {
    marginTop: 60,
    ...theme.titleSansSerif,
    marginBottom: 10,
    color: '#ffffff',
    fontSize: '3rem',
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: '2.0rem',
      fontWeight: 500,
      marginBottom: 100,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8rem',
      fontWeight: 500,
      marginBottom: 80,
      paddingLeft: 20,
    },
  },
  coverSubTitle: {
    ...theme.subtitleSansSerif,
    marginBottom: 100,
    color: '#ffffff',
    fontSize: '0.9rem',
    fontWeight: 400,
    letterSpacing: 4,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
      marginBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: 500,
      marginBottom: 0,
      paddingLeft: 20,
    },
  },
  divider: { background: '#ffffff' },
  coverMenu: { width: '100%', minHeight: '30%' },

  coverMenuBox: {
    width: 800,
    height: 80,
    marginTop: 0,
    marginLeft: 140,
    [theme.breakpoints.down('xl')]: { marginTop: 0 },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 50,
    },
    [theme.breakpoints.down('xs')]: { display: 'none' },
  },
  coverMenuBoxItem: {
    width: 220,
    height: 'auto',
    padding: 10,
    verticalAlign: 'center',
  },
  coverMenuBoxItemText: {
    ...theme.subtitleSansSerif,
    marginTop: 0,
    width: 800,
    color: '#ffffff',
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: 2,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      fontWeight: 400,
      marginBottom: 10,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      fontSize: '1rem',
      fontWeight: 500,
      marginBottom: 0,
      paddingLeft: 20,
    },
  },
  coverMenuBoxItemTextPargraf: {
    ...theme.subtitleSansSerif,
    height: 'auto',
    color: '#fff111',
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: 2,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
    },
    [theme.breakpoints.down('sm')]: { fontWeight: 500, paddingLeft: 20 },
  },
  coverMenuSubBox: {
    width: '100%',
    height: 160,
    marginLeft: 140,
    paddingTop: 20,
    [theme.breakpoints.down('xl')]: { marginTop: 0 },
    [theme.breakpoints.down('md')]: { width: '100%', marginLeft: 50 },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 40,
      marginLeft: 0,
    },
  },
  coverMenuSubBoxItem: { height: 20, marginBottom: 0 },

  //PRVI LAYER
  prviGrid: {
    width: '100%',
    height: '100%',
    backgroundColor: '#000000',
  },

  prviParallaxGrid: { width: '100%', height: '100%' },
  prviParallaxLayer: { background: 'none', width: '100%' },

  prviGridLeft: {
    width: '50%',
    height: '100%',
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 140,
    [theme.breakpoints.down('md')]: { width: '100%', paddingLeft: 50 },
    [theme.breakpoints.down('xs')]: { paddingLeft: 0 },
  },
  prviSubGrid: {
    height: '60%',
    [theme.breakpoints.down('md')]: { height: '30%' },
    [theme.breakpoints.down('xs')]: { width: '100%', paddingLeft: 0 },
  },
  drugiSubGrid: { height: '40%' },
  prviTitle: {
    ...theme.titleSansSerif,
    width: 420,
    marginTop: 60,
    marginBottom: 10,
    color: '#fdba45',
    fontSize: '2.4rem',
    fontWeight: '400',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.0rem',
      fontWeight: 500,
      marginBottom: 100,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 240,
      marginBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: '1.3rem',
      fontWeight: 400,
    },
  },
  divider: { background: '#ffffff' },
  prviSubContainer: {
    width: '50%',
    padding: 20,
    paddingLeft: 0,
    [theme.breakpoints.down('md')]: { width: '100%' },
    [theme.breakpoints.down('xs')]: { paddingBottom: 0 },
  },
  prviSubContainerTitle: {
    ...theme.subtitleSansSerif,
    height: 80,
    marginBottom: 0,
    color: '#ffffff',
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: 3,
    [theme.breakpoints.down('md')]: { fontSize: '1rem', marginBottom: 10 },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginBottom: 10,
      paddingLeft: 20,
      fontSize: '1rem',
      fontWeight: 500,
      letterSpacing: 4,
    },
  },
  prviSubContainerSubTitle: {
    ...theme.subtitleSansSerif,
    marginBottom: 0,
    color: '#E0E1E1',
    fontSize: '1.1rem',
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      fontWeight: 400,
      marginBottom: 10,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
      fontWeight: 500,
      marginBottom: 0,
      paddingLeft: 20,
    },
  },

  //DESNI DIO
  prviGridRight: {
    width: '50%',
    height: 'auto',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('md')]: { width: '100%' },
  },
  prviGridRightBox: { width: '100%', height: '100%' },
  media: { position: 'absolute' },

  drugiContainer: { height: 'auto' },
  drugiGridLeft: { width: '50%' },
  drugiGridRight: { width: '50%' },
  // TRECI LAYER
  drugiGrid: { width: '100%', minHeight: '100%', backgroundColor: '#2c3d46' },
})

const TehnologijePregledComponent = () => {
  const classes = useStyles()
  //const parallax = useRef(IParallax) ide ref={parallax}

  return (
    <Grid container direction='column'>
      <Grid item className={classes.parallaxGrid}>
        <Parallax pages={4} className={classes.parallaxStyle}>
          <ParallaxLayer
            className={classes.coverParallaxLayer}
            offset={0}
            speed={0}
            style={{ width: '100%' }}
          >
            <Grid container direction='column' className={classes.coverGrid}>
              <Grid container direction='column' className={classes.coverBox}>
                <Box>
                  <Link to='/admin/kurslist'>
                    <div className={classes.box}>
                      <Box component='div' display='inline'>
                        <KeyboardArrowLeftIcon className={classes.coverArrow} />
                      </Box>
                      <Box
                        component='div'
                        display='inline'
                        className={classes.coverArrow1}
                      >
                        AITI OBLASTI
                      </Box>
                    </div>
                  </Link>
                </Box>
                <Grid item>
                  <h3 className={classes.coverTitle}>
                    Upoznajte se sa tehnologijama
                  </h3>
                  <h3 className={classes.coverSubTitle}>
                    ZA DIGITALNU TRANSFORMACIJU
                  </h3>
                </Grid>
                <VideoModal />
              </Grid>
              <Divider className={classes.divider} />
              <Grid item className={classes.coverMenu}>
                <Grid container direction='column'>
                  <Grid item className={classes.coverMenuBox}>
                    <Grid container>
                      <Grid item className={classes.coverMenuBoxItem}>
                        <p className={classes.coverMenuBoxItemTextPargraf}>
                          1. Korak
                        </p>
                        <h4 className={classes.coverMenuBoxItemText}>
                          Korisnicko iskustvo
                        </h4>
                      </Grid>

                      <Grid item className={classes.coverMenuBoxItem}>
                        <p className={classes.coverMenuBoxItemTextPargraf}>
                          2. Korak
                        </p>
                        <h4 className={classes.coverMenuBoxItemText}>
                          Upravljanje resursima
                        </h4>
                      </Grid>
                      <Grid item className={classes.coverMenuBoxItem}>
                        <p className={classes.coverMenuBoxItemTextPargraf}>
                          3. Korak
                        </p>
                        <h4 className={classes.coverMenuBoxItemText}>
                          Napredna proizvodnja
                        </h4>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  <Grid item className={classes.coverMenuSubBox}>
                    <h3 className={classes.coverMenuBoxItemTextPargraf}>
                      Set tehnologija sa kojima mozete ubrzati svoj biznis
                    </h3>

                    <p className={classes.coverMenuBoxItemText}>
                      Razvijte poslovnu strategiju zasnovanu na primjeni
                      naprednih tehnologija i integrisanoj primjeni digitalnih
                      tehnologija. Mi vam mozemo pomoci da usvojite agilni
                      pristup razvoju instalaciji digitalne infrastrukture i
                      digitalnog ekosistema.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ParallaxLayer>
          <ParallaxLayer
            offset={1}
            speed={0.5}
            className={classes.prviParallaxLayer}
          >
            <Grid container className={classes.prviGrid}>
              <Grid item className={classes.prviParallaxGrid}>
                <Grid container direction='row-reverse'>
                  <Grid sm item className={classes.prviGridRight}>
                    <Grid container className={classes.prviGridRightBox}>
                      <CardMedia
                        component='img'
                        className={classes.media}
                        image={web}
                      />
                    </Grid>
                  </Grid>
                  <Grid sm item className={classes.prviGridLeft}>
                    <Grid container direction='column'>
                      <Grid item className={classes.prviSubGrid}>
                        <h3 className={classes.prviTitle}>
                          Sa nama dizajnirajnje odlicnog korisnickog iskustva
                          postaje puno lakse
                        </h3>
                      </Grid>
                      <Grid item className={classes.drugiSubGrid}>
                        <Grid container>
                          <Grid item className={classes.prviSubContainer}>
                            <h3 className={classes.prviSubContainerTitle}>
                              KREATIVNOST ZA SVE
                            </h3>
                            <p className={classes.prviSubContainerSubTitle}>
                              Dizajn i razvoj korisnickog sadrzaja za digitalni
                              marketing i razvoj Web, mobilnih i desktop
                              aplikacija
                            </p>
                          </Grid>
                          <Grid item className={classes.prviSubContainer}>
                            <h3 className={classes.prviSubContainerTitle}>
                              BUDUCNOST U POVEZANOSTI
                            </h3>
                            <p className={classes.prviSubContainerSubTitle}>
                              Kokreacija monilitnih i 'headless' aplikacija
                              koristenjem MERN/MEAN ili .NET seta tehnologija
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ParallaxLayer>
          <ParallaxLayer
            offset={2}
            speed={0}
            className={classes.prviParallaxLayer}
          >
            <Grid container className={classes.drugiGrid}>
              <Grid item className={classes.prviParallaxGrid}>
                <Grid container direction='row-reverse'>
                  <Grid sm item className={classes.prviGridRight}>
                    <Grid container className={classes.prviGridRightBox}>
                      <CardMedia
                        component='img'
                        className={classes.media}
                        image={web}
                      />
                    </Grid>
                  </Grid>
                  <Grid sm item className={classes.prviGridLeft}>
                    <Grid container direction='column'>
                      <Grid item className={classes.prviSubGrid}>
                        <h3 className={classes.prviTitle}>
                          Mi vam mozemo olaksati transformaciju sistema
                          upravljaja resursima (ERP)
                        </h3>
                      </Grid>
                      <Grid item className={classes.drugiSubGrid}>
                        <Grid container>
                          <Grid item className={classes.prviSubContainer}>
                            <h3 className={classes.prviSubContainerTitle}>
                              END-TO-END INTEGRACIJA
                            </h3>
                            <p className={classes.prviSubContainerSubTitle}>
                              Brzi razvoj kostimiziranih aplikacija koje mogu
                              obaviti bilo koji zadatak za rjesavanje vasih
                              poslovnih problema sa Claris FileMaker
                            </p>
                          </Grid>
                          <Grid item className={classes.prviSubContainer}>
                            <h3 className={classes.prviSubContainerTitle}>
                              RAZMISLI BRZO
                            </h3>
                            <p className={classes.prviSubContainerSubTitle}>
                              Unaprijedjenje poslovnih operacija i
                              prilagodjavanje poslovnih modela digitalnom dobu
                              razvojem i infrastrukture za digitalnu trgovinu
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ParallaxLayer>
          <ParallaxLayer
            offset={3}
            speed={0.5}
            className={classes.prviParallaxLayer}
          >
            <Grid container className={classes.prviGrid}>
              <Grid item className={classes.prviParallaxGrid}>
                <Grid container direction='row-reverse'>
                  <Grid sm item className={classes.prviGridRight}>
                    <Grid container className={classes.prviGridRightBox}>
                      <CardMedia
                        component='img'
                        className={classes.media}
                        image={web}
                      />
                    </Grid>
                  </Grid>
                  <Grid sm item className={classes.prviGridLeft}>
                    <Grid container direction='column'>
                      <Grid item className={classes.prviSubGrid}>
                        <h3 className={classes.prviTitle}>
                          Poslovni procesi sa integrisanom vjestackom
                          inteligencijom (AI)
                        </h3>
                      </Grid>
                      <Grid item className={classes.drugiSubGrid}>
                        <Grid container>
                          <Grid item className={classes.prviSubContainer}>
                            <h3 className={classes.prviSubContainerTitle}>
                              Industrija 4.0 (IIoT)
                            </h3>
                            <p className={classes.prviSubContainerSubTitle}>
                              Industije 4.0 (IIoT) drzi kljuc za drasticno
                              smanjenje vremena u poslovnim procesima i i
                              povecanje opcija za razvoj novih poslovnih modela
                            </p>
                          </Grid>
                          <Grid item className={classes.prviSubContainer}>
                            <h3 className={classes.prviSubContainerTitle}>
                              OTVORITE NOVE MOGUCNOSTI
                            </h3>
                            <p className={classes.prviSubContainerSubTitle}>
                              Unaprijedite faktore performansi, smanjite vrijeme
                              u vasim procesima, povecajte fleksibilnost,
                              unaprijedite kvalitetu i produktivnost sa
                              automatizacijom procesa koristenjem Machine
                              leartning (AI) tehnologija
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ParallaxLayer>
        </Parallax>
      </Grid>
    </Grid>
  )
}

export default TehnologijePregledComponent
