import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAdminOrderDetails,
  updateAdminOrder,
} from '../../actions/orderActions'
import { ORDER_UPDATE_RESET } from '../../constants/orderConstants'
//Components
import Message from '../../components/UI/Message'
import Loader from '../../components/UI/Loader'
import FormContainer from '../../components/OBUKE/FormContainer'
//Styles
import { Form } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import theme from '../../components/UI/Theme'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormControl,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    ...theme.paperMain,
    height: 'auto',
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 0,
    },
  },
  paper_bottom: {
    ...theme.paperMain,
    height: 'auto',
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 0,
    },
  },
  gridCardLeft: {
    width: '60%',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  gridCardRight: {
    width: '37%',
    marginLeft: '3%',
    padding: 20,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 10,
    },
  },
  box: {
    display: 'flex',
    verticalAlign: 'center',
    height: 50,
    marginTop: 80,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginBottom: 60,
    },
  },
  coverArrow: { height: 50, verticalAlign: 'center' },
  coverArrow1: { height: 50, paddingTop: 15 },

  coverBox: {
    height: 150,
    [theme.breakpoints.down('xs')]: {
      height: 250,
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    marginTop: 20,
    letterSpacing: '2px',
    fontSize: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 20,
      fontSize: '2rem',
    },
  },
  mainSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 0,
    marginBottom: 20,
    fontFamily: 'PT Sans',
    fontSize: '1.4rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      height: '2rem',
      paddingBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      height: 50,
      margin: 0,
      paddingLeft: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },

  prijavaButton: {
    ...theme.prijavaButton,
    marginTop: 50,
    marginBottom: 50,
  },
  boxForm: {
    marginTop: 20,
  },
  mainFormGroup: {
    marginTop: 20,
    marginBottom: 20,
  },
})

const OrderEditScreen = ({ match, history }) => {
  const classes = useStyles()
  const orderId = match.params.id
  const [paymentResult, setPaymentResult] = useState('')
  const [isPaid, setIsPaid] = useState('')
  const [paidAt, setPaidAt] = useState('')
  const [isDelivered, setIsDelivered] = useState('')
  const [status, setStatus] = useState('')

  const dispatch = useDispatch()

  const orderAdminDetails = useSelector((state) => state.orderAdminDetails)
  const { order, loading, error } = orderAdminDetails

  const orderAdminUpdate = useSelector((state) => state.orderAdminUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    order: orderSuccess,
  } = orderAdminUpdate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    // if (!userInfo) {
    //   history.push('/login')
    if (successUpdate) {
      dispatch({ type: ORDER_UPDATE_RESET })
      history.push('/admin/orderlist')
    } else {
      if (order._id !== orderId) {
        dispatch(getAdminOrderDetails(orderId))
      } else {
        setIsPaid(order.isPaid)
        setPaidAt(order.paidAt)
        setStatus(order.status)
        setIsDelivered(order.isDelivered)
      }
    }
  }, [dispatch, history, orderId, successUpdate, orderSuccess])

  //kontroleri
  const handleChangeIsPaid = (e) => {
    setIsPaid(e.target.value)
  }
  const handleChangeStatus = (e) => {
    setStatus(e.target.value)
  }
  const handleChangeIsDelivered = (e) => {
    setStatus(e.target.value)
  }
  //kontroleri

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateAdminOrder({
        _id: orderId,
        paymentResult,
        isPaid,
        paidAt,
        isDelivered,
        status,
      })
    )
  }

  return (
    <div className={classes.main}>
      <Paper className={classes.paper_top}>
        <Box>
          <Link to='/admin/orderlist'>
            <div className={classes.box}>
              <Box component='div' display='inline'>
                <KeyboardArrowLeftIcon className={classes.coverArrow} />
              </Box>
              <Box
                component='div'
                display='inline'
                className={classes.coverArrow1}
              >
                NAZAD NA SVE PRIJAVE NA KURSEVE
              </Box>
            </div>
          </Link>
        </Box>
        <Grid container justify='space-between' className={classes.coverBox}>
          <Box className={classes.titleBox}>
            <h3 className={classes.mainTitle}>
              ADMINISTRACIJA PRIJAVA NA KURSEVE
            </h3>
            <p className={classes.mainSubTitle}>Azurisanje statusa prijave</p>
          </Box>
        </Grid>
        <Divider />
      </Paper>
      <Paper className={classes.paper_bottom}>
        <FormContainer>
          <h1>KURS:</h1>
          <h5>{order.isPaid}</h5>
          <h1>Polaznik</h1>
          <h3>staviti ime polaznika</h3>
          <Divider />
          {loadingUpdate && <Loader />}
          {errorUpdate && <Message variant='denger'>{errorUpdate}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Form onSubmit={submitHandler}>
              <FormGroup controlId='isPaid' className={classes.mainFormGroup}>
                <FormLabel as='legend'>Status placanja</FormLabel>
                <RadioGroup value={isPaid} onChange={handleChangeIsPaid}>
                  <FormControlLabel
                    control={<Radio />}
                    label='Nije placeno'
                    value='Nije placeno'
                    onChange={(e) => setIsPaid(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Placeno, primljena uplata'
                    value='Placeno'
                    onChange={(e) => setIsPaid(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Placeno, grant'
                    value='Polazniku je odobren grant za placanje troskova edukacije'
                    onChange={(e) => setIsPaid(e.target.value)}
                  ></FormControlLabel>
                </RadioGroup>
              </FormGroup>
              <Divider />
              <FormGroup controlId='paidAt' className={classes.mainFormGroup}>
                <FormLabel>Datum placanja</FormLabel>
                <FormControl
                  type='text'
                  value={paidAt}
                  onChange={(e) => setPaidAt(e.target.value)}
                >
                  <TextField
                    id='outlined-basic'
                    label='Datum uplate'
                    variant='outlined'
                    value={paidAt}
                  />
                </FormControl>
              </FormGroup>
              <Divider />
              <FormGroup controlId='status' className={classes.mainFormGroup}>
                <FormLabel as='legend'>Status prijave</FormLabel>
                <RadioGroup value={status} onChange={handleChangeStatus}>
                  <FormControlLabel
                    control={<Radio />}
                    label='Vasa prijava je u procesu administracije'
                    value='Vasa prijava je u procesu administracije'
                    onChange={(e) => setStatus(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Prijava se nalazi u proceduri'
                    value='Prijava se nalazi u proceduri'
                    onChange={(e) => setStatus(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Polaznik odabran u trening program'
                    value='Polaznik odabran u trening program'
                    onChange={(e) => setStatus(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Polaznik nije primljeno'
                    value='Niste primljeni u trening program'
                    onChange={(e) => setStatus(e.target.value)}
                  ></FormControlLabel>
                </RadioGroup>
              </FormGroup>
              <Divider />
              <FormGroup controlId='status' className={classes.mainFormGroup}>
                <FormLabel as='legend'>Obavjestenje korisniku</FormLabel>
                <RadioGroup
                  value={isDelivered}
                  onChange={handleChangeIsDelivered}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label='Korisnik nije obavjesten'
                    value='Korisnik nije obavjesten'
                    onChange={(e) => setIsDelivered(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Korisnik obavjesten'
                    value='Korisnik obavjesten'
                    onChange={(e) => setIsDelivered(e.target.value)}
                  ></FormControlLabel>
                </RadioGroup>
              </FormGroup>
              <Divider />
              <Button
                type='submit'
                variant='primary'
                className={classes.prijavaButton}
              >
                Azuriraj
              </Button>
            </Form>
          )}
        </FormContainer>
      </Paper>
    </div>
  )
}

export default OrderEditScreen
