import axios from 'axios'
import {
  KURS_LIST_REQUEST,
  KURS_LIST_SUCCESS,
  KURS_LIST_FAIL,
  KURS_DETAILS_REQUEST,
  KURS_DETAILS_SUCCESS,
  KURS_DETAILS_FAIL,
  KURS_DELETE_REQUEST,
  KURS_DELETE_SUCCESS,
  KURS_DELETE_FAIL,
  KURS_CREATE_REQUEST,
  KURS_CREATE_SUCCESS,
  KURS_CREATE_FAIL,
  KURS_UPDATE_REQUEST,
  KURS_UPDATE_SUCCESS,
  KURS_UPDATE_FAIL,
  KURS_CREATE_REVIEW_REQUEST,
  KURS_CREATE_REVIEW_SUCCESS,
  KURS_CREATE_REVIEW_FAIL,
  KURS_TOP_REQUEST,
  KURS_TOP_SUCCESS,
  KURS_TOP_FAIL,
  KURS_ONE_REQUEST,
  KURS_ONE_SUCCESS,
  KURS_ONE_FAIL,
} from '../constants/kursConstants'

export const listKursevi = (keyword = '', pageNumber = '') => async (
  dispatch
) => {
  try {
    dispatch({ type: KURS_LIST_REQUEST })

    const { data } = await axios.get(
      `/api/kursevi?keyword=${keyword}&pageNumber=${pageNumber}`
    )

    dispatch({
      type: KURS_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: KURS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listKursDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: KURS_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/kursevi/${id}`)

    dispatch({
      type: KURS_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: KURS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteKurs = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: KURS_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/kursevi/${id}`, config)

    dispatch({
      type: KURS_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: KURS_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createKurs = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: KURS_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/kursevi`, {}, config)

    dispatch({
      type: KURS_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: KURS_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateKurs = (kurs) => async (dispatch, getState) => {
  try {
    dispatch({
      type: KURS_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/kursevi/${kurs._id}`, kurs, config)

    dispatch({
      type: KURS_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: KURS_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createKursReview = (kursId, review) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: KURS_CREATE_REVIEW_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.post(`/api/kursevi/${kursId}/reviews`, review, config)

    dispatch({
      type: KURS_CREATE_REVIEW_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: KURS_CREATE_REVIEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listTopKursevi = () => async (dispatch) => {
  try {
    dispatch({ type: KURS_TOP_REQUEST })

    const { data } = await axios.get(`/api/kursevi/top`)

    dispatch({
      type: KURS_TOP_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: KURS_TOP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listOneKurs = () => async (dispatch) => {
  try {
    dispatch({ type: KURS_ONE_REQUEST })

    const { data } = await axios.get(`/api/kursevi/one`)

    dispatch({
      type: KURS_ONE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: KURS_ONE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
