import React, { useState } from 'react'
import { Link } from 'react-router-dom'
//Components

//CSS
import { makeStyles, Box, Grid, Paper, Divider } from '@material-ui/core'
import theme from '../../components/UI/Theme'
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    width: 400,
    marginRight: 20,
    marginBottom: 20,
    backgroundColor: theme.palette.common.aitiGreyIII,
    transition: 'transform 0.25s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: '5%',
      backgroundColor: theme.palette.common.aitiGrey,
    },
  },
  cardHovered: {
    transform: 'scale3d(1.01, 1.01, 1)',
    backgroundColor: theme.palette.common.aitiGreyIII,
  },
  mainTitle: {
    ...theme.titleSansSerif,
    height: 100,
    padding: 20,
    backgroundColor: theme.palette.common.aitiGreyIV,
    lineHeight: '2.2rem',
    fontSize: '1.7rem',
    fontWeight: 300,
  },
  prviText: {
    ...theme.titleSansSerif,
    height: 30,
    paddingLeft: 20,
    paddingTop: 10,
    fontWeight: 600,
    fontSize: '0.8rem',
  },
  description: {
    paddingLeft: 20,
    fontSize: '0.9rem',
    fontWeight: 300,
  },
  button: {
    ...theme.button,
  },
})

const UslugePregledComponent = () => {
  const classes = useStyles()

  const [state, setState] = useState({
    raised: false,
    shadow: 1,
  })

  return (
    <Grid container direction='row'>
      <Grid
        item
        className={classes.root}
        classes={{ root: state.raised ? classes.cardHovered : '' }}
        onMouseOver={() => setState({ raised: true, shadow: 3 })}
        onMouseOut={() => setState({ raised: false, shadow: 1 })}
        raised={state.raised}
        zdepth={state.shadow}
      >
        <Box className={classes.mainTitle}>Stratesko dizajniranje</Box>
        <Box className={classes.prviText}>SEKTORI</Box>
        <Box className={classes.description}>Javni sektor</Box>
        <Box className={classes.prviText}>KRATKA DESKRIPCIJA</Box>
        <Box className={classes.description}>
          Dizajniranje, planiranje, organizovanje i zagovaranje javnih
          socio-ekonomskih politika u sektorima obrazovanja, zaposljavanja,
          turizma, mobilnosti
        </Box>
        <Box className={classes.prviText}>DOSTUPNOST</Box>
        <Box className={classes.description}>
          U skladu sa ugovorom, projektom ili Sporazumom o razumjevanju (MoU)
        </Box>
      </Grid>
      <Grid
        item
        className={classes.root}
        classes={{ root: state.raised ? classes.cardHovered : '' }}
        onMouseOver={() => setState({ raised: true, shadow: 3 })}
        onMouseOut={() => setState({ raised: false, shadow: 1 })}
        raised={state.raised}
        zdepth={state.shadow}
      >
        <Box className={classes.mainTitle}>
          Istrazivanje i eksperimentalni razvoj
        </Box>
        <Box className={classes.prviText}>SEKTORI</Box>
        <Box className={classes.description}>Privatni sektor, Javni sektor</Box>
        <Box className={classes.prviText}>KRATKA DESKRIPCIJA</Box>
        <Box className={classes.description}>
          Istrazivanje i eksperimentlni razvoj u digitalnim tehnologijama,
          proizvodnji, povezanosti i urbanoj mobilnosti, hrani i sirovinama
        </Box>
        <Box className={classes.prviText}>DOSTUPNOST</Box>
        <Box className={classes.description}>Potrebno kontaktirati</Box>
      </Grid>
      <Grid
        item
        className={classes.root}
        classes={{ root: state.raised ? classes.cardHovered : '' }}
        onMouseOver={() => setState({ raised: true, shadow: 3 })}
        onMouseOut={() => setState({ raised: false, shadow: 1 })}
        raised={state.raised}
        zdepth={state.shadow}
      >
        <Box className={classes.mainTitle}>Analize</Box>
        <Box className={classes.prviText}>SEKTORI</Box>
        <Box className={classes.description}>Javni sektor</Box>
        <Box className={classes.prviText}>KRATKA DESKRIPCIJA</Box>
        <Box className={classes.description}>
          Izrada strucnih analiza, provodjenje ekspertiza iz podrucja
          drustvenih, humanistickih i tehnickih nauka, informacijskih
          tehnologija i interdisciplinarnih podrucja nauke
        </Box>
        <Box className={classes.prviText}>DOSTUPNOST</Box>
        <Box className={classes.description}>
          U skladu sa Sporazumom o razumjevanju (MoU)
        </Box>
      </Grid>
      <Grid
        item
        className={classes.root}
        classes={{ root: state.raised ? classes.cardHovered : '' }}
        onMouseOver={() => setState({ raised: true, shadow: 3 })}
        onMouseOut={() => setState({ raised: false, shadow: 1 })}
        raised={state.raised}
        zdepth={state.shadow}
      >
        <Box className={classes.mainTitle}>Razvoj resursa i tehnologija</Box>
        <Box className={classes.prviText}>SEKTORI</Box>
        <Box className={classes.description}>Industrija</Box>
        <Box className={classes.prviText}>KRATKA DESKRIPCIJA</Box>
        <Box className={classes.description}>
          Saradnja sa industrijom u oblasti primjene novih tehnologija i
          obrazovanja radne snage
        </Box>
        <Box className={classes.prviText}>DOSTUPNOST</Box>
        <Box className={classes.description}>
          U skladu sa ugovorom, projektom ili Sporazumom o razumjevanju (MoU)
        </Box>
      </Grid>
      <Grid
        item
        className={classes.root}
        classes={{ root: state.raised ? classes.cardHovered : '' }}
        onMouseOver={() => setState({ raised: true, shadow: 3 })}
        onMouseOut={() => setState({ raised: false, shadow: 1 })}
        raised={state.raised}
        zdepth={state.shadow}
      >
        <Box className={classes.mainTitle}>Kolaboracija u inovacijama</Box>
        <Box className={classes.prviText}>SEKTORI</Box>
        <Box className={classes.description}>Privatni sektor, javni sektor</Box>
        <Box className={classes.prviText}>KRATKA DESKRIPCIJA</Box>
        <Box className={classes.description}>
          Pruzanje podrske inovacijama koje obezbjedjuju konstantan tok novih
          proizvoda, usluga, procesa i poslovnih modela
        </Box>
        <Box className={classes.prviText}>DOSTUPNOST</Box>
        <Box className={classes.description}>
          U skladu sa ugovorom ili Sporazumom o razumjevanju (MoU)
        </Box>
      </Grid>
      <Grid
        item
        className={classes.root}
        classes={{ root: state.raised ? classes.cardHovered : '' }}
        onMouseOver={() => setState({ raised: true, shadow: 3 })}
        onMouseOut={() => setState({ raised: false, shadow: 1 })}
        raised={state.raised}
        zdepth={state.shadow}
      >
        <Box className={classes.mainTitle}>Istrazivanja</Box>
        <Box className={classes.prviText}>SEKTORI</Box>
        <Box className={classes.description}>Javni sektor, privatni sektor</Box>
        <Box className={classes.prviText}>KRATKA DESKRIPCIJA</Box>
        <Box className={classes.description}>
          Organizacija, upravljanje i provodjenje socio-ekonomskih i poslovno
          orjentisanih istrazivanja
        </Box>
        <Box className={classes.prviText}>DOSTUPNOST</Box>
        <Box className={classes.description}>Potrebno kontaktirati</Box>
      </Grid>
      <Grid
        item
        className={classes.root}
        classes={{ root: state.raised ? classes.cardHovered : '' }}
        onMouseOver={() => setState({ raised: true, shadow: 3 })}
        onMouseOut={() => setState({ raised: false, shadow: 1 })}
        raised={state.raised}
        zdepth={state.shadow}
      >
        <Box className={classes.mainTitle}>Javne politike</Box>
        <Box className={classes.prviText}>SEKTORI</Box>
        <Box className={classes.description}>Javni sektor</Box>
        <Box className={classes.prviText}>KRATKA DESKRIPCIJA</Box>
        <Box className={classes.description}>
          Izrada nacrta politika, strategija, akcionih planova i srodnih
          doukumenata iz socio-ekonomske domene
        </Box>
        <Box className={classes.prviText}>DOSTUPNOST</Box>
        <Box className={classes.description}>
          U skladu sa ugovorom, projektom ili Sporazumom o razumjevanju (MoU)
        </Box>
      </Grid>
      <Grid
        item
        className={classes.root}
        classes={{ root: state.raised ? classes.cardHovered : '' }}
        onMouseOver={() => setState({ raised: true, shadow: 3 })}
        onMouseOut={() => setState({ raised: false, shadow: 1 })}
        raised={state.raised}
        zdepth={state.shadow}
      >
        <Box className={classes.mainTitle}>Ekspertize</Box>
        <Box className={classes.prviText}>SEKTORI</Box>
        <Box className={classes.description}>Javni sektor, privatni sektor</Box>
        <Box className={classes.prviText}>KRATKA DESKRIPCIJA</Box>
        <Box className={classes.description}>
          Priprema i izrada strucnih studija u oblasti obrazovanja odraslih,
          strucnog obrazovanja radne snage, digitalnih tehnologija, inovacija,
          rada i zaposljavanja
        </Box>
        <Box className={classes.prviText}>DOSTUPNOST</Box>
        <Box className={classes.description}>
          U skladu sa ugovorom, projektom ili Sporazumom o razumjevanju (MoU)
        </Box>
      </Grid>
      <Grid
        item
        className={classes.root}
        classes={{ root: state.raised ? classes.cardHovered : '' }}
        onMouseOver={() => setState({ raised: true, shadow: 3 })}
        onMouseOut={() => setState({ raised: false, shadow: 1 })}
        raised={state.raised}
        zdepth={state.shadow}
      >
        <Box className={classes.mainTitle}>Poslovni razvoj</Box>
        <Box className={classes.prviText}>SEKTORI</Box>
        <Box className={classes.description}>Privatni sektor</Box>
        <Box className={classes.prviText}>KRATKA DESKRIPCIJA</Box>
        <Box className={classes.description}>
          Pruzanje konsultantskih usluga u oblasti razvoja poslovanja i
          digitalne transformacije
        </Box>
        <Box className={classes.prviText}>DOSTUPNOST</Box>
        <Box className={classes.description}>Potrebno kontaktirati</Box>
      </Grid>
    </Grid>
  )
}

export default UslugePregledComponent
