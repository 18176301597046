import React, { useState, useEffect } from 'react'
import { Route, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
//COMPONENTS
import SearchBox from './SearchBoxNOVO'
import { logout } from '../actions/userActions'
import ScrollColor from './UI/Header/ScrollColor'
import { Divide as Hamburger } from 'hamburger-react'
//MATERIAL UI
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Tabs, Grid, Tab } from '@material-ui/core'
// import Tab from '@material-ui/core/Tab'
// import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import AccountCircle from '@material-ui/icons/PersonOutline'
import { CssBaseline, Box, Divider } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: { maxWidth: '100%', height: 'auto' },
  appbar: {
    zIndex: theme.zIndex.modal + 1,
  },
  toolbar: {
    height: '100%',
    width: '100%',
    verticalAlign: 'center',
  },
  drawerIconContainer: {
    height: 'auto',
    marginRight: '0px',
    verticalAlign: 'center',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  drawerIcon: { height: 30, width: 30 },
  aiti_logo: {
    fontSize: '1.2rem',
    paddingLeft: '10px',
    marginRight: '100px',
    verticalAlign: 'center',
    //color: '#fff',
    [theme.breakpoints.down('md')]: {
      marginRight: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  toolBarMargin: {
    visibility: 'none',
  },

  // Navigacija "tabs"

  bigIndicator: { height: 5 },
  tabContainer: {
    width: 800,
    marginRight: 'auto',
    display: 'inline-block',
    verticalAlign: 'center',
  },
  tabs: {
    //...theme.typography.tab,
    textTransform: 'none',
    //color: '#000',
    minWidth: 50,
    marginLeft: 50,
    padding: 0,
    opacity: 1,
    zIndex: 5000,
    '&:hover': {
      color: theme.palette.common.aitiBlue,
      textDecoration: 'none',
      fontWeight: '500',
    },
  },
  mainMenu: {
    width: '100%',
    height: 700,
    maxWidth: '100%',
    left: 0,
    right: 0,
    borderRadius: 0,
    opacity: 0.8,
  },
  mainMenuTab: {
    marginTop: '55px',
  },
  mainMenuTab_control: {
    marginTop: 0,
    padding: '20px',
  },
  menuItem: {
    ...theme.typography.tab,
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
  mainMenuTab_Container_title: {
    margin: 10,
    padding: 10,
  },
  mainMenuContainer: {
    marginTop: 10,
    width: '25%',
  },
  mainMenuContainerItem: {},
  mainMenuContainerTitle: {
    display: 'block',
    width: '90%',
    paddingRight: '10px',
    textTransform: 'none',
  },
  mainMenuContainerSubtitle: {
    fontWeight: '300',
    width: '90%',
    height: '3.2rem',
    paddingLeft: '1rem',
    color: '#444333',
  },
  //DRAWER MENU
  drawer: {
    width: '100%',
    //marginTop: 50,
    backgroundColor: 'transparent',
  },
  list: {
    backgroundColor: theme.palette.common.aitiBlue,
    opacity: 0.8,
  },
  listbox: {
    width: '61%',
    height: 60,
    color: '#fff',
  },
  drawerItemText: {
    ...theme.typography.tab,
    color: '#ffffff',
    fontSize: '1rem',
    fontWeight: 500,
    opacity: 1,
  },
  drawerItemTextSelected: {
    '& .MuiListItemText-root': {
      backgroundColor: theme.palette.common.aitiOrange,
      opacity: 1,
    },
  },
  searchBox: {
    width: 'auto',
    marginLeft: 'auto',
    display: 'inline-block',
  },

  profileIconButton: {
    display: 'inline',
    width: '50px',
  },
  //PROFILE MENU
  profileMenuList: {
    display: 'inline-block',
    width: 'auto',
    marginLeft: 'auto',
  },
  profileMenuListItem: { textTransform: 'none', marginLeft: 'auto' },
  profileMenuListUser: { display: 'inline', textTransform: 'none' },
  profileMenuListItemButton: {
    textTransform: 'none',
  },
  profileMenu: {
    width: '100%',
    marginRight: 'auto',
    padding: 40,
    //backgroundColor: theme.palette.common.aitiBlack,
    opacity: 1,
  },
  profileDrawer: {
    //width: 375,
    height: 'auto',
    //padding: 0,
    backgroundColor: theme.palette.common.aitiDark,
    opacity: 1,
    zIndex: 2000,
  },
  profileTitle: {
    ...theme.titleSansSerif,
    marginTop: 40,
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: '600',
    letterSpacing: 5,
    color: theme.palette.common.aitiTirkiz,
  },
  profileSubTitle: {
    ...theme.subtitleSansSerif,
    marginTop: 10,
    textAlign: 'center',
    fontSize: '1rem',
    letterSpacing: 5,
    color: '#fff',
  },
  listSubTitle: {
    ...theme.subtitleSansSerif,
    marginTop: 10,
    textAlign: 'left',
    fontSize: '1.1rem',
    letterSpacing: 2,
    color: '#fff',
    cursor: 'pointer',
  },

  profileParagraf: {
    ...theme.subtitleSansSerif,
    display: 'block',
    marginTop: -15,
    textAlign: 'left',
    fontSize: '0.9rem',
    letterSpacing: 1,
    color: theme.palette.common.aitiTirkiz,
  },

  button: {
    ...theme.loginButton,
    width: 300,
    backgroundColor: theme.palette.common.aitiTirkiz,
    margin: 40,
    color: '#000',
  },
  profileList: {
    padding: 40,
    //backgroundColor: theme.palette.common.aitiBlue,
  },
}))

const Header = () => {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openMenu, setOpenMenu] = useState(false)
  const theme = useTheme()
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  const [openDrawer, setOpenDrawer] = useState(false)
  const [openProfileDrawer, setOpenProfileDrawer] = useState(false)

  const handleTabChange = (e, newValue) => {
    setValue(newValue)
  }
  const handleMainMenuClick = (e) => {
    setAnchorEl(e.currentTarget)
    setOpenMenu(true)
  }
  const handleMainMenuClose = (e) => {
    setAnchorEl(null)
    setOpenMenu(false)
  }
  // const handleMenuItemClick = (e) => {
  //   setAnchorEl(null)
  //   setOpenMenu(false)
  // }
  //PROFILE MENU
  const [profileAnchorEl, setProfileAnchorEl] = useState(null)
  const handleProfileClick = (event) => {
    setProfileAnchorEl(event.currentTarget)
  }
  const handleProfileClose = () => {
    setProfileAnchorEl(null)
  }

  //NAVBAR COLOR TRIGGER
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })

  //USE EFFECTS
  useEffect(() => {
    if (window.location.pathname === '/' && value !== 0) {
      setValue(0)
    }
    if (window.location.pathname === '/oblasti' && value !== 1) {
      setValue(1)
    } else if (window.location.pathname === '/usluge' && value !== 2) {
      setValue(2)
    } else if (window.location.pathname === '/karijera' && value !== 3) {
      setValue(3)
    }
  }, [value])

  const tabs = (
    <React.Fragment>
      <Tabs
        classes={{ indicator: classes.bigIndicator }}
        value={value}
        className={classes.tabContainer}
        onChange={handleTabChange}
      >
        <Tab
          aria-controls='menu-istrazi'
          aria-haspopup={anchorEl ? 'true' : undefined}
          disableRipple
          className={classes.tabs}
          onClick={(event) => handleMainMenuClick(event)}
          label='Istrazi vise'
        ></Tab>
        <Tab
          disableRipple
          className={classes.tabs}
          component={Link}
          to='/oblasti'
          label='Oblasti'
        ></Tab>
        <Tab
          disableRipple
          className={classes.tabs}
          component={Link}
          to='usluge'
          label='Usluge'
        ></Tab>
        <Tab
          disableRipple
          className={classes.tabs}
          component={Link}
          to='/careers'
          label='Karijera'
        ></Tab>
      </Tabs>
      <Menu
        marginThreshold={0}
        classes={{ paper: classes.mainMenu }}
        className={classes.mainMenuTab}
        id='menu-istrazi'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMainMenuClose}
        MenuListProps={{ onMouseLeave: handleMainMenuClose }}
      >
        <div className={classes.mainMenuTab_control}>
          <div className={classes.mainMenuTab_Container_title}>
            <h1>
              AITI <span>Digital</span>
            </h1>
            <p>Napredni institut za tehnologije i inovacije</p>
          </div>
          <Grid container>
            <Grid item className={classes.mainMenuContainer}>
              <Grid container>
                <Grid item>
                  <MenuItem
                    className={classes.mainMenuContainerItem}
                    component={Link}
                    to='/vijesti'
                    onClick={() => {
                      handleMainMenuClose()
                      setValue(0)
                    }}
                    onClose={handleMainMenuClose}
                  >
                    <div>
                      <h5 className={classes.mainMenuContainerTitle}>
                        Najnovije vijesti
                      </h5>
                    </div>
                  </MenuItem>
                  <div>
                    <p className={classes.mainMenuContainerSubtitle}>
                      Upoznaj se o najnovijim desavanjima kod nas.
                    </p>
                  </div>
                  <MenuItem
                    className={classes.mainMenuContainerItem}
                    component={Link}
                    to='/oblasti'
                    onClick={() => {
                      handleMainMenuClose()
                      setValue(1)
                    }}
                    onClose={handleMainMenuClose}
                  >
                    <div>
                      <h5 className={classes.mainMenuContainerTitle}>
                        Oblasti ekspertize
                      </h5>
                    </div>
                  </MenuItem>
                  <div>
                    <p className={classes.mainMenuContainerSubtitle}>
                      Steknite uvid u kojim oblastima vam mozemo pomoci da
                      povecate rast i performanse
                    </p>
                  </div>
                  <MenuItem
                    className={classes.mainMenuContainerItem}
                    component={Link}
                    to='/vjestine'
                    onClick={() => {
                      handleMainMenuClose()
                      setValue(1)
                    }}
                    onClose={handleMainMenuClose}
                  >
                    <div>
                      <h5 className={classes.mainMenuContainerTitle}>
                        Za pojedinice
                      </h5>
                    </div>
                  </MenuItem>
                  <div>
                    <p className={classes.mainMenuContainerSubtitle}>
                      Posebno dizajniran pristup koji vam moze pomoci da
                      razvijete svoju karijeru ili da zapocnete karijeru u novoj
                      domeni rada
                    </p>
                  </div>
                  <MenuItem
                    className={classes.mainMenuContainerItem}
                    component={Link}
                    to='/usluge'
                    onClick={() => {
                      handleMainMenuClose()
                      setValue(3)
                    }}
                    onClose={handleMainMenuClose}
                  >
                    <div>
                      <h5 className={classes.mainMenuContainerTitle}>
                        Za organizacije
                      </h5>
                    </div>
                  </MenuItem>
                  <div>
                    <p className={classes.mainMenuContainerSubtitle}>
                      Domene saradnje sa organizacijama iz industije i javnog
                      sektora u oblasti prekvalifikacije i treniga radne snage
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.mainMenuContainer}>
              <Grid container>
                <Grid item>
                  <MenuItem
                    className={classes.mainMenuContainerItem}
                    component={Link}
                    to='/oblasti/tehnologije'
                    onClick={() => {
                      handleMainMenuClose()
                      setValue(2)
                    }}
                    onClose={handleMainMenuClose}
                  >
                    <div>
                      <h5 className={classes.mainMenuContainerTitle}>
                        Digitalna transformacija
                      </h5>
                    </div>
                  </MenuItem>
                  <div>
                    <p className={classes.mainMenuContainerSubtitle}>
                      Saznajte vise o setu tehnologija sa kojima mozete ubrzati
                      svoj biznis
                    </p>
                  </div>
                  <MenuItem
                    className={classes.mainMenuContainerItem}
                    component={Link}
                    to='/usluge/pregled'
                    onClick={() => {
                      handleMainMenuClose()
                      setValue(0)
                    }}
                    onClose={handleMainMenuClose}
                  >
                    <div>
                      <h5 className={classes.mainMenuContainerTitle}>
                        Katalog usluga
                      </h5>
                    </div>
                  </MenuItem>
                  <div>
                    <p className={classes.mainMenuContainerSubtitle}>
                      Pogledajte katalog usluga za industriju i javni sektor
                    </p>
                  </div>
                  <MenuItem
                    className={classes.mainMenuContainerItem}
                    component={Link}
                    to='/obuke'
                    onClick={() => {
                      handleMainMenuClose()
                      setValue(0)
                    }}
                    onClose={handleMainMenuClose}
                  >
                    <div>
                      <h5 className={classes.mainMenuContainerTitle}>Obuke</h5>
                    </div>
                  </MenuItem>
                  <div>
                    <p className={classes.mainMenuContainerSubtitle}>
                      Saznaj kako mozes izgraditi vitalne vjestine i biti
                      spreman za buducnost rada u digitalnim i tradicionalnim
                      industrijama
                    </p>
                  </div>
                  <MenuItem
                    className={classes.mainMenuContainerItem}
                    component={Link}
                    to='/kursevi'
                    onClick={() => {
                      handleMainMenuClose()
                      setValue(0)
                    }}
                    onClose={handleMainMenuClose}
                  >
                    <div>
                      <h5 className={classes.mainMenuContainerTitle}>
                        Nase edukacije
                      </h5>
                    </div>
                  </MenuItem>
                  <div>
                    <p className={classes.mainMenuContainerSubtitle}>
                      Pronadji trening program koji ti moze pomoci da svoju
                      karijeru podignes na veci nivo
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.mainMenuContainer}>
              <Grid container>
                <Grid item>
                  <MenuItem
                    className={classes.mainMenuContainerItem}
                    component={Link}
                    to='/careers'
                    onClick={() => {
                      handleMainMenuClose()
                      setValue(3)
                    }}
                    onClose={handleMainMenuClose}
                  >
                    <div>
                      <h5 className={classes.mainMenuContainerTitle}>
                        Karijera u AITI
                      </h5>
                    </div>
                  </MenuItem>
                  <div>
                    <p className={classes.mainMenuContainerSubtitle}>
                      Saznaj kako mozes zapoceti svoju karijeru u AITI Institutu
                    </p>
                  </div>
                  <MenuItem
                    className={classes.mainMenuContainerItem}
                    component={Link}
                    to='/register'
                    onClick={() => {
                      handleMainMenuClose()
                      setValue(0)
                    }}
                    onClose={handleMainMenuClose}
                  >
                    <div>
                      <h5 className={classes.mainMenuContainerTitle}>
                        Registruj se
                      </h5>
                    </div>
                  </MenuItem>
                  <div>
                    <p className={classes.mainMenuContainerSubtitle}>
                      Otvori svoj AITI racun
                    </p>
                  </div>
                  <MenuItem
                    className={classes.mainMenuContainerItem}
                    component={Link}
                    to='/login'
                    onClick={() => {
                      handleMainMenuClose()
                      setValue(0)
                    }}
                    onClose={handleMainMenuClose}
                  >
                    <div>
                      <h5 className={classes.mainMenuContainerTitle}>
                        Prijavi se
                      </h5>
                    </div>
                  </MenuItem>
                  <div>
                    <p className={classes.mainMenuContainerSubtitle}>
                      Pogledaj najnovije obavijesti o svojim obukama
                    </p>
                  </div>
                  <MenuItem
                    className={classes.mainMenuContainerItem}
                    component={Link}
                    to='/onama'
                    onClick={() => {
                      handleMainMenuClose()
                      setValue(0)
                    }}
                    onClose={handleMainMenuClose}
                  >
                    <div>
                      <h5 className={classes.mainMenuContainerTitle}>O nama</h5>
                    </div>
                  </MenuItem>
                  <div>
                    <p className={classes.mainMenuContainerSubtitle}>
                      Saznaj vise o AITI INSTITUTU, nasim ciljevima i fokusima
                      djelovanja u Bosni i Hercegovini
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Menu>
    </React.Fragment>
  )
  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: classes.drawer }}
      >
        <div
          elevation={trigger ? 0 : 0}
          style={{
            marginBottom: trigger ? 50 : 80,
          }}
          className={classes.toolbarMargin}
        ></div>
        <List className={classes.list}>
          <ListItem>
            <Box className={classes.listbox}>
              <p>Napredni institut za inovacije i tehnologije</p>
            </Box>
          </ListItem>

          <ListItem
            onClick={() => {
              setOpenDrawer(false)
              setValue(0)
            }}
            divider
            button
            component={Link}
            selected={value === 0}
            classes={{ selected: classes.drawerItemTextSeleted }}
            to='/vijesti'
          >
            <ListItemText className={classes.drawerItemText} disableTypography>
              Sta je novo
            </ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setOpenDrawer(false)
              setValue(1)
            }}
            divider
            button
            component={Link}
            selected={value === 1}
            to='/obuke'
          >
            <ListItemText className={classes.drawerItemText} disableTypography>
              EduTech
            </ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setOpenDrawer(false)
              setValue(2)
            }}
            divider
            button
            component={Link}
            selected={value === 2}
            to='/oblasti'
          >
            <ListItemText className={classes.drawerItemText} disableTypography>
              Nasa expertiza
            </ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setOpenDrawer(false)
              setValue(2)
            }}
            divider
            button
            component={Link}
            selected={value === 2}
            to='/usluge'
          >
            <ListItemText className={classes.drawerItemText} disableTypography>
              Kako mozemo pomoci
            </ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setOpenDrawer(false)
              setValue(3)
            }}
            divider
            button
            component={Link}
            selected={value === 3}
            to='/careers'
          >
            <ListItemText className={classes.drawerItemText} disableTypography>
              Karijera
            </ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setOpenDrawer(false)
              setValue(3)
            }}
            divider
            button
            component={Link}
            selected={value === 5}
            to='/aiti'
          >
            <ListItemText className={classes.drawerItemText} disableTypography>
              O AITI
            </ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setOpenDrawer(false)
              setValue(3)
            }}
            divider
            button
            component={Link}
            selected={value === 6}
            to='/login'
          >
            <ListItemText className={classes.drawerItemText} disableTypography>
              Prijavi se
            </ListItemText>
          </ListItem>
        </List>
      </SwipeableDrawer>
    </React.Fragment>
  )

  //REACT
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const logoutHandler = () => {
    dispatch(logout())
    setProfileAnchorEl(null)
  }

  return (
    <div>
      <ScrollColor>
        <AppBar position='fixed' className={classes.appbar}>
          <CssBaseline />
          <Toolbar className={classes.toolbar}>
            <Hamburger size={22} toggled={openDrawer} toggle={setOpenDrawer} />
            <Button
              disableRipple
              className={classes.aiti_logo}
              elevation={trigger ? 1 : 0}
              style={{
                color: trigger ? '#000' : '#fff',
              }}
              onClick={() => setValue(0)}
              component={Link}
              to='/'
            >
              AITI
            </Button>
            {matches ? undefined : tabs}
            {drawer}

            {/* <div className={classes.searchBox}>
              <Route
                render={({ history }) => <SearchBox history={history} />}
              />
            </div> */}
            <div className={classes.profileMenuList}>
              <div className={classes.profileMenuListItem}>
                {userInfo && (
                  <div className={classes.profileMenuListUser}>
                    {userInfo.name}
                  </div>
                )}
                <div
                  className={classes.profileIconButton}
                  id='username'
                  aria-controls='profile-menu'
                  aria-haspopup='true'
                  onClick={handleProfileClick}
                >
                  <IconButton
                    elevation={trigger ? 1 : 0}
                    style={{
                      color: trigger ? '#000' : '#fff',
                    }}
                  >
                    <AccountCircle />
                  </IconButton>
                </div>
              </div>
              <SwipeableDrawer
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                anchor='right'
                className={classes.profileMenu}
                classes={{ paper: classes.profileDrawer }}
                id='profile-menu'
                anchorEl={profileAnchorEl}
                keepMounted
                open={Boolean(profileAnchorEl)}
                onClose={handleProfileClose}
              >
                <div
                  elevation={trigger ? 0 : 0}
                  style={{
                    marginBottom: trigger ? 50 : 80,
                  }}
                  className={classes.toolbarMargin}
                ></div>
                <Box>
                  <h3 className={classes.profileTitle}>AITI:DIGITAL</h3>
                  {userInfo && (
                    <div className={classes.profileSubTitle}>
                      {userInfo.name}
                    </div>
                  )}
                </Box>
                {userInfo ? (
                  <List className={classes.profileList}>
                    <Box
                      component={Link}
                      to='/profile'
                      onClick={handleProfileClose}
                    >
                      <p className={classes.listSubTitle}>Vas profil</p>
                      <p className={classes.profileParagraf}>
                        Vidi svoj profil
                      </p>
                    </Box>
                    <Box
                      component={Link}
                      to='/cart'
                      onClick={handleProfileClose}
                    >
                      <p className={classes.listSubTitle}>Vasi kursevi</p>
                      <p className={classes.profileParagraf}>
                        Pogledajte status kurseva koje ste odabrali
                      </p>
                    </Box>

                    <Box component={Link} to='/' onClick={logoutHandler}>
                      <p className={classes.listSubTitle}>Odjavi se</p>
                    </Box>
                  </List>
                ) : (
                  <div>
                    <Button
                      variant='contained'
                      className={classes.button}
                      component={Link}
                      to='/login'
                      onClick={handleProfileClose}
                    >
                      Prijavi se
                    </Button>
                    <Divider />
                    <Button
                      variant='contained'
                      className={classes.button}
                      component={Link}
                      to='/register'
                      onClick={handleProfileClose}
                    >
                      Registruj se
                    </Button>
                  </div>
                )}
                {userInfo && userInfo.isAdmin && (
                  <ListItem
                    component={Link}
                    to='/administracija'
                    onClick={handleProfileClose}
                  >
                    <p>Administracija</p>
                  </ListItem>
                )}
              </SwipeableDrawer>
            </div>
          </Toolbar>
        </AppBar>

        {/* </HideOnScroll> */}
      </ScrollColor>
    </div>
  )
}

export default Header
