import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createOrder } from '../../actions/orderActions'
//import { ORDER_CREATE_RESET } from '../constants/orderConstants'
//import { USER_DETAILS_RESET } from '../constants/userConstants'

//Components
import Message from '../../components/UI/Message'
import CheckoutSteps from '../../components/OBUKE/CheckoutSteps'
//Styles
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import theme from '../../components/UI/Theme'
import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Grid,
  Paper,
} from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    ...theme.paperMain,
    height: 'auto',
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 0,
    },
  },
  gridCardLeft: {
    width: '60%',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  gridCardRight: {
    width: '30%',
    marginLeft: '3%',
    padding: 20,
    paddingRight: 50,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 10,
    },
  },
  box: {
    display: 'flex',
    verticalAlign: 'center',
    height: 50,
    marginTop: 80,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginBottom: 60,
    },
  },
  coverArrow: { height: 50, verticalAlign: 'center' },
  coverArrow1: { height: 50, paddingTop: 15 },

  coverBox: {
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },

  headerSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 0,
    marginBottom: 0,
    fontFamily: 'PT Sans',
    fontSize: '1.6rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      height: '2rem',
      paddingBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      height: 'auto',
      margin: 0,
      paddingLeft: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    marginTop: 20,
    letterSpacing: '2px',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 20,
      fontSize: '2.5rem',
    },
  },
  gridBox: {},
  gridContact: {},
  gridParagraph: {
    marginBottom: 2,
  },
  gridTitleBox: {
    marginLeft: 20,
  },
  prijavaButton: {
    ...theme.prijavaButton,
    marginTop: 50,
    marginBottom: 50,
  },

  media: {
    width: 100,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 100,
      height: 'auto',
    },
  },
  gridCardRightBox: {
    width: '100%',
    margin: 0,
  },
  gridRightBox: {
    width: 300,
    marginLeft: 0,
  },
  gridRightItem: {
    width: 150,
    height: 50,
    marginTop: 5,
    padding: 0,
    alignItems: 'center',
  },
  gridRightItemB: {
    width: 'auto',
    height: 50,
    marginTop: 5,
    padding: 0,
    paddingLeft: 'auto',

    alignItems: 'center',
    justify: 'flex-end',
  },
})

const PlaceOrderScreen = ({ history }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)

  if (!cart.shippingAddress.address) {
    history.push('/shipping')
  } else if (!cart.paymentMethod) {
    history.push('/payment')
  }
  //   Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  )
  cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 100)
  cart.taxPrice = addDecimals(Number((0.15 * cart.itemsPrice).toFixed(2)))
  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2)

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success, error } = orderCreate

  useEffect(() => {
    if (success) {
      history.push(`/order/${order._id}`)
      // dispatch({ type: USER_DETAILS_RESET })
      // dispatch({ type: ORDER_CREATE_RESET })
    }
    // eslint-disable-next-line
  }, [history, success])

  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      })
    )
  }

  return (
    <div className={classes.main}>
      <Paper className={classes.paper_top}>
        <Box>
          <Link to='/kursevi'>
            <div className={classes.box}>
              <Box component='div' display='inline'>
                <KeyboardArrowLeftIcon className={classes.coverArrow} />
              </Box>
              <Box
                component='div'
                display='inline'
                className={classes.coverArrow1}
              >
                SVI KURSEVI
              </Box>
            </div>
          </Link>
        </Box>
        <Grid
          container
          direction='column'
          justify='space-between'
          className={classes.coverBox}
        >
          <Box>
            <h3 className={classes.mainTitle}>
              Vasa selekcija odabranih obrazovnih programa
            </h3>
            <p className={classes.headerSubTitle}>
              Molimo da napravite finalnu provjeru odabranih obrazovnih
              programa. Nakon slanja prijave vas zahtjev ce uci u
              administrativnu proceduru.
            </p>
          </Box>
          <Divider />
        </Grid>
      </Paper>

      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        className={classes.paper_top}
      >
        <Grid container className={classes.gridCardLeft}>
          <CheckoutSteps step1 step2 step3 step4 />
          <Grid container direction='column' className={classes.gridBox}>
            <Grid item className={classes.gridContact}>
              <h2>Podaci za kontakt</h2>
              <p>
                <strong>Adresa:</strong>
              </p>
              <p className={classes.gridParagraph}>
                {cart.shippingAddress.address}
              </p>
              <p className={classes.gridParagraph}>
                {cart.shippingAddress.city}
              </p>
              <p className={classes.gridParagraph}>
                {cart.shippingAddress.postalCode}
              </p>
              <p className={classes.gridParagraph}>
                {cart.shippingAddress.country}
              </p>
            </Grid>

            <Grid item>
              <h2>Metod placanja</h2>
              <strong>Metod: </strong>
              {cart.paymentMethod}
            </Grid>

            <Grid item>
              <h2>Kursevi na koje ste se prijavili</h2>
              {cart.cartItems.length === 0 ? (
                <Message>Niste se prijavili ni na jedan kurs</Message>
              ) : (
                <Grid container>
                  {cart.cartItems.map((item, index) => (
                    <Grid item key={index}>
                      <Grid container>
                        <Card>
                          <CardMedia
                            component='img'
                            className={classes.media}
                            image={item.image}
                            alt={item.name}
                          />
                        </Card>
                        <Box className={classes.gridTitleBox}>
                          <Link to={`/kurs/${item.kurs}`}>
                            <p className={classes.gridParagraph}>{item.name}</p>
                          </Link>
                          <p>
                            {item.qty} x KM {item.price} = KM{' '}
                            {item.qty * item.price}
                          </p>
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.gridCardRight}>
          <Grid
            container
            direction='column'
            className={classes.gridCardRightBox}
          >
            <Box item>
              <h2>Sazetak narudzbi</h2>
            </Box>
            <Grid item>
              <Grid
                container
                direction='row'
                spacing={3}
                className={classes.gridRightBox}
              >
                <Grid item className={classes.gridRightItem}>
                  Cijena:
                </Grid>
                <Grid
                  item
                  justify='flex-end'
                  className={classes.gridRightItemB}
                >
                  {cart.itemsPrice} KM
                </Grid>
              </Grid>
              <Divider />
            </Grid>

            <Grid item>
              <Grid
                container
                direction='row'
                spacing={3}
                className={classes.gridRightBox}
              >
                <Grid item className={classes.gridRightItem}>
                  Naknade:
                </Grid>
                <Grid item className={classes.gridRightItemB}>
                  {cart.shippingPrice} KM
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid item>
              <Grid
                container
                direction='row'
                spacing={3}
                className={classes.gridRightBox}
              >
                <Grid item className={classes.gridRightItem}>
                  PDV:
                </Grid>
                <Grid item className={classes.gridRightItemB}>
                  {cart.taxPrice} KM
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid item>
              <Grid
                container
                direction='row'
                spacing={3}
                className={classes.gridRightBox}
              >
                <Grid item className={classes.gridRightItem}>
                  Ukupno:
                </Grid>
                <Grid item className={classes.gridRightItemB}>
                  {cart.totalPrice} KM
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid item>
              {error && <Message variant='danger'>{error}</Message>}
            </Grid>
            <Grid item>
              <Button
                type='button'
                className={classes.prijavaButton}
                disabled={cart.cartItems === 0}
                onClick={placeOrderHandler}
              >
                Posalji narudzbu
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default PlaceOrderScreen
