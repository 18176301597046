import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import theme from '../UI/Theme'

const useStyles = makeStyles({
  container: {
    ...theme.paperMain,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 20,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 20,
    },
  },
})

const FormContainer = ({ children }) => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Grid container>
        <Grid item xs={12} md={6}>
          {children}
        </Grid>
      </Grid>
    </Box>
  )
}

export default FormContainer
