import axios from 'axios'

import {
  VIJEST_LIST_REQUEST,
  VIJEST_LIST_SUCESS,
  VIJEST_LIST_FAIL,
  VIJEST_DETAILS_REQUEST,
  VIJEST_DETAILS_SUCESS,
  VIJEST_DETAILS_FAIL,
  VIJEST_DELETE_REQUEST,
  VIJEST_DELETE_SUCCESS,
  VIJEST_DELETE_FAIL,
  VIJEST_CREATE_REQUEST,
  VIJEST_CREATE_SUCCESS,
  VIJEST_CREATE_FAIL,
  VIJEST_UPDATE_REQUEST,
  VIJEST_UPDATE_SUCCESS,
  VIJEST_UPDATE_FAIL,
  VIJEST_ONE_REQUEST,
  VIJEST_ONE_SUCESS,
  VIJEST_ONE_FAIL,
} from '../constants/vijestConstants'

export const listVijesti = () => async (dispatch) => {
  try {
    dispatch({ type: VIJEST_LIST_REQUEST })
    const { data } = await axios.get('/api/vijesti')
    dispatch({
      type: VIJEST_LIST_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: VIJEST_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listVijestDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: VIJEST_DETAILS_REQUEST })
    const { data } = await axios.get(`/api/vijesti/${id}`)
    dispatch({
      type: VIJEST_DETAILS_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: VIJEST_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteVijest = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VIJEST_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/vijesti/${id}`, config)

    dispatch({
      type: VIJEST_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: VIJEST_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createVijest = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: VIJEST_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/vijesti`, {}, config)

    dispatch({
      type: VIJEST_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: VIJEST_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateVijest = (vijest) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VIJEST_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/vijesti/${vijest._id}`,
      vijest,
      config
    )

    dispatch({
      type: VIJEST_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: VIJEST_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listOneVijest = () => async (dispatch) => {
  try {
    dispatch({ type: VIJEST_ONE_REQUEST })

    const { data } = await axios.get(`/api/vijesti/one`)

    dispatch({
      type: VIJEST_ONE_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: VIJEST_ONE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
