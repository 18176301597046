import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listKursevi } from '../../actions/kursActions'
//Components
import Meta from '../../components/Meta'
import Paginate from '../../components/PaginateNOVO'
import Kurs from '../../components/OBUKE/Kurs'
//Style
import { makeStyles, Box, Grid, Paper } from '@material-ui/core'

const borderBox = { m: 1, border: 1, style: { borderColor: '#d9d9d9' } }

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    padding: theme.spacing(1.4),
    margin: 'auto',
    width: 1350,
    backgroundColor: theme.palette.common.aitiGrey,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paperKursevi: {
    padding: theme.spacing(1.4),
    margin: 'auto',
    width: 1350,
    backgroundColor: theme.palette.common.aitiGrey,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '400px',
      padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  coverBox: {
    height: 'auto',
    width: 'auto',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      height: 450,
      width: 370,
    },
  },
  titleBox: {
    width: 900,
    height: 'auto',

    paddingTop: 80,
    marginBottom: 80,
    [theme.breakpoints.down('md')]: {
      height: 400,
      width: '80%',
      paddingTop: 80,
    },
    [theme.breakpoints.down('sm')]: {
      height: 500,
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      height: 440,
      width: '100%',
      padding: 20,
      paddingTop: 80,
      marginBottom: 0,
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    marginTop: 80,
    letterSpacing: 2,
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 0,
      fontSize: '3rem',
    },
  },
  mainSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 100,
    fontFamily: 'PT Sans',
    fontSize: '2.3rem',
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      height: '2rem',
      paddingBottom: 50,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: 350,
      height: '10rem',
      margin: 0,
      paddingRight: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
  mainGrid_vs: {
    padding: theme.spacing(2),
    justify: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 0,
    },
  },
  mainGridItem_left: {
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: { width: '100%', padding: 0 },
  },
  kurseviGrid: { width: 'auto' },
}))

const KurseviScreen = ({ match }) => {
  const classes = useStyles()
  const keyword = match.params.keyword
  const pageNumber = match.params.pageNumber || 1
  const dispatch = useDispatch()
  const kursList = useSelector((state) => state.kursList)
  const { loading, error, kursevi, pages, page } = kursList

  useEffect(() => {
    dispatch(listKursevi(keyword, pageNumber))
  }, [dispatch, keyword, pageNumber])

  return (
    <div className={classes.main}>
      <Meta />
      <Paper className={classes.paper_top}>
        <Grid container justify='space-between' className={classes.coverBox}>
          <Box
            {...borderBox}
            borderTop={0}
            borderLeft={0}
            borderBottom={1}
            borderRight={0}
            className={classes.titleBox}
          >
            <p>AITI DIGITAL</p>
            <h3 className={classes.mainTitle}>AITI Skill Accelerator</h3>
            <p className={classes.mainSubTitle}>
              Pronadji trening program koji ti moze pomoci da svoju karijeru
              podignes na veci nivo
            </p>
          </Box>
        </Grid>
      </Paper>

      <Paper className={classes.paperKursevi}>
        <Grid container justify='space-between' className={classes.kurseviGrid}>
          {loading ? (
            <h2>Loading.....</h2>
          ) : error ? (
            <h3>{error}</h3>
          ) : (
            <Grid container className={classes.mainGrid_vs}>
              {kursevi.map((kurs) => (
                <Grid
                  item
                  className={classes.mainGridItem_left}
                  key={kurs._id}
                  xs={12}
                  //sm={12}
                  md={6}
                  lg={4}
                  //xl={3}
                >
                  <Kurs kurs={kurs} />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        <Paginate pages={pages} page={page} keyword={keyword ? keyword : ''} />
      </Paper>
    </div>
  )
}

export default KurseviScreen
