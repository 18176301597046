import React from 'react'
//Components
import Meta from '../../components/Meta'
//Styles
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../components/UI/Theme'
import { Card, Grid, CardMedia, Paper } from '@material-ui/core'
// Images
import coverImage from '../../images/vjestine-prva.jpg'
import secondImage from '../../images/vjestine-druga.jpeg'
import thirdImage from '../../images/vjestine-treca.jpeg'
const useStyles = makeStyles((theme) => ({
  //SHEET
  root: {
    maxWidth: '100%',
    height: 'auto',
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  //COVER
  cover: {
    maxWidth: '100%',
    height: 600,
    marginTop: 80,

    [theme.breakpoints.down('sm')]: {
      height: '65vh',
      marginTop: 60,
    },
  },
  media: {
    height: 600,
    backgroundPosition: 'top',
    [theme.breakpoints.down('sm')]: { height: '65vh' },
  },
  coverBox: {
    height: 700,
    width: '33vw',
    marginRight: 'auto',
    verticalAlign: 'middle',
    backgroundColor: '#fff',
    opacity: 0.8,
    marginTop: -700,
    padding: 40,
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 180,
      marginTop: -180,
      padding: 20,
      paddingBottom: 0,
      marginRight: 0,
    },
  },
  coverTitle: {
    ...theme.mainTitle,
    marginTop: 300,
    color: '#000',
    fontSize: '4.4rem',
    fontWeight: '600',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      marginTop: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.4rem',
      fontWeight: '600',
      marginBottom: 0,
    },
  },
  coverSubTitle: {
    ...theme.mainSubTitle,
    marginBottom: 10,
    color: '#000',
    fontWeight: '400',
    lineHeight: '1.6',
    alignItem: 'center',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 0,
      fontSize: '1.3rem',
      color: '#000',
    },
  },
  // PRVI DIO
  prviDio: {
    maxWidth: '100%',
    backgroundColor: '#fff',
    height: 400,
  },
  prvi_dio_box: {
    width: '50%',
    margin: 'auto',
    backgroundColor: '#fff',
    paddingTop: 60,
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 20,
      paddingTop: 60,
    },
  },
  prvi_dioParagraph: {
    ...theme.mainParagraph,
    fontSize: '1.3rem',
    color: '#000',
    fontWeight: '500',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
      marginBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: '500',
    },
  },
  //DRUGI DIO
  drugiDio: {
    maxWidth: '80%',
    margin: 'auto',
    marginTop: 60,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  image_II: {
    height: 500,
    alignItem: 'bottom',
    [theme.breakpoints.down('sm')]: { height: '35vh' },
  },
  drugiBox: {
    height: 'auto',
    width: '85%',
    margin: 'auto',
    marginTop: -100,
    padding: 80,
    backgroundColor: '#fff',
    color: '#000',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      marginTop: -40,
      padding: 20,
    },
  },
  drugi_dioTitle: {
    ...theme.mainTitle,
    width: '60vw',
    paddingTop: -15,
    fontSize: '3rem',
    letterSpacing: 4,
    color: '#000',
    fontWeight: 600,
    zIndex: '1250 !important',
    '& span': {
      color: theme.palette.common.aitiTirkiz,
      fontWeight: 600,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
      paddingTop: 220,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '1.8rem',
      fontWeight: 600,
      lineHeight: '1.6',
      letterSpacing: 2,
      marginBottom: 20,
      paddingTop: 10,
    },
  },
  drugi_dioSubTitle: {
    ...theme.mainSubTitle,
    marginTop: 20,
    color: '#000',
    fontWeight: '600',
    lineHeight: '1.6',
    alignItem: 'center',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 0,
      fontSize: '1.3rem',
      color: '#000',
    },
  },
  drugi_dioParagraph: {
    ...theme.mainParagraph,
    fontSize: '1.3rem',
    lineHeight: '1.7',
    color: '#000',
    fontWeight: '500',
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: '500',
    },
  },
  treci_dioParagraph: {
    ...theme.mainParagraph,
    fontSize: '1.3rem',
    lineHeight: '1.7',
    color: '#000',
    fontWeight: '500',
    marginBottom: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      marginBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: '500',
      marginBottom: 1,
    },
  },
}))

const VjestineScreen = () => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Meta />
      <Card className={classes.cover}>
        <CardMedia className={classes.media} image={coverImage} title='AITI' />

        <Grid container direction='column' className={classes.coverBox}>
          <h3 className={classes.coverTitle}>Dizajnirani za ucenje</h3>
          <p className={classes.coverSubTitle}>
            Sa nama mozes nauciti vodece tehnologije
          </p>
        </Grid>
      </Card>
      <div className={classes.prviDio}>
        <Grid container direction='column' className={classes.prvi_dio_box}>
          <p className={classes.prvi_dioParagraph}>
            Nasa misija je usmjerena kako bi olaksali razvoj i sticanje novih
            vjestina kako bi se svi zajedno pripremili za buducnost.
          </p>
          <p className={classes.prvi_dioParagraph}>
            Mi vam mozemo pomoci da preokrenete svoju karijeru i da ubrzate svoj
            napredak na osnovu snage vasih znanja i talenata. Ili da otvorite
            vrata razvoju potpuno nove karijere u polju kao sto su informacione
            tehnologije.
          </p>
          <p className={classes.prvi_dioParagraph}>
            AITI vam omogucava da steknete ili unaprijedite znanja i vjestine
            kroz pohadjanje akreditovanih programa obrazovanja, ucesce na
            treninzima, kursevima i radionicma, kao i kroz obuku uz rad.
          </p>
        </Grid>
      </div>
      <div className={classes.drugiDio}>
        <CardMedia
          className={classes.image_II}
          image={secondImage}
          title='AITI'
        />
        <Grid container direction='column' className={classes.drugiBox}>
          <h3 className={classes.drugi_dioTitle}>Nas pristup ucenju</h3>

          <p className={classes.drugi_dioParagraph}>
            Mi smo posveceni razvoju vjesina koje su potrebne na trzistu rada.
            Trenutnom i buducem.
          </p>
          <p className={classes.drugi_dioParagraph}>
            Nasi programi obrazovanja su posebno dizajnirani kako bi vas
            opremili znanjima i vjestinama sa kojima mozete biti izvrsni na
            radnom mjestu. Ako se nalazite u tranziciji u karijeri znanja i
            vjestine stecene kod vas vam mogu dati kljucnu prednost kod novog
            poslodavca.
          </p>
          <p className={classes.drugi_dioSubTitle}>Zasto uciti sa nama</p>
          <p className={classes.drugi_dioParagraph}>
            Sa nama mozete steci znanja i vjestine koje unapriedjuju vase
            formalne kvalifikacije
          </p>
          <p className={classes.drugi_dioParagraph}>
            Neki od nasih kurseva, zahvaljujuci podrsci donatora su besplatni.
          </p>
          <p className={classes.drugi_dioParagraph}>
            Ucenje sa nama vi razvijate svoju karijeru sa vjestinama koje
            poslodavci traze.
          </p>
          <p className={classes.drugi_dioParagraph}>
            Mi nastojimo pojednostaviti pristup ucenju koliko je moguce: Mi vam
            pomazemo da izaberete svoj obrazovni put, pruzamo podrsku tokom
            pohadjanja obuke, i promovisemo vasa znanja i vjestine kod
            poslodavaca.
          </p>
          <p className={classes.drugi_dioSubTitle}>Za nezaposlene</p>
          <p className={classes.drugi_dioParagraph}>
            Ako ste nezaposleni ili u potrazi za poslom, AITI akreditovani
            obrazovni programi su idealna mogucnost da unaprijedite svoj set
            znanja i vjestina koji sa kojima mozete poboljsati svoju poziciju na
            trzistu rada.
          </p>
          <p className={classes.drugi_dioParagraph}>
            Sve sto je potrebno jeste da se da se registrujete, ponunite kontakt
            podatke i prijavite na neki od otvorenih poziva za pohadjanje obuke.
            Pored pohadjanja programa obrazovanja mi vam nudimo mogucnost
            pohadjanja trening kurseva koji ce unaprijediti domene vasih
            vjestine iz sirogog seta oblasti kako bi vas osposobilo da obavljate
            veliki broj uloga na radnom mjestu.
          </p>
          <p className={classes.drugi_dioParagraph}>
            Pored pohadjanja akreditovanih programa obrazovanja i trening
            kurseva, AITI vam nudi mogucnost obuke na radnom mjestu / ucenja u
            radno slicnom okruzenju.
          </p>
          <p className={classes.drugi_dioParagraph}>
            Kroz pristup ucenja u radno slicnom okruzenju mi vam pruzamo
            mogucnost da radom na konkretnim poslovima istovremeno ucite i kroz
            rad razvijate svoje vjestine. Posebno dizajnirani programi
            osposobljavanja u trajanju od 30, 90 i 180 dana. Primjenjujuci nasu
            metodu vi dobijate priliku da procjenite nivo svojih vjestina u
            odnosu na nivo koji je potreban za efikasno obavljanje posla; Zatim
            se za vas izradjuje individualiziran program obuke koji se provodi
            pod kontrolom metora, nakon cega ucestvujete u radu na konkretnim
            poslovima u kojima svoje znanja i vjestine unaprijedujete kroz rad i
            kroz podrsku mentora, menadzera i prije svega svojih kolega na
            radnom mjestu.
          </p>
          <p className={classes.drugi_dioSubTitle}>Za zaposlene</p>
          <p className={classes.drugi_dioParagraph}>
            Mi vjerujemo da je kljuc uspijeha u kontinuiranom razvoju znanja i
            vjestina kako bi bili izvrsni na radnom mjestu.
          </p>
          <p className={classes.drugi_dioParagraph}>
            Mi kontinuiramo pratimo stanje na trzistu rada i potrebe poslodavaca
            za znanjima i vjestinama radne snage. Takodje pratimo i trendove na
            sirem trzistu rada i procjenjujemo kojim oblastima je potrebno
            posvetiti paznju kako bi zaposlene osobe najefikasnije napravile
            iskorak u svojoj karijeri. U skladu sa obrazovnim potrebama
            zaposlenih osoba dizajniramo posebne obrazovne dogadjaje i trening
            programe koji su prilagodjeni zaposlenim osobama kako bi se pruzila
            mogucnost da unaprijede svoj set vjestina, ili kako da steknu
            potpuno novi set vjestina koji ce im omoguiti uspjesno obavljanje
            veceg broja uloga na radnom mjestu.
          </p>
          <p className={classes.drugi_dioParagraph}>
            Nas pristup u razvoju vjestina zaposlenih zasnovan je na
            identifikaciji obrazovnih potreba, dizajniranju obrazovnih dogadjaja
            koji imaju za cilj jacanje svijesti o potrebi za novim vjestinama,
            te organiyovanju kratkih edukacija izvan radnog vremena i u dane
            vikenda.
          </p>
          <p className={classes.drugi_dioParagraph}>
            AITI zajedno sa ALDI Gorazde direktno saradjuje i sa poslodavcima
            kako bi se ciljano razvile vjestine zaposlenih radnika u oblastima
            za koje poslodavci smatraju da su kljucne u osiguranju vecih
            performansi na radnom mjestu.
          </p>
        </Grid>
      </div>
      <div className={classes.drugiDio}>
        <CardMedia
          className={classes.image_II}
          image={thirdImage}
          title='AITI'
        />
        <Grid container direction='column' className={classes.drugiBox}>
          <h3 className={classes.drugi_dioTitle}>AITI Alumni</h3>

          <p className={classes.drugi_dioParagraph}>
            Mi vjerujemo da je kljuc u dugorocnoj saradnji koja je puno veca od
            pohadjanja jednog ili vise obrazovnih programa.
          </p>
          <p className={classes.drugi_dioParagraph}>
            AITI Alumni je posebno dizajniran program kako bi svi oni koji su
            zajedno sa nama razvijali vjestine imali otvorena vrata za sve
            dogadjaje koje organizujemo a koji su posveceni razvoju znanja i
            vjestina.
          </p>
          <p className={classes.drugi_dioParagraph}>
            Nase progarame obrazovanja, zajedno sa programima koje smo provodili
            sa ALDI Gorazde, je uspjesno zavrsilo vise od 1000 osoba, od cega
            preko 300 osoba koje su posjedovale inicijalno univerzitetsko
            obrazovanje. Povjerenje koje nam je dato potpuno je opravdano.
            Vecina onih koji su zajedno sa nama razvijali svoje vjestine danas
            su zaposlene osobe. A vise od 200 osoba su nasi Alumni.
          </p>

          <p className={classes.drugi_dioSubTitle}>Kako nas kontaktirati</p>
          <p className={classes.treci_dioParagraph}>
            Za sve upite i informacije mozete nas slobodno kontaktirati:
          </p>
          <p className={classes.treci_dioParagraph}>AITI</p>
          <p className={classes.treci_dioParagraph}>
            Napredni institut za inovacije tehnologije
          </p>
          <p className={classes.treci_dioParagraph}>Panorama b.b.</p>
          <p className={classes.treci_dioParagraph}>73000 Gorazde</p>
          <p className={classes.drugi_dioParagraph}>Bosna i Hercegovina</p>
          <p className={classes.treci_dioParagraph}>Tel: +387 38 227 850</p>
          <p className={classes.treci_dioParagraph}>Tel: +387 38 227 850</p>
          <p className={classes.treci_dioParagraph}>Email: aiti@aiti.ba</p>
        </Grid>
      </div>
    </Paper>
  )
}

export default VjestineScreen
