import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listCareerDetails, updateCareer } from '../../actions/careerActions'
import { CAREER_UPDATE_RESET } from '../../constants/careerConstants'
import { Editor } from '@tinymce/tinymce-react'
//Components
import Message from '../../components/UI/Message'
import Loader from '../../components/UI/Loader'
import FormContainer from '../../components/OBUKE/FormContainer'
//Styles
import { Form } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import theme from '../../components/UI/Theme'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormControl,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    ...theme.paperMain,
    height: 'auto',
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 0,
    },
  },
  paper_bottom: {
    ...theme.paperMain,
    height: 'auto',
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 0,
    },
  },
  gridCardLeft: {
    width: '60%',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  gridCardRight: {
    width: '37%',
    marginLeft: '3%',
    padding: 20,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: 10,
    },
  },
  box: {
    display: 'flex',
    verticalAlign: 'center',
    height: 50,
    marginTop: 80,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginBottom: 60,
    },
  },
  coverArrow: { height: 50, verticalAlign: 'center' },
  coverArrow1: { height: 50, paddingTop: 15 },

  coverBox: {
    height: 150,
    [theme.breakpoints.down('xs')]: {
      height: 250,
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    marginTop: 20,
    letterSpacing: '2px',
    fontSize: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 20,
      fontSize: '2rem',
    },
  },
  mainSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 0,
    marginBottom: 20,
    fontFamily: 'PT Sans',
    fontSize: '1.4rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      height: '2rem',
      paddingBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      height: 50,
      margin: 0,
      paddingLeft: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },

  prijavaButton: {
    ...theme.prijavaButton,
    marginTop: 50,
    marginBottom: 50,
  },
  boxForm: {
    marginTop: 20,
  },
  mainFormGroup: {
    marginTop: 20,
    marginBottom: 20,
  },
})

const CareerEditScreen = ({ match, history }) => {
  const classes = useStyles()
  const careerId = match.params.id

  const [title, setTitle] = useState('')
  const [image, setImage] = useState('')
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('')
  const [status, setStatus] = useState('')
  const [date, setDate] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [brief, setBrief] = useState('')
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  const careerDetails = useSelector((state) => state.careerDetails)
  const { loading, error, career } = careerDetails

  const careerUpdate = useSelector((state) => state.careerUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = careerUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: CAREER_UPDATE_RESET })
      history.push('/admin/careerlist')
    } else {
      if (!career.title || career._id !== careerId) {
        dispatch(listCareerDetails(careerId))
      } else {
        setTitle(career.title)
        setImage(career.image)
        setDescription(career.description)
        setCategory(career.category)
        setStatus(career.status)
        setDate(career.date)
        setDateEnd(career.dateEnd)
        setBrief(career.brief)
      }
    }
  }, [dispatch, history, careerId, career, successUpdate])
  //tinymce
  const handleDescription = (content, e) => {
    setDescription(content)
  }

  const [value, setValue] = useState(description ?? '')
  useEffect(() => setValue(description ?? ''), [description])

  const handleChangeCategory = (e) => {
    setCategory(e.target.value)
  }
  const handleChangeStatus = (e) => {
    setStatus(e.target.value)
  }

  //tinymce end
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateCareer({
        _id: careerId,
        title,
        image,
        description,
        category,
        status,
        date,
        dateEnd,
        brief,
      })
    )
  }

  return (
    <div className={classes.main}>
      <Paper className={classes.paper_top}>
        <Box>
          <Link to='/admin/vijestlist'>
            <div className={classes.box}>
              <Box component='div' display='inline'>
                <KeyboardArrowLeftIcon className={classes.coverArrow} />
              </Box>
              <Box
                component='div'
                display='inline'
                className={classes.coverArrow1}
              >
                NAZAD NA KARIJERE
              </Box>
            </div>
          </Link>
        </Box>
        <Grid container justify='space-between' className={classes.coverBox}>
          <Box className={classes.titleBox}>
            <h3 className={classes.mainTitle}>
              Kreiranje nove ili uredjivanje postojece objavljene karijere
            </h3>
            <p className={classes.mainSubTitle}>
              Editovanje selektovane karijere
            </p>
          </Box>
        </Grid>
        <Divider />
      </Paper>
      <Paper className={classes.paper_bottom}>
        <FormContainer>
          <h5>KARIJERA:</h5>
          <h1>{title}</h1>
          <Divider />
          {loadingUpdate && <Loader />}
          {errorUpdate && <Message variant='denger'>{errorUpdate}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <Form onSubmit={submitHandler}>
              <FormGroup controlId='name' className={classes.mainFormGroup}>
                <FormLabel>Naziv pozicije</FormLabel>
                <FormControl
                  type='text'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                >
                  <TextField
                    id='outlined-basic'
                    label='Naziv pozicije'
                    variant='outlined'
                    value={title}
                  />
                </FormControl>
              </FormGroup>
              <Divider />
              <Form.Group controlId='image'>
                <Form.Label>Slika</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Slika za karijeru'
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                ></Form.Control>
                <Form.File
                  id='image-file'
                  label='Izaberi datoteku'
                  type='file'
                  custum
                  onChange={uploadFileHandler}
                ></Form.File>
                {uploading && <Loader />}
              </Form.Group>
              <Divider />
              <FormGroup controlId='brief' className={classes.mainFormGroup}>
                <FormLabel>Ukratko o poziciji</FormLabel>
                <FormControl
                  type='text'
                  value={brief}
                  onChange={(e) => setBrief(e.target.value)}
                >
                  <TextField
                    id='outlined-basic'
                    label='Kratak opis u jednoj ili dvije recenice'
                    variant='outlined'
                    value={brief}
                  />
                </FormControl>
              </FormGroup>
              <Divider />
              <FormGroup
                controlId='description'
                className={classes.mainFormGroup}
              >
                <FormLabel>Detaljan opis pozicije</FormLabel>
                <FormControl
                  type='text'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                >
                  <Editor
                    value={description}
                    init={{
                      height: 400,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                    onEditorChange={handleDescription}
                  />
                </FormControl>
              </FormGroup>
              <Divider />
              <FormGroup controlId='category' className={classes.mainFormGroup}>
                <FormLabel as='legend'>Odaberi kategoriju karijere</FormLabel>
                <RadioGroup value={category} onChange={handleChangeCategory}>
                  <FormControlLabel
                    control={<Radio />}
                    label='Ugovor o radu'
                    value='Ugovor o radu'
                    onChange={(e) => setCategory(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Ugovor o djelu'
                    value='Ugovor o djelu'
                    onChange={(e) => setCategory(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Studiranje uz rad'
                    value='Apprentiship'
                    onChange={(e) => setCategory(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Obuka uz rad'
                    value='Obuka uz rad'
                    onChange={(e) => setCategory(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Volontiranje'
                    value='Volontiranje'
                    onChange={(e) => setCategory(e.target.value)}
                  ></FormControlLabel>
                </RadioGroup>
              </FormGroup>
              <Divider />
              <Divider />
              <FormGroup controlId='status' className={classes.mainFormGroup}>
                <FormLabel as='legend'>Odaberi status pozicije</FormLabel>
                <RadioGroup value={status} onChange={handleChangeStatus}>
                  <FormControlLabel
                    control={<Radio />}
                    label='Otvoreno'
                    value='Otvoreno'
                    onChange={(e) => setStatus(e.target.value)}
                  ></FormControlLabel>
                  <FormControlLabel
                    control={<Radio />}
                    label='Zatvoreno'
                    value='Zatvoreno'
                    onChange={(e) => setStatus(e.target.value)}
                  ></FormControlLabel>
                </RadioGroup>
              </FormGroup>
              <FormGroup controlId='date' className={classes.mainFormGroup}>
                <FormLabel>Datum objavljivanja</FormLabel>
                <FormControl
                  type='text'
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                >
                  <TextField
                    id='outlined-basic'
                    label='Datum'
                    variant='outlined'
                    value={date}
                  />
                </FormControl>
              </FormGroup>
              <Divider />
              <FormGroup controlId='dateEnd' className={classes.mainFormGroup}>
                <FormLabel>Rok za prijavu</FormLabel>
                <FormControl
                  type='text'
                  value={dateEnd}
                  onChange={(e) => setDateEnd(e.target.value)}
                >
                  <TextField
                    id='outlined-basic'
                    label='Rok za prijavu'
                    variant='outlined'
                    value={dateEnd}
                  />
                </FormControl>
              </FormGroup>
              <Divider />
              <Button
                type='submit'
                variant='primary'
                className={classes.prijavaButton}
              >
                Azuriraj
              </Button>
            </Form>
          )}
        </FormContainer>
      </Paper>
    </div>
  )
}

export default CareerEditScreen
