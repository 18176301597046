import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { listCareerDetails } from '../../actions/careerActions'

//Components
//import Meta from '../components/Meta'
import Message from '../../components/UI/Message'
import Loader from '../../components/UI/Loader'
//Styles
import { Row, Col, Image, ListGroup } from 'react-bootstrap'
//Styles
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../components/UI/Theme'
import { Box, Card, Grid, CardMedia, Paper } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  //SHEET
  root: {
    maxWidth: '100%',
    height: 'auto',
    marginTop: 80,
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
}))

const CareerScreen = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const careerDetails = useSelector((state) => state.careerDetails)
  const { loading, error, career } = careerDetails

  useEffect(() => {
    dispatch(listCareerDetails(match.params.id))
  }, [dispatch, match])
  return (
    <Paper className={classes.root}>
      <Link className='btn btn-light my-2' to='/careers'>
        Pogledaj sve pozicije
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <Row>
          <Col md={6}>
            <Image src={career.image} alt={career.title} fluid />
          </Col>
          <Col md={3}>
            <ListGroup varian='Flush'>
              <ListGroup.Item>
                <h3>{career.title}</h3>
              </ListGroup.Item>
              <ListGroup.Item></ListGroup.Item>
              <ListGroup.Item>Datum: {career.date}</ListGroup.Item>
              <ListGroup.Item>Rok prijave: {career.dateEnd}</ListGroup.Item>
              <ListGroup.Item>
                Opis
                <div dangerouslySetInnerHTML={{ __html: career.brief }} />
              </ListGroup.Item>
              <ListGroup.Item>
                Opis
                <div dangerouslySetInnerHTML={{ __html: career.description }} />
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      )}
    </Paper>
  )
}

export default CareerScreen
