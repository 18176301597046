import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import { useSpring, animated } from 'react-spring/web.cjs' // web.cjs is required for IE 11 support
import video from '../../images/video.mp4'
import { Box, CardMedia } from '@material-ui/core'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'

const videoBox = {
  bgcolor: 'transparent',
  m: 1,
  margin: 'auto',
  style: { width: '300px', height: '60px' },
  borderColor: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  videoBoxMain: {
    paddingTop: 15,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#000000',
    },
  },
  videoBoxMainParagraph: {
    height: '1rem',
    margin: 0,
    padding: 0,
    paddingRight: 10,
  },
}))

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter()
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited()
      }
    },
  })

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  )
})

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
}

export default function SpringModal() {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Box
        type='button'
        onClick={handleOpen}
        display='flex'
        justifyContent='center'
        border={1}
        className={classes.videoBoxMain}
        {...videoBox}
        alignItem='center'
      >
        <p className={classes.videoBoxMainParagraph}>Pogledaj video</p>
        <span>
          <PlayCircleOutlineIcon />
        </span>
      </Box>

      <Modal
        aria-labelledby='spring-modal-title'
        aria-describedby='spring-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div>
              <h2 id='spring-modal-title'>AITI PRISTUP</h2>
              <div id='spring-modal-description'>
                <CardMedia
                  component='video'
                  variant='top'
                  className={classes.media}
                  image={video}
                  autoPlay
                />
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
