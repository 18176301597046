import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUserDetails, updateUserProfile } from '../../actions/userActions'
import { listMyOrders } from '../../actions/orderActions'
import { USER_UPDATE_PROFILE_RESET } from '../../constants/userConstants'
//Components
import Message from '../../components/UI/Message'
import Loader from '../../components/UI/Loader'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
//Style
import { Form, Col } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../components/UI/Theme'
import { Box, Button, Divider, Grid, Paper } from '@material-ui/core'

const useStyles = makeStyles({
  //ide
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    ...theme.paperMain,
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  box: {
    display: 'flex',
    verticalAlign: 'center',
    height: 50,
    marginTop: 80,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      marginBottom: 60,
    },
  },
  coverArrow: { height: 50, verticalAlign: 'center' },
  coverArrow1: { height: 50, paddingTop: 15 },

  mainTitle: {
    ...theme.mainTitle,
    marginTop: 20,
    marginBottom: 0,
    letterSpacing: '2px',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 20,
      fontSize: '2.5rem',
    },
  },

  headerSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 0,
    marginBottom: 40,
    fontFamily: 'PT Sans',
    fontSize: '1.6rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      height: '2rem',
      paddingBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      height: 50,
      margin: 0,
      paddingLeft: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },

  prijavaButton: {
    ...theme.prijavaButton,
    marginBottom: 50,
  },
  gridCardLeft: {
    width: '60%',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 10,
    },
  },
  boxPrice: {
    width: 100,
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 70,
    },
  },
  gridCardLeftContainer: {
    display: 'inline',
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  gridCardLeftContainerBox: {
    width: '100%',
    height: 150,
    marginTop: 0,
    marginRight: 40,
    marginBottom: 0,
    padding: 20,
    '&:hover': {
      transform: 'scale3d(1.01, 1.01, 1)',
      backgroundColor: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      marginRight: 20,
      padding: 0,
    },
  },
  mediaCard: {
    height: 100,
  },
  media: {
    width: 200,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 75,
      height: '100%',
    },
  },
  itemBox: {
    width: '55%',
    [theme.breakpoints.down('sm')]: {
      width: '52%',
    },
  },
  itemCategory: {
    color: '#444333',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
  },
  itemPrice: {
    width: '15%',
    [theme.breakpoints.down('sm')]: {
      width: '20%',
    },
  },
  gridCardRight: {
    width: '37%',
    marginLeft: '3%',
    padding: 20,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 10,
    },
  },
  boxComment: {
    width: '80%',
  },
})

const ProfileScreen = ({ location, history }) => {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  const orderListMy = useSelector((state) => state.orderListMy)
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (!user || !user.name || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserDetails('profile'))
        dispatch(listMyOrders())
      } else {
        setName(user.name)
        setEmail(user.email)
      }
    }
  }, [dispatch, history, userInfo, user, success])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Lozinke se ne podudaraju')
    } else {
      dispatch(updateUserProfile({ id: user._id, name, email, password }))
    }
  }

  return (
    <div className={classes.main}>
      <Paper className={classes.paper_top}>
        <Box>
          <Link to='/'>
            <div className={classes.box}>
              <Box component='div' display='inline'>
                <KeyboardArrowLeftIcon className={classes.coverArrow} />
              </Box>
              <Box
                component='div'
                display='inline'
                className={classes.coverArrow1}
              >
                AITI DIGITAL HOMEPAGE
              </Box>
            </div>
          </Link>
        </Box>
        <Grid
          container
          direction='column'
          justify='space-between'
          className={classes.coverBox}
        >
          <Box>
            <h3 className={classes.mainTitle}>Korisnicki portal</h3>
            <p className={classes.headerSubTitle}>{user.name}</p>
            <Divider />
          </Box>
        </Grid>
      </Paper>
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
        className={classes.paper_top}
      >
        <Grid item className={classes.gridCardLeft}>
          <div>
            <Col md={9}>
              <h2>Moje prijave</h2>
              {loadingOrders ? (
                <Loader />
              ) : errorOrders ? (
                <Message variant='danger'>{errorOrders}</Message>
              ) : (
                <Grid container direction='column'>
                  <Grid>
                    {orders.map((order) => (
                      <Grid container direction='column' key={order._id}>
                        <Grid item>{order.createdAt.substring(0, 10)}</Grid>
                        <Grid>AITI ref:{order._id}</Grid>

                        <Grid>{order.totalPrice} KM</Grid>
                        <Grid>{order.isPaid}</Grid>
                        <Grid>{order.isDelivered}</Grid>
                        <Box>
                          <Button
                            className={classes.prijavaButton}
                            component={Link}
                            to={`/order/${order._id}`}
                          >
                            Detalji
                          </Button>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Col>
          </div>
        </Grid>
        <Grid item className={classes.gridCardRight}>
          <Grid container>
            <Col md={3}>
              <h2>Korisnicki profil</h2>
              {message && <Message variant='danger'>{message}</Message>}
              {}
              {success && <Message variant='success'>Profile Updated</Message>}
              {loading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'>{error}</Message>
              ) : (
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId='name'>
                    <Form.Label>Ime</Form.Label>
                    <Form.Control
                      type='name'
                      placeholder='Unesite vase ime'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='email'>
                    <Form.Label>Email adresa</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='primjer: ime.prezime@google.com'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='password'>
                    <Form.Label>Lozinka</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='Lozinka'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='confirmPassword'>
                    <Form.Label>Potvrdite lozinku</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='Potvrdite lozinku'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Button type='submit' variant='primary'>
                    Azuriraj
                  </Button>
                </Form>
              )}
            </Col>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default ProfileScreen
