import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ title, description, keyword }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description}></meta>
      <meta name='keyword' content={keyword}></meta>
    </Helmet>
  )
}
Meta.defaultProps = {
  title: 'Dobro dosli u AITI ',
  description: 'Institut za tehnologije i inovacije',
  keyword:
    'kursevi, obuka, digitalne tehnologije, IT, aplikacije, AI, istrazivanje',
}

export default Meta
