import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import {
  vijestListReducer,
  vijestDetailsReducer,
  vijestDeleteReducer,
  vijestCreateReducer,
  vijestUpdateReducer,
  vijestOneReducer,
} from './reducers/vijestReducers'
import {
  kursListReducer,
  kursDetailsReducer,
  kursDeleteReducer,
  kursCreateReducer,
  kursUpdateReducer,
  kursCreateReviewReducer,
  kursTopRatedReducer,
  kursOneReducer,
} from './reducers/kursReducers'
import { cartReducer } from './reducers/cartReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userPlatformDetailsReducer,
} from './reducers/userReducers'
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderListMyReducer,
  orderListReducer,
  orderDeliverReducer,
  orderAdminDetailsReducer,
  orderAdminUpdateReducer,
} from './reducers/orderReducers'
import {
  careerListReducer,
  careerAdminListReducer,
  careerDetailsReducer,
  careerOneReducer,
  careerCreateReducer,
  careerDeleteReducer,
  careerUpdateReducer,
} from './reducers/careerReducers'

const reducer = combineReducers({
  vijestList: vijestListReducer,
  vijestDetails: vijestDetailsReducer,
  vijestDelete: vijestDeleteReducer,
  vijestCreate: vijestCreateReducer,
  vijestUpdate: vijestUpdateReducer,
  vijestOne: vijestOneReducer,
  careerList: careerListReducer,
  careerAdminList: careerAdminListReducer,
  careerDetails: careerDetailsReducer,
  careerCreate: careerCreateReducer,
  careerUpdate: careerUpdateReducer,
  careerDelete: careerDeleteReducer,
  careerOne: careerOneReducer,
  kursList: kursListReducer,
  kursDetails: kursDetailsReducer,
  kursDelete: kursDeleteReducer,
  kursCreate: kursCreateReducer,
  kursUpdate: kursUpdateReducer,
  kursCreateReview: kursCreateReviewReducer,
  kursTopRated: kursTopRatedReducer,
  kursOne: kursOneReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  UserPlatformDetail: userPlatformDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  orderAdminDetails: orderAdminDetailsReducer,
  orderAdminUpdate: orderAdminUpdateReducer,
})

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
  ? JSON.parse(localStorage.getItem('shippingAddress'))
  : {}

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
