import axios from 'axios'

import {
  CAREER_LIST_REQUEST,
  CAREER_LIST_SUCESS,
  CAREER_LIST_FAIL,
  CAREER_DETAILS_REQUEST,
  CAREER_DETAILS_SUCESS,
  CAREER_DETAILS_FAIL,
  CAREER_ONE_SUCESS,
  CAREER_ONE_REQUEST,
  CAREER_ONE_FAIL,
  CAREER_DELETE_REQUEST,
  CAREER_DELETE_SUCCESS,
  CAREER_DELETE_FAIL,
  CAREER_CREATE_REQUEST,
  CAREER_CREATE_SUCCESS,
  CAREER_CREATE_FAIL,
  CAREER_UPDATE_REQUEST,
  CAREER_UPDATE_SUCCESS,
  CAREER_UPDATE_FAIL,
  CAREER_ADMIN_LIST_REQUEST,
  CAREER_ADMIN_LIST_SUCESS,
  CAREER_ADMIN_LIST_FAIL,
} from '../constants/careerConstants'

export const listCareers = () => async (dispatch) => {
  try {
    dispatch({ type: CAREER_LIST_REQUEST })
    const { data } = await axios.get('/api/careers')
    dispatch({
      type: CAREER_LIST_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAREER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const listCareersAdmin = () => async (dispatch) => {
  try {
    dispatch({ type: CAREER_ADMIN_LIST_REQUEST })
    const { data } = await axios.get('/api/careers/admin')
    dispatch({
      type: CAREER_ADMIN_LIST_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAREER_ADMIN_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listCareerDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: CAREER_DETAILS_REQUEST })
    const { data } = await axios.get(`/api/careers/${id}`)
    dispatch({
      type: CAREER_DETAILS_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAREER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listOneCareer = () => async (dispatch) => {
  try {
    dispatch({ type: CAREER_ONE_REQUEST })

    const { data } = await axios.get(`/api/careers/popular`)

    dispatch({
      type: CAREER_ONE_SUCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAREER_ONE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteCareer = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAREER_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/careers/${id}`, config)

    dispatch({
      type: CAREER_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: CAREER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createCareer = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAREER_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/careers`, {}, config)

    dispatch({
      type: CAREER_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAREER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateCareer = (career) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAREER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/careers/${career._id}`,
      career,
      config
    )

    dispatch({
      type: CAREER_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CAREER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
