import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listVijesti } from '../../actions/vijestActions'
//Components
import Vijest from '../../components/Vijesti/Vijest'
//Styles
import { Box, Grid, makeStyles, Paper } from '@material-ui/core'

const borderBox = {
  //bgcolor: 'background.paper',
  m: 1,
  border: 1,
  style: { borderColor: '#d9d9d9' },
}

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('xs')]: {
      width: 375,
      //padding: 10,
    },
  },
  mainGrid: {
    width: '100%',
  },
  paper_vs: {
    padding: theme.spacing(1.4),
    margin: 'auto',
    width: 1350,
    backgroundColor: theme.palette.common.aitiGrey,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  titleBox: {
    width: 900,
    height: 400,
    paddingTop: 80,
    [theme.breakpoints.down('lg')]: {
      height: 400,
      width: 700,
      marginTop: 40,
      marginLeft: 20,
      paddingTop: 40,
    },
    [theme.breakpoints.down('md')]: {
      height: 350,
      width: 600,
      marginTop: 60,
      marginLeft: 40,
      paddingTop: 40,
    },
    [theme.breakpoints.down('sm')]: {
      height: 350,
      width: 500,
    },
    [theme.breakpoints.down('xs')]: {
      height: 350,
      width: 335,
      marginLeft: 20,
      paddingTop: 40,
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    marginTop: '2rem',
    letterSpacing: '2px',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      height: '2rem',
      paddingBottom: 40,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 0,
      fontSize: '3rem',
    },
  },
  mainSubTitle: {
    width: '100%',
    height: 'auto',
    fontFamily: 'PT Sans',
    fontSize: '2.3rem',
    letterSpacing: '1px',
    [theme.breakpoints.down('md')]: {
      paddingTop: 40,
      fontSize: '1.2rem',
      height: 80,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '350px',
      height: '10rem',
      margin: 0,
      padding: 0,
      paddingTop: '1rem',
      fontSize: '1.2rem',
    },
  },
  mainGrid_vs: {
    padding: theme.spacing(2),
    justify: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 0,
    },
  },
  mainGridItem_left: {
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '50%',
      margin: 0,
      padding: 20,
    },
    [theme.breakpoints.down('xs')]: {
      width: 350,
      padding: 0,
    },
  },
}))

const VijestiScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const vijestList = useSelector((state) => state.vijestList)
  const { loading, error, vijesti } = vijestList

  useEffect(() => {
    dispatch(listVijesti())
  }, [dispatch])

  return (
    <div className={classes.main}>
      <Paper className={classes.paper_vs}>
        <Grid container justify='space-between' className={classes.mainGrid}>
          <Box
            {...borderBox}
            borderTop={0}
            borderLeft={0}
            borderBottom={1}
            borderRight={0}
            className={classes.titleBox}
          >
            <p>AITI DIGITAL</p>
            <h3 className={classes.mainTitle}>Novo u nasem fokusu</h3>
            <p className={classes.mainSubTitle}>
              Pogledajte kolekciju vijesti u vezi nasih sposobnosti i mogucnosti
              za razvoj vasih kapaciteta
            </p>
          </Box>
          {loading ? (
            <h2>Loading.....</h2>
          ) : error ? (
            <h3>{error}</h3>
          ) : (
            <Grid container className={classes.mainGrid_vs}>
              {vijesti.map((vijest) => (
                <Grid
                  item
                  className={classes.mainGridItem_left}
                  key={vijest._id}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  //xl={3}
                >
                  <Vijest vijest={vijest} />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  )
}

export default VijestiScreen
