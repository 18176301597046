import React, { useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import { makeStyles } from '@material-ui/core/styles'
import {
  FilledInput,
  FormControl,
  IconButton,
  Popper,
  Input,
  InputLabel,
  InputAdornment,
  TextField,
  Grid,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  // root: {
  //   '& > *': {
  //     margin: theme.spacing(1),
  //     //width: '20px',
  //     display: 'inline',
  //     marginLeft: 'auto',
  //   },
  // },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  iconSearch: {
    marginLeft: 'auto',
  },
  searchForm: {
    display: 'inline',
    width: '100%',
  },
  searchFormControl: {},

  searchPopper: {
    backgroundColor: '#ffffff',
    opacity: 1,
  },
  searchButton: {
    display: 'inline',
    position: 'right',

    marginLeft: 'auto',
    zIndex: '200',
  },
  // textField: {
  //   width: '25ch',
  // },
  margin: {
    width: '40ch',
    backgroundColor: '#ffffff',
    height: 60,
    marginRight: 20,

    marginTop: -52,
    zIndex: '10000',
    opacity: 1,
  },
}))

const SearchBox = ({ history }) => {
  const [keyword, setKeyword] = useState('')
  const classes = useStyles()

  const submitHandler = (e) => {
    e.preventDefault()
    if (keyword.trim()) {
      history.push(`/search/${keyword}`)
    } else {
      history.push('/')
    }
  }
  //PROFILE MENU
  const [searchAnchorEl, setSearchAnchorEl] = useState(null)
  const handleSearchClick = (event) => {
    setSearchAnchorEl(searchAnchorEl ? null : event.currentTarget)
  }
  const open = Boolean(searchAnchorEl)
  const searchId = open ? 'search-popper' : undefined

  //END PROFILE MENU

  const searchCloseClick = (e) => {
    setSearchAnchorEl(null)
  }
  //NAVBAR COLOR TRIGGER
  const trigger = useScrollTrigger({})
  return (
    <div>
      <IconButton
        className={classes.iconSearch}
        elevation={trigger ? 10 : 0}
        style={{
          color: trigger ? '#000' : '#fff',
        }}
        aria-aria-describedby={searchId}
        aria-controls='standard-adornment-amount'
        aria-haspopup='true'
        onClick={handleSearchClick}
      >
        <SearchIcon />
      </IconButton>
      <Popper
        id={searchId}
        open={open}
        anchorEl={searchAnchorEl}
        disablePortal={true}
        className={classes.searchPopper}
      >
        <form
          onSubmit={submitHandler}
          noValidate
          autoComplete='off'
          className={classes.searchForm}
        >
          <div className={classes.root}>
            <FormControl
              onChange={(e) => setKeyword(e.target.value)}
              fullWidth
              className={classes.margin}
              variant='filled'
            >
              <InputLabel htmlFor='standard-adornment-amount'>
                Pretrazi AITI
              </InputLabel>
              <FilledInput
                id='standard-adornment-amount'
                endAdornment={
                  <InputAdornment position='end'>
                    <SearchIcon type='submit' edge='end' />
                    <CloseIcon type='submit' onClick={searchCloseClick} />
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
        </form>
      </Popper>
    </div>
  )
}

export default SearchBox
