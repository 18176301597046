import React from 'react'
//Components
import Meta from '../../components/Meta'
//Styles
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../components/UI/Theme'
import { Card, Grid, CardMedia, Paper } from '@material-ui/core'
// Images
import coverImage from '../../images/aiti-cover.jpeg'
import secondImage from '../../images/aiti-druga.jpeg'
import thirdImage from '../../images/aiti-treca.jpeg'
const useStyles = makeStyles((theme) => ({
  //SHEET
  root: {
    maxWidth: '100%',
    height: 'auto',
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  //COVER
  cover: {
    maxWidth: '100%',
    height: 600,
    marginTop: 80,
    [theme.breakpoints.down('sm')]: {
      height: '65vh',
      marginTop: 60,
    },
  },
  media: {
    height: 700,
    alignItem: 'top',
    [theme.breakpoints.down('sm')]: { height: '65vh' },
  },
  coverBox: {
    height: 280,
    width: '75vw',
    margin: 'auto',
    verticalAlign: 'middle',
    marginTop: -300,
    padding: 40,
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 180,
      marginTop: -180,
      padding: 20,
    },
  },
  coverTitle: {
    ...theme.mainTitle,
    marginBottom: 20,
    color: '#fff',
    fontSize: '4.4rem',
    fontWeight: '600',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      marginBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
      fontWeight: '600',
      marginBottom: 10,
    },
  },
  coverSubTitle: {
    ...theme.mainSubTitle,
    marginBottom: 10,
    color: '#fff',
    fontWeight: '400',
    lineHeight: '1.6',
    alignItem: 'center',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 0,
      fontSize: '1.6rem',
      color: '#000',
    },
  },
  // PRVI DIO
  prviDio: {
    maxWidth: '100%',
    height: 400,
  },
  prvi_dio_box: {
    width: '50%',
    margin: 'auto',
    backgroundColor: '#fff',
    paddingTop: 60,
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 20,
      paddingTop: 60,
    },
  },
  prvi_dioParagraph: {
    ...theme.mainParagraph,
    fontSize: '1.3rem',
    color: '#000',
    fontWeight: '500',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
      marginBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: '500',
    },
  },
  //DRUGI DIO
  drugiDio: {
    maxWidth: '80%',
    margin: 'auto',
    marginTop: 60,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  image_II: {
    height: 500,
    alignItem: 'bottom',
    [theme.breakpoints.down('sm')]: { height: '35vh' },
  },
  drugiBox: {
    height: 'auto',
    width: '85%',
    margin: 'auto',
    marginTop: -100,
    padding: 80,
    backgroundColor: '#fff',
    color: '#000',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      marginTop: -40,
      padding: 20,
    },
  },
  drugi_dioTitle: {
    ...theme.mainTitle,
    width: '60vw',
    paddingTop: -15,
    fontSize: '3rem',
    letterSpacing: 4,
    color: '#000',
    fontWeight: 600,
    zIndex: '1250 !important',
    '& span': {
      color: theme.palette.common.aitiTirkiz,
      fontWeight: 600,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
      paddingTop: 220,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '1.8rem',
      fontWeight: 600,
      lineHeight: '1.6',
      letterSpacing: 2,
      marginBottom: 20,
      paddingTop: 10,
    },
  },
  drugi_dioSubTitle: {
    ...theme.mainSubTitle,
    marginTop: 20,
    color: '#000',
    fontWeight: '600',
    lineHeight: '1.6',
    alignItem: 'center',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 0,
      fontSize: '1.3rem',
      color: '#000',
    },
  },
  drugi_dioParagraph: {
    ...theme.mainParagraph,
    fontSize: '1.3rem',
    lineHeight: '1.7',
    color: '#000',
    fontWeight: '500',
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: '500',
    },
  },
  treci_dioParagraph: {
    ...theme.mainParagraph,
    fontSize: '1.3rem',
    lineHeight: '1.7',
    color: '#000',
    fontWeight: '500',
    marginBottom: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      marginBottom: 10,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: '500',
      marginBottom: 1,
    },
  },
}))

const AboutUsScreen = () => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Meta />
      <Card className={classes.cover}>
        <CardMedia className={classes.media} image={coverImage} title='AITI' />

        <Grid container direction='column' className={classes.coverBox}>
          <h3 className={classes.coverTitle}>Za buduce doba</h3>
          <p className={classes.coverSubTitle}>
            Tehnologije i inovacije koje mogu promjeniti igru
          </p>
        </Grid>
      </Card>
      <div className={classes.prviDio}>
        <Grid container direction='column' className={classes.prvi_dio_box}>
          <p className={classes.prvi_dioParagraph}>
            Mi vjerujemo u snagu razvojnog partnerstva.
          </p>
          <p className={classes.prvi_dioParagraph}>
            Bosna i Hercegovina se susrece sa kompleksnim razvojim izazovima
            prouzrokovanim krizom u vjestinama za koje je samostalno tesko
            pronaci odgovor.
          </p>
          <p className={classes.prvi_dioParagraph}>
            Nas institut nastoji identificirati inovativna i djelotvorna
            rjesenja kako bi doprinijeli promociji tehnologija i inovacija, i
            zajedno sa drugim stakeholderima razviti kapaciete ljudi,
            organizacija i zajednica kako bi se prilagodili izazovima buduceg
            doba.
          </p>
        </Grid>
      </div>
      <div className={classes.drugiDio}>
        <CardMedia
          className={classes.image_II}
          image={secondImage}
          title='AITI'
        />
        <Grid container direction='column' className={classes.drugiBox}>
          <h3 className={classes.drugi_dioTitle}>Gledamo prema buducnosti</h3>

          <p className={classes.drugi_dioParagraph}>
            U 21. stoljecu znanje je kljucni izvor rasta. Mi radimo zajedno sa
            sa akterima iz svijeta industrije i svijeta obrazovanja kako bi se
            pripremili za izazove koje donosi digitalno doba.
          </p>
          <p className={classes.drugi_dioParagraph}>
            U partnerstvu sa institucijama, skolama i industrijom usmjereni smo
            ka jacanju kapaciteta radne snage i razvoju vjestina koje ce postaci
            invacije i primjenu naprednih tehnologija.
          </p>
          <p className={classes.drugi_dioSubTitle}>Pokretani znanjem</p>
          <p className={classes.drugi_dioParagraph}>
            Mi smo svjesni da se svaka drzava i svaka industrija nalazi u krizi
            vjestina i da povecanje ponude tradicionalnih vjestina nije potpuni
            odgovor. Za povecanje performansi industrije od kljucne vaznosti je
            osigurati da radna snaga relevatne digitalne i soft skills vjestine.
          </p>
          <p className={classes.drugi_dioParagraph}>
            Kao odgovor na ovaj izazov, nasa maticna organizacija ALDI Gorazde
            je u 2018. godini pokrenula inovativni trening lab cija je osnovna
            svrha prosiriti mogucnosti sticanja vjestina relevantnih za buduce
            trziste rada.
          </p>
          <p className={classes.drugi_dioParagraph}>
            Inovativni trening lab je za dvije godine pilotirao 10 novih
            programa obrazovanja u digitalnim i soft skills koji su posebno
            prilagodjeni trenutnim potrebama trzista rada. Pored toga u saradnji
            sa partnerima Lab je u dvije godine pruzio priliku za 250 vecinom
            visokoobrazovanih mladih ljudi da u radno slicnom okruzenju uz pomoc
            mentora steknu za tržište rada relevantne digitalne i soft skills.
          </p>
          <p className={classes.drugi_dioSubTitle}>Tehnologije i inovacije</p>
          <p className={classes.drugi_dioParagraph}>
            COVID-19 je radikalno promjenio poslovne modele koji se jos uvijek
            se mjenjaju kako bi odgovorili na nove izazove i nove poslovne
            prioritete.Za industriju to donosi novi imperativ da generisu nove
            vrijednosti koristenjem inovativnih tehnologija i pristupa
            informacijama.
          </p>
          <p className={classes.drugi_dioParagraph}>
            Nekoliko mjeseci prije izbijanja COVID-19 ALDI je pokrenuo
            aktivnosti na transformaciji Inovatnivnog trening Lab-a u samostalnu
            efukativnu i istrazivacku instituciju sa misijom promocije razvoja
            znanja i ucenja i istrazivanja i eksperimentalnog razvoja u
            digitalnim tehnologijama, proizvodnji, povezanosti i urbanoj
            mobilnosti - AITI.
          </p>
          <p className={classes.drugi_dioParagraph}>
            Napredni Institut za tehnologije i inovacije (AITI) se krajem 2020
            registrovao kao institut sa ciljem da doprinese razvoju vjestina
            radne snage ali i istovremeno da pomogne digitalnom ubrzavanju,
            identifikaciji i koristenju izrastajucih tehnologija kao i
            sofistifikaciji partnerstva i kolaboracije sa lokalnom industrijom.
          </p>
          <p className={classes.drugi_dioParagraph}>
            AITI nastoji podrzati lokalnu industriju da iskoriste prednosti koje
            donose nove tehnologije kako bi transformisli poslovne operacije i
            modele na nacin koji ce im pomoci da bolje odgovore na buduce
            poremecaje i da povecaju svoje performanse i konkuretnost. U oblasti
            razvoja znanja, mi smo fokusirani na to kako opremiti mlade ljude sa
            vjestinama koje su neophodne kako bi se popunio jaz i digitalnim
            vjestinama.
          </p>
        </Grid>
      </div>
      <div className={classes.drugiDio}>
        <CardMedia
          className={classes.image_II}
          image={thirdImage}
          title='AITI'
        />
        <Grid container direction='column' className={classes.drugiBox}>
          <h3 className={classes.drugi_dioTitle}>Uvijek za saradnju</h3>

          <p className={classes.drugi_dioParagraph}>
            Za nas je saradnja u oblasti razvoja znanja, promociji inovacija i
            tehnologija imperativ.
          </p>

          <p className={classes.drugi_dioSubTitle}>Kako nas kontaktirati</p>
          <p className={classes.treci_dioParagraph}>
            Za sve upite i informacije mozete nas slobodno kontaktirati:
          </p>
          <p className={classes.treci_dioParagraph}>AITI</p>
          <p className={classes.treci_dioParagraph}>
            Napredni institut za inovacije tehnologije
          </p>
          <p className={classes.treci_dioParagraph}>Panorama b.b.</p>
          <p className={classes.treci_dioParagraph}>73000 Gorazde</p>
          <p className={classes.drugi_dioParagraph}>Bosna i Hercegovina</p>
          <p className={classes.treci_dioParagraph}>Tel: +387 38 227 850</p>
          <p className={classes.treci_dioParagraph}>Tel: +387 38 227 850</p>
          <p className={classes.treci_dioParagraph}>Email: aiti@aiti.ba</p>
        </Grid>
      </div>
    </Paper>
  )
}

export default AboutUsScreen
