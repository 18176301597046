export const CAREER_LIST_REQUEST = 'CAREER_LIST_REQUEST'
export const CAREER_LIST_SUCESS = 'CAREER_LIST_SUCESS'
export const CAREER_LIST_FAIL = 'CAREER_LIST_FAIL'

export const CAREER_ADMIN_LIST_REQUEST = 'CAREER_ADMIN_LIST_REQUEST'
export const CAREER_ADMIN_LIST_SUCESS = 'CAREER_ADMIN_LIST_SUCESS'
export const CAREER_ADMIN_LIST_FAIL = 'CAREER_ADMIN_LIST_FAIL'

export const CAREER_DETAILS_REQUEST = 'CAREER_DETAILS_REQUEST'
export const CAREER_DETAILS_SUCESS = 'CAREER_DETAILS_SUCESS'
export const CAREER_DETAILS_FAIL = 'CAREER_DETAILS_FAIL'

export const CAREER_DELETE_REQUEST = 'CAREER_DELETE_REQUEST'
export const CAREER_DELETE_SUCCESS = 'CAREER_DELETE_SUCCESS'
export const CAREER_DELETE_FAIL = 'CAREER_DELETE_FAIL'

export const CAREER_CREATE_REQUEST = 'CAREER_CREATE_REQUEST'
export const CAREER_CREATE_SUCCESS = 'CAREER_CREATE_SUCCESS'
export const CAREER_CREATE_FAIL = 'CAREER_CREATE_FAIL'
export const CAREER_CREATE_RESET = 'CAREER_CREATE_RESET'

export const CAREER_UPDATE_REQUEST = 'CAREER_UPDATE_REQUEST'
export const CAREER_UPDATE_SUCCESS = 'CAREER_UPDATE_SUCCESS'
export const CAREER_UPDATE_FAIL = 'CAREER_UPDATE_FAIL'
export const CAREER_UPDATE_RESET = 'CAREER_UPDATE_RESET'

export const CAREER_ONE_REQUEST = 'CAREER_ONE_REQUEST'
export const CAREER_ONE_SUCESS = 'CAREER_ONE_SUCESS'
export const CAREER_ONE_FAIL = 'CAREER_ONE_FAIL'
