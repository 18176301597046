import React from 'react'
import { Link } from 'react-router-dom'
//COMPONENTS
import Meta from '../../components/Meta'
import obuke1 from '../../images/obuke_01.jpg'
//STYLES
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Card,
  CardMedia,
  Step,
  Stepper,
  StepLabel,
  StepContent,
  Typography,
} from '@material-ui/core'

function getSteps() {
  return [
    'Donesi odluku',
    'Saznaj kako ti AITI moze pomoci da razvijes svoje vjestine',
    'Upoznaj se sa aktuelnim mogucnostima ucenje koje pruza AITI',
    'Prijavi se',
  ]
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return `Donesi odluku i vec si napravio prvi korak.`
    case 1:
      return 'Mi zivimo u novom dobu koje od svakog od nas zahtjeva da posjedujemo sirok spektar vjestina koje su nam potrebne da ostvarimo svoj puni licni i profesionalni potencijal. AITI vam moze pomoci da razvijete vase tehnicke i softskills vjestine koje vam mogu pomoci da postanete izvrsni u vasem poslu.  '
    case 2:
      return `AITI nudi veliki broj obrazovnih puteva za sticanje i razvoj vjestina 
              koje vam mogu pomoci da razvijete svoju profesionalnu karijeru. 
              U sekciji kursevi i obuke mozete pronaci trening program i model obuke 
              koji najbolje odgovara vasim potrebama.`
    case 3:
      return `Vi mozete odabrati bilo koji program obrazovanja koji odgovara vasim potrebama i prijaviti se online. Ukoliko se prijavljujete na aktivni program prijavom ucestvujete u procesu selekcije o cijim rezultatima ce te biti obavjesteni u navedenim rokovima. Ukoliko se prijavljujete na program koji nije aktivan, o rezultatima vase prijave bicete obavjesteni nakon sto vas zahtjev bude obradjen.`
    default:
      return 'Unknown step'
  }
}
const borderBox = {
  m: 1,
  border: 1,
  style: { borderColor: '#d9d9d9' },
}
const outerTheme = createMuiTheme({
  overrides: {
    MuiStepLabel: {
      label: {
        color: '#ad6ff7',
        '&$active': {
          color: '#ad6ff7',
        },
        '&$completed': {
          color: '#ad6ff7',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#5e2e99',
    },
    secondary: {
      main: '#5e2e99',
    },
  },
  MuiStepIcon: {
    root: {
      '&$completed': {
        color: '#444333',
      },
      '&$active': {
        color: '#444333',
      },
    },
    active: {},
    completed: {},
  },
})

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.common.aitiGrey,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paper_top: {
    padding: theme.spacing(1.4),
    margin: 'auto',
    width: 1350,
    backgroundColor: theme.palette.common.aitiGrey,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  paperKursevi: {
    padding: theme.spacing(1.4),
    margin: 'auto',
    width: 1350,
    backgroundColor: theme.palette.common.aitiGrey,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '400px',
      padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  coverBox: {
    height: 'auto',
    width: 'auto',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      height: 450,
      width: '370px',
    },
  },
  titleBox: {
    width: '900px',
    height: 'auto',
    paddingTop: 80,
    marginBottom: 80,
    [theme.breakpoints.down('md')]: {
      height: 400,
      width: '80%',
      paddingTop: 80,
    },
    [theme.breakpoints.down('sm')]: {
      height: 500,
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      height: 440,
      width: '100%',
      padding: 20,
      paddingTop: 80,
      marginBottom: 0,
    },
  },
  mainTitle: {
    ...theme.mainTitle,
    marginTop: 80,
    letterSpacing: 2,
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      height: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '1rem',
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      margin: 0,
      padding: 0,
      fontSize: '3rem',
    },
  },
  mainSubTitle: {
    width: '100%',
    height: 'auto',
    paddingBottom: 100,
    fontFamily: 'PT Sans',
    fontSize: '2.3rem',
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      height: '2rem',
      paddingBottom: 50,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: 350,
      height: '7rem',
      margin: 0,
      paddingRight: 20,
      paddingTop: '1rem',
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
  obukePromo: {
    height: 'auto',
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: '#f4e9e3',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  obukePromoGrid: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      paddingBottom: 100,
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
    },
  },
  obukePromoGridBox: {
    paddingLeft: 100,
    height: '100%',

    [theme.breakpoints.down('md')]: {
      paddingTop: 10,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 50,
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      padding: 20,
      paddingBottom: 40,
    },
  },
  obukePromoGridTitle: {
    ...theme.titleSansSerif,
    display: 'flex',
    height: '40%',
    alignItems: 'center',
    paddingTop: 20,
    paddingRight: 100,
    [theme.breakpoints.down('md')]: {
      paddingTop: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 10,
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '40%',
      paddingBottom: 20,
      fontSize: '2.5rem',
    },
  },
  obukePromoGridSubtitle: {
    ...theme.subtitleSansSerif,
    display: 'flex',
    alignItems: 'top',
    paddingTop: 20,
    paddingRight: 100,
    paddingBottom: 40,
    [theme.breakpoints.down('md')]: {
      paddingTop: 10,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      paddingLeft: 10,
      paddingRight: 20,
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
      marginBottom: 10,
      fontSize: '1.2rem',
    },
  },
  obukePromoGridButton: {
    ...theme.registerButton,
    width: '100%',
    //margin: 20,
  },

  //stepper
  stepperCover: {
    minHeight: 600,
    backgroundColor: '#333333',
  },
  stepperCoverTitle: {
    ...theme.titleSansSerif,
    display: 'flex',
    height: '40%',
    paddingTop: 20,
    paddingRight: 100,
    paddingLeft: 60,
    alignItems: 'center',
    color: '#ccc000',
    [theme.breakpoints.down('md')]: { paddingTop: 10 },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      paddingLeft: 20,
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 220,
      paddingBottom: 0,
      fontSize: '2.5rem',
    },
  },
  stepperCoverSubtitle: {
    ...theme.subtitleSansSerif,
    display: 'flex',
    alignItems: 'top',
    paddingTop: 20,
    paddingRight: 100,
    paddingBottom: 40,
    paddingLeft: 60,
    color: '#fff',
    [theme.breakpoints.down('md')]: { paddingTop: 10 },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
      marginBottom: 10,
      fontSize: '1.2rem',
    },
  },

  root: {
    paddingTop: 40,
    width: '100%',
    backgroundColor: '#333333',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  obukeStep: { backgroundColor: '#333333' },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: '#fff',
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#333333',
    color: '#fff',
  },
  resetContainer: {
    padding: theme.spacing(3),
    backgroundColor: '#333333',
  },
  stepperStepper: {
    width: 370,
    backgroundColor: '#333333',
  },
  stepperLabel: {
    color: '#ccc000',
  },
  stepperContent: {
    color: '#ccc000',
  },
  stepperTypography: {
    color: '#ffffff',
  },
  //stepperEnd
  mainGrid_vs: {
    padding: theme.spacing(2),
    justify: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      padding: 0,
    },
  },
  mainGridItem_left: {
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 0,
    },
  },
  kurseviGrid: { width: 'auto' },
}))

const ObukeScreen = ({ match }) => {
  const classes = useStyles()

  //stepper
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }
  //stepperEnd
  return (
    <div className={classes.main}>
      <Meta />
      <Paper className={classes.paper_top}>
        <Grid container justify='space-between' className={classes.coverBox}>
          <Box
            {...borderBox}
            borderTop={0}
            borderLeft={0}
            borderBottom={1}
            borderRight={0}
            className={classes.titleBox}
          >
            <p>AITI DIGITAL</p>
            <h3 className={classes.mainTitle}>Suprostavi se izazovu</h3>
            <p className={classes.mainSubTitle}>
              Izgradi vitalne vjestine za sebe i svoj tim i budi spreman za
              buducnost rada u digitalnim i tradicionalnim industrijama.
            </p>
          </Box>
        </Grid>
      </Paper>

      <Grid container direction='row-reverse' className={classes.obukePromo}>
        <Grid item sm xs={12} className={classes.obukePromoGrid}>
          <Card>
            <CardMedia
              className={classes.promoMedia}
              component='img'
              variant='top'
              image={obuke1}
            ></CardMedia>
          </Card>
        </Grid>
        <Grid item sm xs={12} className={classes.obukePromoGrid}>
          <Box className={classes.obukePromoGridBox}>
            <h2 className={classes.obukePromoGridTitle}>Otvorite AITI vrata</h2>
            <p className={classes.obukePromoGridSubtitle}>
              Mi vam mozemo pomoci da razvijete svoje digitalne i soft skills
              vjestine. Napravi prvi korak i registruj se.
            </p>
            <Link to='/register'>
              <Button
                variant='outlined'
                //href='/register'
                className={classes.obukePromoGridButton}
              >
                Registruj se
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.stepperCover}>
        <Grid item sm xs={12}>
          <h2 className={classes.stepperCoverTitle}>Napravi prve korake</h2>
          <p className={classes.stepperCoverSubtitle}>
            Mi smo tu da ti pomognemo da napravis promjenu u svojoj karijeri
          </p>
        </Grid>
        <Grid item sm xs={12}>
          <ThemeProvider theme={outerTheme}>
            <div className={classes.root}>
              <Stepper
                activeStep={activeStep}
                orientation='vertical'
                className={classes.stepperStepper}
              >
                {steps.map((label, index) => (
                  <Step key={label} className={classes.stepperContent}>
                    <StepLabel className={classes.stepperLabel}>
                      {label}
                    </StepLabel>
                    <StepContent className={classes.stepperContent}>
                      <Typography>{getStepContent(index)}</Typography>
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Nazad
                          </Button>
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={handleNext}
                            className={classes.button}
                          >
                            {activeStep === steps.length - 1
                              ? 'Zavrsi'
                              : 'Dalje'}
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                  <Typography className={classes.stepperTypography}>
                    Hvala.
                  </Typography>
                  <Button onClick={handleReset} className={classes.button}>
                    Reset
                  </Button>
                </Paper>
              )}
            </div>
          </ThemeProvider>
        </Grid>
      </Grid>
      <Paper className={classes.paper_top}>
        <Grid container justify='space-between' className={classes.coverBox}>
          <Box className={classes.titleBox}>
            <h3 className={classes.mainTitle}>Saznaj sta mozes nauciti</h3>
            <p className={classes.mainSubTitle}>
              Pregledaj sve mogucnosti za razvoj vjestina koje mozes steci u
              AITI
            </p>
            <Link to='/kursevi'>
              <Button
                variant='outlined'
                className={classes.obukePromoGridButton}
              >
                Pretrazi sve obuke
              </Button>
            </Link>
          </Box>
        </Grid>
      </Paper>
    </div>
  )
}

export default ObukeScreen
